import React from "react";

import { makeStyles, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { resetAllExceptOrder, resetBasketState } from "../../../store/features/shop/Shop";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 500,
    margin: "20px auto",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 32,
  },
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "120%",
    marginBottom: 12,
    marginTop: 24,
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    color: "#69779A",
  },
  button: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    textTransform: "none",
    borderRadius: 40,
    padding: "16px 28px",
    width: "80%",
    marginTop: 24,
  },
}));

export const SuccessPreorder = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const prevOrder = useSelector(
    (store: RootState) => store.shop.basket.prevOrder
  );

  React.useEffect(() => {
    dispatch(resetAllExceptOrder());
  }, []);

  const goToMain = () => {
    dispatch(resetBasketState());
    history.push("/");
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div>
          <img
            src={window.location.origin + "/image/success_icon.svg"}
            alt="success"
          />
        </div>
        <Typography className={classes.title}>{t("DONE_PRE_ORDER")}</Typography>
        <Typography className={classes.description}>
          {t("WHEN_DEVICE_AVAILABLE")}
        </Typography>
        <Typography className={classes.description}>
          {t("ORDER_SENT_TO_EMAIL")} {prevOrder?.email}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToMain}
        >
          {t("GO_TO_MAIN")}
        </Button>
      </div>
    </div>
  );
};
