import React from "react";
import {
  FormControl,
  makeStyles,
  RadioGroup,
  Radio,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 24,
  },
  radio: {
    color: "#5D9BF8",
    "&.Mui-checked": {
      color: "#5D9BF8",
    },
  },
}));

export const PaymentForm = ({ formik, isDisabled = false }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isPreOrder = useSelector(
    (store: RootState) => store.shop.basket.isPreOrder
  );
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("PAYMENT")}</Typography>
      <FormControl required>
        <RadioGroup
          name="paymentMethod"
          value={formik.values.paymentMethod}
          onChange={formik.handleChange}
        >
          {isPreOrder && (
            <FormControlLabel
              value="preOrder"
              control={
                <Radio className={classes.radio} disabled={isDisabled} />
              }
              label={t("PRE_ORDER")}
            />
          )}
          <FormControlLabel
            value="cod"
            control={
              <Radio
                className={classes.radio}
                disabled={isPreOrder || isDisabled}
              />
            }
            label={t("COLLECTION_OF_DELIVERY")}
          />
          <FormControlLabel
            value="card"
            control={
              <Radio
                className={classes.radio}
                // disabled={isPreOrder || isDisabled}
                disabled={true}
              />
            }
            label={t("BY_CARD")}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};
