import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { darkTheme, lightTheme } from "./themes";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

export const ThemeWrapper = ({ children }: any) => {
  const theme = useSelector((state: RootState) => state.theme.theme);
  let currentTheme = theme === "light" ? lightTheme : darkTheme;
  return <ThemeProvider theme={{ ...currentTheme }}>{children}</ThemeProvider>;
};
