import React from "react";
import {
  Box,
  Button,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "30%",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#1D3A60",
    padding: "56px 32px 48px",
    [theme.breakpoints.down("md")]: {
      width: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "65%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: "38px 20px 48px",
    },
  },
  orderTitle: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "100%",
    textAlign: "center",
    marginBottom: 12,
    color: "#1D3A60",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  text: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    textAlign: "center",
    marginBottom: 24,
    color: "#69779A",
  },
  description: {
    width: "100%",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "120%",
    marginBottom: 24,
    color: "#69779A",
    "& ul": {
      marginTop: 12,
      paddingLeft: 16,
      "& li": {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "140%",
        color: "#69779A",
        marginBottom: 8,
        position: "relative",
        "&:last-child": {
          marginBottom: 0,
        },
        "&:before": {
          position: "absolute",
          display: "block",
          content: "''",
          width: 8,
          height: 8,
          backgroundColor: "#5D9BF8",
          opacity: 0.5,
          bottom: 9,
          left: "-16px",
          borderRadius: "50%",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 16,
    },
  },
  submitButton: {
    textTransform: "none",
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    width: "80%",
    padding: "14px 69px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export const PreorderOnOrderModal = ({
  handleClose,
  open,
  haveToBePreorder,
  availableBracelets,
  onSubmit,
}: any) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const title = haveToBePreorder
    ? t("SELECTED_BRACELETS_NOT_AVAILABLE")
    : t("NOT_ALL_BRACELETS_AVAILABLE");
  const text = haveToBePreorder
    ? t("SELECTED_BRACELETS_NOT_AVAILABLE_TEXT")
    : t("NOT_ALL_BRACELETS_AVAILABLE_TEXT");
  const buttonText = haveToBePreorder ? t("PRE_ORDER") : t("MAKE_AN_ORDER");

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box className={classes.wrapper}>
        <div className={classes.content}>
          <Typography className={classes.orderTitle}>{title}</Typography>
          <Typography
            className={classes.text}
            style={{ marginBottom: !haveToBePreorder ? 12 : 24 }}
          >
            {text}
          </Typography>
          {!haveToBePreorder && (
            <Typography className={classes.description}>
              Доступні браслети:
              <ul>
                {availableBracelets.map((elem: any) => (
                  <li key={elem.id}>
                    MISU Watch {elem?.name?.toUpperCase()} - {elem.count}
                  </li>
                ))}
              </ul>
            </Typography>
          )}
          <Button
            className={classes.submitButton}
            onClick={onSubmit}
            color="primary"
            variant="contained"
          >
            {buttonText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
