import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    backgroundImage: `url(${window.location.origin}/image/section_9_bg.svg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "112px 3% 88px",
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "48px 16px",
      marginBottom: 60,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    height: "100%",
    maxWidth: 1200,
    color: "#1D3A60",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  // wrapper: {
  //   backgroundImage: `url(${window.location.origin}/image/section_9_bg.svg)`,
  //   backgroundPosition: "center",
  //   backgroundSize: "cover",
  //   padding: "112px 5% 88px",
  //   borderRadius: 40,
  //   display: "flex",
  //   flexDirection: "column",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "90%",
  //   maxWidth: 1200,
  //   marginBottom: 120,
  //   [theme.breakpoints.down("sm")]: {
  //     width: "100%",
  //     padding: "48px 16px",
  //     marginBottom: 60,
  //   },
  // },
  // content: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   color: "#1D3A60",
  //   width: "100%",
  //   height: "100%",
  //   textAlign: "left",
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column",
  //   },
  // },
  phoneWrapper: {
    // marginRight: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  textWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 50,
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 18,
    marginBottom: 12,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  button: {
    background: "#5985F5",
    borderRadius: 40,
    color: "#fff",
    fontWeight: 800,
    fontSize: 16,
    padding: "16px 28px",
    lineHeight: "140%",
    textTransform: "none",
    boxShadow: "none",
    marginTop: 40,
    width: "40%",
    "&:hover": {
      boxShadow: "none",
      background: "#4978F0",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

export const Section9 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const onButtonClick = () => {
    history.push("/watches");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.phoneWrapper}>
          <img
            src={window.location.origin + "/image/section9_phone.svg"}
            alt="phone"
          />
        </div>
        <div className={classes.textWrapper}>
          <Typography className={classes.title}>
            {t("SECTION_9_TITLE")}
          </Typography>
          <Typography className={classes.text}>
            {t("SECTION_9_SUBTITLE_1")}
          </Typography>
          <Typography className={classes.text}>
            {t("SECTION_9_SUBTITLE_2")}
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={onButtonClick}
          >
            {t("MEDICAL_ASSISTANCE_ONLINE_ORDER")}
          </Button>
        </div>
      </div>
    </div>
  );
};
