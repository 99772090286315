import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { loadBasket } from "../../store/features/shop/Shop";
import { AppDispatch, RootState } from "../../store/store";
import Footer from "../UI/Footer";

import { useTranslation } from "react-i18next";
import { HeaderV2 } from "../UI/HeaderV2";
import { CookieConfirm } from "../UI/CookieConfirm";
import { Section1 } from "../UI/MainPage/Section1";
import { Section2 } from "../UI/MainPage/Section2";
import { Section3 } from "../UI/MainPage/Section3";
import { Section4 } from "../UI/MainPage/Section4";
import { Section5 } from "../UI/MainPage/Section5";
import { Section6 } from "../UI/MainPage/Section6";
import { Section8 } from "../UI/MainPage/Section8";
import { Section9 } from "../UI/MainPage/Section9";
import { Section10 } from "../UI/MainPage/Section10";
import { Section11Feedback } from "../UI/MainPage/Section11Feedback";
import { AlfaMain } from "../UI/AlfaMain";
import { SectionMunich } from "../UI/MainPage/SectionMunich";
import { Section12 } from "../UI/MainPage/Section12";

const MainPageContent = () => {

  React.useEffect(() => {
    const scrollToHashElement = () => {
      setTimeout(() => {
        const { hash } = window.location;
        const elementToScroll = document.getElementById(hash?.replace("#", ""));

        if (!elementToScroll) return;

        elementToScroll.scrollIntoView({
          block: "center",
          inline: "center",
          behavior: "auto",
        });
      }, 300);
    };

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  }, []);

  return (
    <>
      <HeaderV2 />
      <CookieConfirm />
      <Section1 />
      {/* <SectionMunich /> */}
      <Section2 />
      <Section3 />
      <Section4 />
      <div id="monitoring"></div>
      <Section5 />
      <Section6 />
      <AlfaMain />
      <Section8 />
      <div id="connection"></div>
      <Section9 />
      <Section10 />
      {/* <Section12 /> */}
      <Section11Feedback />
      <div id="contacts"></div>
      <Footer />
    </>
  );
};

export const MainV2 = () => {
  const loadingLocales = useSelector(
    (store: RootState) => store.locale.loading
  );

  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    document.title = `MISU`;
  }, []);

  React.useEffect(() => {
    dispatch(loadBasket());
    // dispatch(loadNews());
  }, [dispatch]);

  if (loadingLocales) return null;

  return <MainPageContent />;
};
