import React from "react";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  LinearProgress,
  makeStyles,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import { ChevronLeft, Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

import { PreorderItem } from "./PreorderItem";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "70%",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "95%",
    color: "#1D3A60",
    padding: "48px 35px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: "38px 20px 48px",
    },
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    height: "100%",
    padding: "0px 30px 20px 30px",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 32,
      opacity: 0.2,
      backgroundColor: "#DEE1EB",
      border: "3px solid #DEE1EB",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#FF5E5E",
      borderRadius: 32,
      height: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "100%",
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  mainText: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: "100%",
    textAlign: "center",
    marginBottom: 12,
  },
  descriptionWrap: {
    textAlign: "center",
    marginBottom: 20,
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  descriptionText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "120%",
    textAlign: "justify",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  submitButton: {
    marginTop: 20,
    textTransform: "none",
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    maxWidth: 250,
    width: "35%",
    padding: "14px 69px",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15,
    [theme.breakpoints.down("xs")]: {
      right: 5,
      top: 5,
    },
  },
  linearProgress: {
    width: "50%",
    height: "1px",
    minHeight: 4,
    marginBottom: 20,
  },
}));

const useStylesForm = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "100%",
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  submitButton: {
    marginTop: 20,
    textTransform: "none",
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    maxWidth: 350,
    padding: "14px 69px",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15,
    [theme.breakpoints.down("xs")]: {
      right: 5,
      top: 5,
    },
  },
  inputBlock: {
    marginBottom: 24,
    textAlign: "left",
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "&:last-child": {
        marginBottom: 24,
      },
    },
  },
  closeFormButton: {
    position: "absolute",
    left: 15,
    top: 15,
    [theme.breakpoints.down("xs")]: {
      left: 5,
      top: 5,
    },
  },
  inputPhone: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    textAlign: "left",
    width: "100% !important",
    height: "100% !important",
    "& input": {
      background: "none !important",
      border: "none !important",
      color: "#8aa2bf !important",
      paddingLeft: "0px !important",
      width: "100% !important",
      height: "100% !important",
      padding: "18px 0px",
      textAlign: "left",
    },
    "& .country-list": {
      color: "#8aa2bf",
    },
    "& .form-control": {
      paddingLeft: "50px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
}));

const InfoPart = ({ onOrderClick, data }: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isEnLocale = i18n.language === "en";
  return (
    <>
      <Typography className={classes.title}>{t("PREORDER_TITLE")}</Typography>
      <Typography className={classes.mainText}>
        {t("PREORDER_SUBTITLE")} <br />
        <b>
          {data?.count ?? 0} / {data?.goal_count ?? 700}
        </b>
      </Typography>
      <LinearProgress
        className={classes.linearProgress}
        variant="determinate"
        value={((data?.count ?? 0) / (data?.goal_count ?? 700)) * 100}
      />
      <div className={classes.itemsWrapper}>
        {data?.watches?.map((e: any) => (
          <PreorderItem
            img={
              e?.image ??
              window.location.origin + "/assets/img/watches/ep01.png"
            }
            title={e?.name ?? "Назва девайсу"}
            price={e?.price ?? 4500}
            discount={e?.discount ?? 30}
            text={
              (isEnLocale ? e?.description_en : e?.description) ??
              "Короткий опис девайсу в 1-2 речення"
            }
            items={
              (isEnLocale ? e?.features_en : e?.features) ?? [
                "Фіча 1",
                "Фіча 2",
                "Фіча 3",
                "Фіча 4",
                "Фіча 5",
              ]
            }
          />
        ))}
      </div>
      <div className={classes.descriptionWrap}>
        <Typography className={classes.descriptionText}>
          * {t("PREORDER_DESCRIPTION")}
        </Typography>
      </div>
      <Button
        color="primary"
        variant="contained"
        onClick={onOrderClick}
        className={classes.submitButton}
      >
        {t("PREORDER_BOOK")}
      </Button>
    </>
  );
};

const FormContent = ({ onGoBackClick, onFormSubmit, data }: any) => {
  const classes = useStylesForm();
  const { t } = useTranslation();
  const [country, setCountry] = React.useState("");

  const initState = {
    pib: "",
    phone: "",
    email: "",
  };

  const schema = yup
    .object({
      pib: yup
        .string()
        .trim()
        .matches(
          /[А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z\-]{2,} [А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z\-]{2,} [А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z]{4,}/,
          t("VALIDATION_FULLNAME")
        )
        .required(t("REQUIRED")),
      phone: yup
        .string()
        // .min(10, t("MUST_BE_TEN"))
        // .max(10, t("MUST_BE_TEN"))
        .required(t("REQUIRED")),
      email: yup.string().email(t("ENTER_VALID_EMAIL")).required(t("REQUIRED")),
    })
    .required();

  const formik = useFormik({
    initialValues: initState,
    validationSchema: schema,
    onSubmit: (values: any) => {
      const formElements: any = Array.from(
        document
          ?.getElementById("preorder-form")
          ?.querySelectorAll("input[type=number]") ?? []
      );
      if (formElements.every((e: any) => e.valueAsNumber === 0)) {
        alert(t("PREORDER_ERROR"));
        return;
      }
      onSubmit(values, formElements);
    },
  });

  const handlePhoneChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => {
    const event = {
      target: {
        name: "phone",
        value: formattedValue,
      },
    };

    setCountry(country.name);

    formik.handleChange(event);
  };

  const onSubmit = async (info: any, inputs: any) => {
    const { pib, phone, email } = info;

    const preorders: any = [];
    inputs.forEach((e: any) => {
      if (e.valueAsNumber === 0) return;

      preorders.push({
        full_name: pib,
        phone,
        email,
        count: e.valueAsNumber,
        watch: data?.watches.find((x: any) => x.name === e.name)?.id ?? "0",
        country,
      });
    });

    if (process.env.REACT_APP_PREORDER) {
      await axios
        .post(process.env.REACT_APP_PREORDER, preorders, {
          headers: {
            Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
          },
        })
        .then(() => {
          alert(t("PREORDER_SUCCESS"));
        })
        .catch((e) => console.log("error send preorder data: ", e));
    }

    onFormSubmit();
  };

  return (
    <>
      <IconButton onClick={onGoBackClick} className={classes.closeFormButton}>
        <ChevronLeft />
      </IconButton>
      <Typography className={classes.title}>
        {t("PREORDER_FORM_TITLE")}
      </Typography>
      <form onSubmit={formik.handleSubmit} id="preorder-form">
        <div className={classes.inputBlock}>
          <Typography align="left">{t("FULL_NAME")}*</Typography>
          <FormControl fullWidth>
            <TextField
              name="pib"
              variant="outlined"
              placeholder={t("Ivanov Ivan Ivanovich")}
              value={formik!.values.pib}
              onChange={formik.handleChange}
              error={formik!.touched.pib && Boolean(formik!.errors.pib)}
              required
            />
            <p style={{ color: "#DD284A" }}>
              {formik!.touched.pib && formik!.errors.pib}
            </p>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          <Typography align="left">{t("PHONE_NUMBER")}*</Typography>
          <FormControl fullWidth className={classes.inputPhone}>
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
              }}
              onChange={handlePhoneChange}
              value={formik.values.phone}
              excludeCountries={["ru", "by"]}
              country={"ua"}
            />
            <p style={{ color: "#DD284A" }}>
              {formik!.touched.phone && formik!.errors.phone}
            </p>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          <Typography align="left">E-mail*</Typography>
          <FormControl fullWidth>
            <TextField
              name="email"
              type="email"
              variant="outlined"
              placeholder="example@gmail.com"
              value={formik!.values.email}
              onChange={formik.handleChange}
              error={formik!.touched.email && Boolean(formik!.errors.email)}
              required
            />
            <p style={{ color: "#DD284A" }}>
              {formik!.touched.email && formik!.errors.email}
            </p>
          </FormControl>
        </div>
        <div className={classes.inputBlock}>
          {data?.watches?.map((e: any) => (
            <>
              <Typography align="left">{e?.name}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name={e?.name}
                  type="number"
                  variant="outlined"
                  placeholder="0"
                  inputProps={{
                    min: 0,
                  }}
                  required
                />
              </FormControl>
            </>
          ))}
        </div>
        <Typography>* {t("MANDATORY_FIELD")}</Typography>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={classes.submitButton}
        >
          {t("PREORDER_FORM_SUBMIT")}
        </Button>
      </form>
    </>
  );
};

export const PreorderModal = ({ onClose = null, isOpen = false }: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);
  const [showForm, setShowForm] = React.useState(false);

  const [data, setData]: any = React.useState(null);

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    setOpen(isOpen);
    setShowForm(false);
    loadData();
  }, [isOpen]);

  React.useEffect(() => {
    setTimeout(() => setOpen(true), 1000);
  }, []);

  const handleClose = () => {
    setOpen(false);
    if(onClose != null)
      onClose();
  };

  const loadData = async () => {
    if (!process.env.REACT_APP_PREORDER) return;

    await axios
      .get(process.env.REACT_APP_PREORDER, {
        headers: {
          Authorization: `Token ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then(({ data }: any) => {
        setData(data[0]);
      })
      .catch((e) => console.log("error get preorder data: ", e));
  };

  return (
    <Modal open={open} keepMounted>
      <Box className={classes.wrapper}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButton>
        <div className={classes.content}>
          {showForm ? (
            <FormContent
              onGoBackClick={() => setShowForm(false)}
              onFormSubmit={handleClose}
              data={data}
            />
          ) : (
            <InfoPart data={data} onOrderClick={() => setShowForm(true)} />
          )}
        </div>
      </Box>
    </Modal>
  );
};
