import React from "react";
import { makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { loadBasket } from "../../store/features/shop/Shop";
import Footer from "../UI/Footer";
import { HeaderV2 } from "../UI/HeaderV2";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "12px auto 36px",
    width: "90%",
    maxWidth: 1200,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0px 25px",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  tabs: {
    textAlign: "left",
    width: "35%",
    paddingLeft: 30,
    paddingTop: 40,
    "& .MuiTabs-indicator": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 25,
      margin: "auto",
      paddingTop: 0,
      paddingLeft: 0,
    },
  },
  tab: {
    textTransform: "uppercase",
    margin: 0,
    "& .MuiTab-wrapper": {
      display: "inline",
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    minHeight: 0,
    opacity: 1,
    "& .MuiTab-root": {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  content: {
    backgroundColor: "#fff",
    padding: "24px 32px 34px",
    borderRadius: 10,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  contentHeader: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "left",
    color: "#171926",
    textTransform: "uppercase",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  contentTitle: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: "left",
    textTransform: "inherit",
    color: "#171926",
  },
  contentMiniBlock: {
    marginBottom: 16,
    textAlign: "justify",
  },
  listWrap: {
    listStyle: "disc",
    paddingLeft: 30,
  },
  listNum: {
    listStyle: "decimal !important",
    paddingLeft: 30,
  },
  listDisk: {
    listStyle: "disc !important",
  },
  list: {
    listStyle: "inherit !important",
  },
  policyEndWrap: {
    textAlign: "left",
    marginTop: 55,
  },
  miniTitle: {
    fontWeight: 600,
  },
  link: {
    color: "#171926",
  },
  infoLinks: {
    textAlign: "left",
  },
  customLink: {
    color: "blue",
    textDecoration: "underline",
  },
}));

export const TabContent = (props: any) => {
  const {
    children,
    value,
    index,
    wrapperStyles = null,
    boxStyles = null,
    ...rest
  } = props;

  return (
    <div style={{ ...wrapperStyles }}>
      {value === index && (
        <Box style={{ ...boxStyles }} {...rest}>
          {" "}
          {children}{" "}
        </Box>
      )}
    </div>
  );
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Policy = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [tab, setTab] = React.useState(0);

  let query = useQuery();

  const tabs = [
    { keyword: "cookies", title: "Cookie Policy", tab: 0 },
    { keyword: "privacy", title: "Privacy Policy", tab: 1 },
    { keyword: "offer", title: "Public Offer", tab: 2 },
    { keyword: "health_declaration", title: "Declaration of Health", tab: 3 },
    { keyword: "delivery", title: "Delivery", tab: 4 },
    // {
    //   keyword: "clients_right",
    //   title: "Client's right to be informed",
    //   tab: 5,
    // },
  ];

  React.useEffect(() => {
    const tabKeyword = query.get("tab");
    const selectedTab = tabs.find((x) => x.keyword === tabKeyword)?.tab ?? 0;

    if (selectedTab === tab) return;

    setTab(selectedTab);
  }, [window.location.search]);

  React.useEffect(() => {
    dispatch(loadBasket());
  }, [dispatch]);

  React.useEffect(() => {
    const locale: string = query.get("language") ?? "";
    if (locale !== "") i18n.changeLanguage(locale);
  }, [query.get("language")]);

  React.useEffect(() => {
    document.title = `MISU ${tabs.find((x) => x.tab === tab)?.title}`;
  }, [tab]);

  const handleChangeTab = (event: any, newValue: any) => {
    const locale = query.get("language") ?? "";
    const newTabName = tabs.find((x) => x.tab === newValue)?.keyword ?? "";
    query.set("tab", newTabName);

    window.history.replaceState(
      "",
      "",
      `?tab=${newTabName}${
        locale !== "" ? "&language=" + locale : ""
      }`
    );
    setTab(newValue);
  };

  return (
    <div style={{ width: "100%" }}>
      <HeaderV2 />
      <div className={classes.wrapper}>
        <div className={classes.tabs}>
          <Tabs value={tab} onChange={handleChangeTab} orientation="vertical">
            <Tab
              className={classes.tab}
              value={0}
              label={t("COOKIE_POLICY")}
              title={t("COOKIE_POLICY")}
              style={{ textDecoration: tab === 0 ? "underline" : "none" }}
              disableFocusRipple
            />
            <Tab
              className={classes.tab}
              title={t("PRIVACY_POLICY")}
              value={1}
              label={t("PRIVACY_POLICY")}
              style={{ textDecoration: tab === 1 ? "underline" : "none" }}
              disableFocusRipple
            />
            {tab === 1 && (
              <div className={classes.infoLinks}>
                <ol className={classes.listNum}>
                  <li className={classes.list}>
                    <Typography>
                      <a href="#general-info" className={classes.link}>
                        {t("GENERAL_INFORMATION_TITLE")}
                      </a>
                    </Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>
                      <a href="#data-management" className={classes.link}>
                        {t("DATA_MANAGEMENT")}
                      </a>
                    </Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>
                      <a href="#information-security" className={classes.link}>
                        {t("INFORMATION_SECURE_TITLE")}
                      </a>
                    </Typography>
                    <ul>
                      <li>
                        <Typography>
                          <a href="#cookie-policy" className={classes.link}>
                            {t("COOKIE_POLICY")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a href="#what-cookie" className={classes.link}>
                            {t("WHATS_COOKIE_TITLE")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a href="#cookie-use" className={classes.link}>
                            {t("HOW_WE_USE_COOKIE")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a
                            href="#goals-processing-data"
                            className={classes.link}
                          >
                            {t("PURPOSES_DATA_PROCESSING_TITLE")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a href="#right-manage-data" className={classes.link}>
                            {t("PERSONAL_DATA_TITLE")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a
                            href="#data-retention-period"
                            className={classes.link}
                          >
                            {t("RETENTION_PERIOD_TITLE")}
                          </a>
                        </Typography>
                      </li>
                    </ul>
                  </li>
                  <li className={classes.list}>
                    <Typography>
                      <a href="#subcontracts" className={classes.link}>
                        {t("SUBCONTRACTORS_TITLE")}
                      </a>
                    </Typography>
                    <ul>
                      <li>
                        <Typography>
                          <a href="#consent" className={classes.link}>
                            {t("CONSENT_TITLE")}
                          </a>
                        </Typography>
                      </li>
                      <li>
                        <Typography>
                          <a href="#changes" className={classes.link}>
                            {t("CHANGES_TO_POLICY_TITLE")}
                          </a>
                        </Typography>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            )}
            <Tab
              className={classes.tab}
              title={t("PUBLIC_OFFER")}
              value={2}
              label={t("PUBLIC_OFFER")}
              style={{ textDecoration: tab === 2 ? "underline" : "none" }}
              disableFocusRipple
            />
            <Tab
              className={classes.tab}
              title={t("DECLARATION_STATUS")}
              value={3}
              label={t("DECLARATION_STATUS")}
              style={{ textDecoration: tab === 3 ? "underline" : "none" }}
              disableFocusRipple
            />
            <Tab
              className={classes.tab}
              title={t("DELIVERY")}
              value={4}
              label={t("DELIVERY")}
              style={{ textDecoration: tab === 4 ? "underline" : "none" }}
              disableFocusRipple
            />
            {/* <Tab
              className={classes.tab}
              title={t("CLIENTS_RIGHT_TO_BE_INFORMED")}
              value={5}
              label={t("CLIENTS_RIGHT_TO_BE_INFORMED")}
              style={{ textDecoration: tab === 5 ? "underline" : "none" }}
              disableFocusRipple
            /> */}
          </Tabs>
        </div>
        <div className={classes.content}>
          <TabContent value={tab} index={0}>
            <Typography className={classes.contentHeader}>
              {t("COOKIE_POLICY")}
            </Typography>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("GENERAL_INFORMATION_TITLE")}
              </Typography>
              <Typography>{t("GENERAL_INFORMATION_COOKIE")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("WHATS_COOKIE_TITLE")}
              </Typography>
              <Typography>{t("WHATS_COOKIE")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("HOW_WE_USE_COOKIE")}
              </Typography>
              <ul className={classes.listWrap}>
                <li className={classes.list}>
                  <Typography>{t("HOW_WE_USE_COOKIE_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("HOW_WE_USE_COOKIE_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("HOW_WE_USE_COOKIE_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("HOW_WE_USE_COOKIE_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("HOW_WE_USE_COOKIE_5")}</Typography>
                </li>
              </ul>
              <Typography>{t("HOW_WE_USE_COOKIE_TOTAL")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("HOW_CONTROL_COOKIE_TITLE")}
              </Typography>
              <Typography>{t("HOW_CONTROL_COOKIE")}</Typography>
            </div>
          </TabContent>
          <TabContent value={tab} index={1}>
            <Typography className={classes.contentHeader}>
              {t("PRIVACY_POLICY")}
            </Typography>
            <div className={classes.contentMiniBlock} id="general-info">
              <Typography className={classes.contentTitle}>
                {t("GENERAL_INFORMATION_TITLE")}
              </Typography>
              <Typography>
                {t("GENERAL_INFORMATION_PRIVACY")} <br />
                <a href="mailto:support@misu.in.ua" style={{ color: "blue" }}>
                  Support@misu.in.ua
                </a>
              </Typography>
            </div>
            <div className={classes.contentMiniBlock} id="data-management">
              <Typography className={classes.contentTitle}>
                {t("DATA_MANAGEMENT")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("DATA_MANAGEMENT_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DATA_MANAGEMENT_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DATA_MANAGEMENT_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography> {t("DATA_MANAGEMENT_4")}</Typography>
                  <ul className={classes.listWrap}>
                    <li className={classes.list}>
                      <Typography>{t("DATA_MANAGEMENT_4_1")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DATA_MANAGEMENT_4_2")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DATA_MANAGEMENT_4_3")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DATA_MANAGEMENT_4_4")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DATA_MANAGEMENT_4_5")}</Typography>
                    </li>
                  </ul>
                </li>
                <li className={classes.list}>
                  <Typography>
                    {t("DATA_MANAGEMENT_5")} <br />
                    <a
                      href="mailto:support@misu.in.ua"
                      style={{ color: "blue" }}
                    >
                      support@misu.in.ua
                    </a>
                  </Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DATA_MANAGEMENT_6")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DATA_MANAGEMENT_7")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock} id="information-security">
              <Typography className={classes.contentTitle}>
                {t("INFORMATION_SECURE_TITLE")}
              </Typography>
              <Typography>{t("INFORMATION_SECURE")}</Typography>
              <ol className={classes.listWrap}>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_5")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("INFORMATION_SECURE_6")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock} id="cookie-policy">
              <Typography className={classes.contentTitle}>
                {t("COOKIE_POLICY")}
              </Typography>
              <Typography>{t("GENERAL_INFORMATION_COOKIE")}</Typography>
            </div>
            <div className={classes.contentMiniBlock} id="what-cookie">
              <Typography className={classes.contentTitle}>
                {t("WHATS_COOKIE_TITLE")}
              </Typography>
              <Typography>{t("WHATS_COOKIE")}</Typography>
            </div>
            <div className={classes.contentMiniBlock} id="cookie-use">
              <Typography className={classes.contentTitle}>
                {t("HOW_WE_USE_COOKIE")}
              </Typography>
              <div className={classes.contentMiniBlock}>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("HOW_WE_USE_COOKIE_1")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("HOW_WE_USE_COOKIE_2")} </Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("HOW_WE_USE_COOKIE_3")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("HOW_WE_USE_COOKIE_4")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("HOW_WE_USE_COOKIE_5")}</Typography>
                  </li>
                </ul>
                <Typography>{t("HOW_WE_USE_COOKIE_TOTAL")}</Typography>
              </div>
              <Typography className={classes.miniTitle}>
                {t("HOW_CONTROL_COOKIE_TITLE")}
              </Typography>
              <div className={classes.contentMiniBlock}>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("HOW_CONTROL_COOKIE")}</Typography>
                  </li>
                </ul>
              </div>
              <div
                className={classes.contentMiniBlock}
                id="goals-processing-data"
              >
                <Typography className={classes.miniTitle}>
                  {t("PURPOSES_DATA_PROCESSING_TITLE")}
                </Typography>
                <Typography>{t("PURPOSES_DATA_PROCESSING")}</Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_1")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_2")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_3")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_4")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_5")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_6")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PURPOSES_DATA_PROCESSING_7")}</Typography>
                  </li>
                </ul>
              </div>
              <div className={classes.contentMiniBlock} id="right-manage-data">
                <Typography className={classes.miniTitle}>
                  {t("PERSONAL_DATA_TITLE")}
                </Typography>
                <Typography>{t("PERSONAL_DATA")}</Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("PERSONAL_DATA_1")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PERSONAL_DATA_2")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PERSONAL_DATA_3")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("PERSONAL_DATA_4")}</Typography>
                  </li>
                </ul>
              </div>
              <div
                className={classes.contentMiniBlock}
                id="data-retention-period"
              >
                <Typography className={classes.miniTitle}>
                  {t("RETENTION_PERIOD_TITLE")}
                </Typography>
                <Typography>{t("RETENTION_PERIOD")}</Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("RETENTION_PERIOD_1")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("RETENTION_PERIOD_2")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("RETENTION_PERIOD_3")}</Typography>
                  </li>
                </ul>
              </div>
              <div className={classes.contentMiniBlock} id="subcontracts">
                <Typography className={classes.miniTitle}>
                  {t("SUBCONTRACTORS_TITLE")}
                </Typography>
                <Typography>{t("SUBCONTRACTORS")}</Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>DigitalOcean, LLC</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>Firebase Inc.</Typography>
                  </li>
                </ul>
                <Typography>{t("SUBCONTRACTORS_3")}</Typography>
              </div>
              <div className={classes.contentMiniBlock} id="consent">
                <Typography className={classes.miniTitle}>
                  {t("CONSENT_TITLE")}
                </Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("CONSENT")}</Typography>
                  </li>
                </ul>
              </div>
              <div className={classes.contentMiniBlock} id="changes">
                <Typography className={classes.miniTitle}>
                  {t("CHANGES_TO_POLICY_TITLE")}
                </Typography>
                <ul className={classes.listWrap}>
                  <li className={classes.list}>
                    <Typography>{t("CHANGES_TO_POLICY_1")}</Typography>
                  </li>
                  <li className={classes.list}>
                    <Typography>{t("CHANGES_TO_POLICY_2")}</Typography>
                  </li>
                </ul>
              </div>
            </div>

            <div className={classes.policyEndWrap}>
              <Typography style={{ fontWeight: 600 }}>
                {t("POLICY_STARTS_TITLE")}
              </Typography>
              <Typography>
                {t("POLICY_STARTS")}
                <br />
                <a style={{ color: "blue" }} href="mailto:support@misu.in.ua">
                  support@misu.in.ua
                </a>
              </Typography>
            </div>
          </TabContent>
          <TabContent value={tab} index={2}>
            <Typography className={classes.contentHeader}>
              {t("PUBLIC_OFFER_CONTRACT")}
            </Typography>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("GENERAL_PROVISIONS")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("GENERAL_PROVISIONS_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GENERAL_PROVISIONS_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GENERAL_PROVISIONS_3")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DEFINITIONS")}
              </Typography>
              <Typography>{t("DEFINITIONS_LIST_TITLE")}</Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_5")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DEFINITIONS_LIST_6")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("SUBJECT_OF_CONTRACT")}
              </Typography>
              <Typography>{t("SUBJECT_OF_CONTRACT_DESCRIPTION")}</Typography>
              <Typography>{t("SUBJECT_OF_CONTRACT_LIST_TITLE")}</Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("SUBJECT_OF_CONTRACT_LIST_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("SUBJECT_OF_CONTRACT_LIST_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("SUBJECT_OF_CONTRACT_LIST_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("SUBJECT_OF_CONTRACT_LIST_4")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("ORDER_REGISTRATION")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("ORDER_REGISTRATION_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_REGISTRATION_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_REGISTRATION_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_REGISTRATION_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_REGISTRATION_5")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("ORDER_PAYMENT")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography> {t("ORDER_PAYMENT_1")}</Typography>
                  <ul className={classes.listWrap}>
                    <li className={classes.list}>
                      <Typography>{t("ORDER_PAYMENT_1_1")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("ORDER_PAYMENT_1_2")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("ORDER_PAYMENT_1_3")}</Typography>
                    </li>
                  </ul>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_PAYMENT_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_PAYMENT_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_PAYMENT_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_PAYMENT_5")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("ORDER_PAYMENT_6")}</Typography>
                </li>
              </ol>
              <Typography>{t("ORDER_PAYMENT_END")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DELIVERY_TERMS")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography> {t("DELIVERY_TERMS_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_4")}</Typography>
                  <ul className={classes.listWrap}>
                    <li className={classes.list}>
                      <Typography>{t("DELIVERY_TERMS_4_1")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DELIVERY_TERMS_4_2")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("DELIVERY_TERMS_4_3")}</Typography>
                    </li>
                  </ul>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_5")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_6")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_7")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TERMS_8")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("RESPONSIBILITY")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography> {t("RESPONSIBILITY_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_2")}</Typography>
                  <ul className={classes.listWrap}>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_1")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_2")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_3")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_4")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_5")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_6")}</Typography>
                    </li>
                    <li className={classes.list}>
                      <Typography>{t("RESPONSIBILITY_2_7")}</Typography>
                    </li>
                  </ul>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_5")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_6")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RESPONSIBILITY_7")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("OTHER_CONDITIONS")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("OTHER_CONDITIONS_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("OTHER_CONDITIONS_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("OTHER_CONDITIONS_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("OTHER_CONDITIONS_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("OTHER_CONDITIONS_5")}</Typography>
                </li>
              </ol>
            </div>

            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("RETURNING_GOODS")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("RETURNING_GOODS_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURNING_GOODS_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURNING_GOODS_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURNING_GOODS_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURNING_GOODS_5")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("CONTRACT_AND_ITS_TERMS")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("CONTRACT_AND_ITS_TERMS_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("CONTRACT_AND_ITS_TERMS_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("CONTRACT_AND_ITS_TERMS_3")}</Typography>
                </li>
              </ol>
            </div>
          </TabContent>
          <TabContent value={tab} index={3}>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentHeader}>
                {t("DECLARATION_STATUS_USER")}
              </Typography>
              <Typography>{t("DECLARATION_STATUS_USER_TEXT")}</Typography>
            </div>
          </TabContent>
          <TabContent value={tab} index={4}>
            <Typography className={classes.contentHeader}>
              {t("DELIVERY_AND_RETURN")}
            </Typography>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("TERMS_OF_USE")}
              </Typography>
              <Typography>
                {t("TERMS_OF_USE_1")} <br /> <br />
                {t("TERMS_OF_USE_2")}
              </Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DISCLAIMER_BOLD")}
              </Typography>
              <Typography>{t("DISCLAIMER_TEXT")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("REGISTRATION_ON_SITE")}
              </Typography>
              <Typography>{t("REGISTRATION_ON_SITE_TEXT")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DELIVERY_METHOD")}
              </Typography>
              <Typography>{t("DELIVERY_METHOD_TEXT")}</Typography>
              <Typography>{t("DELIVERY_METHOD_WARNING")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DELIVERY_TIME")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TIME_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TIME_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TIME_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("DELIVERY_TIME_4")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("DELIVERY_COST")}
              </Typography>
              <Typography>{t("DELIVERY_COST_TEXT")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("PAYMENT_METHOD")}
              </Typography>
              <Typography>{t("PAYMENT_METHOD_TEXT")}</Typography>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("GOODS_RETURN")}
              </Typography>
              <Typography>{t("GOODS_RETURN_WARNING")}</Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("GOODS_RETURN_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GOODS_RETURN_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GOODS_RETURN_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GOODS_RETURN_4")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("GOODS_RETURN_5")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("RETURN_COST_BOLD")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("RETURN_COST_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURN_COST_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("RETURN_COST_3")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("SITUATIONS_FOR_RETURN_BOLD")}
              </Typography>
              <ol className={classes.listNum}>
                <li className={classes.list}>
                  <Typography>{t("SITUATIONS_FOR_RETURN_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("SITUATIONS_FOR_RETURN_2")}</Typography>
                </li>
              </ol>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography className={classes.contentTitle}>
                {t("CAN_RETURN_PRODUCT_BOLD")}
              </Typography>
              <ul className={classes.listWrap}>
                <li className={classes.list}>
                  <Typography>{t("CAN_RETURN_PRODUCT_1")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("CAN_RETURN_PRODUCT_2")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("CAN_RETURN_PRODUCT_3")}</Typography>
                </li>
                <li className={classes.list}>
                  <Typography>{t("CAN_RETURN_PRODUCT_4")}</Typography>
                </li>
              </ul>
            </div>
            <div className={classes.contentMiniBlock}>
              <Typography>
                <b>{t("CAN_RETURN_PRODUCT_BOLD_TEXT")}</b>{" "}
                {t("CAN_RETURN_PRODUCT_TEXT")}
              </Typography>
            </div>
          </TabContent>
          <TabContent value={tab} index={5}>
            <Typography className={classes.contentHeader}>
              {t("FINANCIAL_SERVICES")}
            </Typography>
            <div className={classes.contentMiniBlock}>
              <Typography>
                <a
                  href="https://zakon.rada.gov.ua/laws/show/2664-14/ed20220801#n205"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.customLink}
                >
                  {t("JUST_LINK")}
                </a>
              </Typography>
            </div>
          </TabContent>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
