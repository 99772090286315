import React from "react";
import { useDispatch } from "react-redux";
import { resetBasketState } from "../../../store/features/shop/Shop";
import { AppDispatch } from "../../../store/store";

interface BasketHeaderProp {
  title: string | null;
  shouldClearBasket: boolean;
}

export const BasketHeader = ({
  shouldClearBasket = false,
  title = null,
}: BasketHeaderProp) => {
  const dispatch: AppDispatch = useDispatch();
  const onClick = () => {
    if (shouldClearBasket) dispatch(resetBasketState());
  };
  return (
    <>
      <div style={{ height: 75 }}></div>
      <nav
        className="vuso-navbar fixed-top"
        id="vusoNav"
        style={{ padding: "10px 0px" }}
      >
        <div className="container">
          <div className="vuso-navbar-logo-wrapper">
            <a className="navbar-arrow ps-3 mobilescreen" href="/watches" onClick={onClick}>
              <img
                src={window.location.origin + "/image/arrow-left-mini.png"}
                alt="MISU"
              />
            </a>
            <a className="navbar-arrow ps-3 pe-lg-6 fullscreen" href="/watches" onClick={onClick}>
              <img
                src={window.location.origin + "/image/arrow-left-full.png"}
                alt="MISU"
              />
            </a>
            <a className="navbar-brand fullscreen" href="/"  onClick={onClick}>
              <img
                src={window.location.origin + "/image/logo-mini.png"}
                alt="MISU"
              />
            </a>
          </div>
          <div className="vuso-navbar-status">
            <p className="d-block">{title}</p>
          </div>
        </div>
      </nav>
    </>
  );
};
