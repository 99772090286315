import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    backgroundImage: `url(${
      window.location.origin + "/image/vuso_background.svg"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginTop: 20,
    borderRadius: 40,
    marginBottom: 120,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 60,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "auto",
    padding: "40px 60px 60px 10px",
    color: "#1D3A60",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      padding: "16px",
    },
  },
  textBox: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  title: {
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: 36,
    lineHeight: "120%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      fontSize: 30,
    },
  },
  description: {
    width: "100%",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "140%",
    "& ul": {
      marginTop: 40,
      paddingLeft: 16,
      "& li": {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "140%",
        color: "#1D3A60",
        marginBottom: 16,
        position: "relative",
        "&:last-child": {
          marginBottom: 0,
        },
        "&:before": {
          position: "absolute",
          display: "block",
          content: "''",
          width: 8,
          height: 8,
          backgroundColor: "#5D9BF8",
          opacity: 0.5,
          bottom: 9,
          left: "-16px",
          borderRadius: "50%",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 16,
    },
  },
  button: {
    background: "#5985F5",
    borderRadius: 40,
    padding: "12px 28px",
    color: "#FFFFFF",
    fontWeight: 800,
    fontSize: 18,
    width: "40%",
    marginTop: 40,
    textTransform: "none",
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      color: "#FFFFFF",
      background: "#4978F0",
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 20,
      fontSize: 16,
    },
  },
  image: {
    transform: "translateY(60px)",
    [theme.breakpoints.down("md")]: {
      transform: "translateY(0px)",
      "& img": {
        width: "80%",
        height: "80%",
      },
      width: "50%",
      height: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "translateY(0px)",
      "& img": {
        width: "90%",
        height: "90%",
      },
      width: "70%",
      height: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
      marginBottom: 24,
      "& img": {
        width: "90%",
        height: "90%",
      },
    },
  },
}));

export const AlfaMain = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const handleClick = () => {
    history.listen((_) => {
      window.scroll(0, 0);
    });
    history.push("/vuso");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.textBox}>
          <Typography className={classes.title}>
            {t("MEDICAL_ASSISTANCE_ONLINE")}
          </Typography>
          <Typography className={classes.description}>
            {parse(t("MEDICAL_ASSISTANCE_ONLINE_TEXT"))}
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleClick}
          >
            {t("MEDICAL_ASSISTANCE_MORE")}
          </Button>
        </div>
        <div className={classes.image}>
          <img
            src={window.location.origin + "/image/vuso_girl.png"}
            alt="girl"
          />
        </div>
      </div>
    </div>
  );
};
