import {
  FormControl,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 8,
  },
  subtitle: {
    color: "#69779A",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "140%",
    marginBottom: 24,
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100%",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  column: {
    marginRight: 20,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  inputBlock: {
    marginBottom: 24,
    textAlign: "left",
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "&:last-child": {
        marginBottom: 24,
      },
    },
  },
  inputPhone: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: 4,
    textAlign: "left",
    width: "100% !important",
    height: "100% !important",
    "& input": {
      background: "none !important",
      border: "none !important",
      color: "#121212 !important",
      paddingLeft: "0px !important",
      width: "100% !important",
      height: "100% !important",
      padding: "18px 0px",
      textAlign: "left",
    },
    "& .country-list": {
      color: "#8aa2bf",
    },
    "& .form-control": {
      paddingLeft: "50px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
}));

export const InfoForm = React.memo(({ formik, isDisabled = false, onChangePhone = null }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isPreOrder = useSelector((store: RootState) => store.shop.basket.isPreOrder);

  const maxDate = new Date()
    .toLocaleDateString("uk-UA")
    .split(".")
    .reverse()
    .join("-");

    const handlePhoneChange = (
      value: any,
      country: any,
      e: any,
      formattedValue: any
    ) => {
      const event = {
        target: {
          name: "phone",
          value: formattedValue,
        },
      };

      onChangePhone(country.countryCode)
  
      formik.handleChange(event);
    };

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("RECIPIENT")}</Typography>
      <Typography className={classes.subtitle}>
        {t("ORDER_LETTER")}
      </Typography>
      <div className={classes.content}>
        <div className={classes.column}>
          <div className={classes.inputBlock}>
            <Typography align="left">{t("FULL_NAME")}*</Typography>
            <FormControl fullWidth>
              <TextField
                name="pib"
                variant="outlined"
                placeholder={t("Ivanov Ivan Ivanovich")}
                value={formik!.values.pib}
                onChange={formik.handleChange}
                error={formik!.touched.pib && Boolean(formik!.errors.pib)}
                required
                disabled={isDisabled}
              />
              <p style={{ color: "#DD284A" }}>
                {formik!.touched.pib && formik!.errors.pib}
              </p>
            </FormControl>
          </div>
          <div className={classes.inputBlock}>
            <Typography align="left">{t("PHONE_NUMBER")}*</Typography>
            <FormControl fullWidth className={classes.inputPhone}>
            <PhoneInput
              inputProps={{
                name: "phone",
                required: true,
              }}
              onChange={handlePhoneChange}
              value={formik.values.phone}
              excludeCountries={["ru", "by"]}
              country={"ua"}
              disabled={isDisabled}
            />
            <p style={{ color: "#DD284A" }}>
              {formik!.touched.phone && formik!.errors.phone}
            </p>
          </FormControl>
          </div>
          {/* <div className={classes.inputBlock}>
            <Typography align="left">{t("BRACELET")}*</Typography>
            <FormControl fullWidth>
              <Select
                name="for"
                variant="outlined"
                placeholder={t("FOR_SELF")}
                value={formik!.values.for}
                onChange={formik.handleChange}
                error={formik!.touched.for && Boolean(formik!.errors.for)}
                required
                disabled={isDisabled}
              >
                <MenuItem value="self">{t("FOR_SELF")}</MenuItem>
                <MenuItem value="gift">{t("FOR_GIFT")}</MenuItem>
              </Select>
              <p>{formik!.touched.for && formik!.errors.for}</p>
            </FormControl>
          </div> */}
        </div>
        <div className={classes.column}>
          {/* {(formik.values.paymentMethod !== "preOrder" && !isPreOrder) && ( */}
            {/* <div className={classes.inputBlock}>
              <Typography align="left">{t("DATE_OF_BIRTH")}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name="birth"
                  type="date"
                  variant="outlined"
                  value={formik!.values.birth}
                  onChange={formik.handleChange}
                  error={formik!.touched.birth && Boolean(formik!.errors.birth)}
                  inputProps={{ max: maxDate }}
                  required
                  disabled={isDisabled || formik.values.forGift}
                />
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.birth && formik!.errors.birth}
                </p>
              </FormControl>
            </div> */}
          {/* )} */}

          <div className={classes.inputBlock}>
            <Typography align="left">E-mail*</Typography>
            <FormControl fullWidth>
              <TextField
                name="email"
                type="email"
                variant="outlined"
                placeholder="example@gmail.com"
                value={formik!.values.email}
                onChange={formik.handleChange}
                error={formik!.touched.email && Boolean(formik!.errors.email)}
                required
                disabled={isDisabled}
              />
              <p style={{ color: "#DD284A" }}>
                {formik!.touched.email && formik!.errors.email}
              </p>
            </FormControl>
          </div>
        </div>
      </div>
      <Typography>* {t("MANDATORY_FIELD")}</Typography>
    </div>
  );
});
