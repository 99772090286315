import {
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "33px 24px 22px",
    borderRadius: 32,
    marginBottom: 20,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "100%",
    marginBottom: 31,
  },
  paymentTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "150%",
  },
  paymentCost: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "180%",
  },
  paymentTitleTotal: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "150%",
    color: "#1D3A60",
  },
  paymentTotal: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "150%",
    color: "#1D3A60",
  },
  paymentItemWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  paymentTotalWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 13,
    marginBottom: 24,
  },
  formButton: {
    boxShadow: "none",
    borderRadius: 42,
    marginBottom: 16,
    padding: "16px 0px",
    backgroundColor: "#FF5E5E",
    color: "#fff",
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    textTransform: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  deliveryText: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "140%",
    width: "80%",
    color: "#69779A",
  },
}));

export const TotalBlock = ({
  formik,
  showingPassportInfo,
  loadingDelivery,
}: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isEnLocale = i18n.language === "en";
  const basket = useSelector((store: RootState) => store.shop?.basket);

  const [state, setState] = React.useState(formik);

  const validating = () => {
    const allValues = Object.values<string>(state!.values);

    // if (formik.values.forGift) {
    //   return (
    //     allValues.slice(0, 2).every((elem: string) => elem.toString().trim() !== "") &&
    //     allValues.slice(5, 9).every((elem: string) => elem.toString().trim() !== "")
    //   );
    // }

    if (formik.values.paymentMethod === "preOrder") {
      return allValues.slice(0, 3).every((elem: string) => elem.toString().trim() !== "");
    }

    // if (showingPassportInfo()) {
    //   // const onePassportPassed =
    //   //   (formik.values.passportType === "old_passport" &&
    //   //     /[А-ЩЬЮЯҐЄІЇ]{2}[0-9]{6}/.test(formik.values.passportOld)) ||
    //   //   (formik.values.passportType === "id_card" &&
    //   //     /[0-9]{9}/.test(formik.values.passportID));

    //   return allValues
    //     .slice(0, allValues.length - 2)
    //     .every((el: string) => el.toString().trim() !== "");
    //   // && onePassportPassed
    // } else
      return allValues
        .slice(0, allValues.length - 1)
        .every((el: string) => el.toString().trim() !== "");
  };

  const [isValid, setIsValid] = React.useState(validating());

  React.useEffect(() => setState(formik), [formik]);

  React.useEffect(
    () => setIsValid(validating()),
    [formik, showingPassportInfo]
  );

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("ORDER_BASKET")}</Typography>
      <div className={classes.paymentItemWrapper}>
        <Typography className={classes.paymentTitle}>
          {t("ORDER_BASKET")} ({basket?.itemsCount})
        </Typography>
        <Typography className={classes.paymentCost}>
          {+basket!.totalPrice}₴
        </Typography>
      </div>
      <div className={classes.paymentItemWrapper}>
        <Typography className={classes.paymentTitle}>
          {t("DELIVERY")}
        </Typography>
        <Typography className={classes.paymentCost}>
          {loadingDelivery ? (
            <CircularProgress size={18} color="primary" />
          ) : (
            `${basket!.deliveryTax}₴`
          )}
        </Typography>
      </div>

      <Divider variant="fullWidth" style={{ width: "100%" }} />

      <div className={classes.paymentTotalWrapper}>
        <Typography className={classes.paymentTitleTotal}>
          {t("TOTAL")}
        </Typography>
        <Typography className={classes.paymentTotal}>
          {+basket!.totalPrice + +basket!.deliveryTax}₴
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        className={classes.formButton}
        type="submit"
        disabled={!isValid}
        fullWidth
      >
        {t(basket.isPreOrder ? "PRE_ORDER" : "ORDER")}
      </Button>
      <Typography className={classes.deliveryText}>
        {t("DELIVERY_INFO")}
      </Typography>
    </div>
  );
};
