import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./css/owl.css";
import "./css/styles.css";

export const Viva = () => {
  const [itemsCount, setItemsCount] = React.useState(3);
  const settingsSlider = {
    loop: true,
    items: itemsCount,
    center: true,
    margin: 0,
    autoplay: true,
    smartSpeed: 250,
    autoplayTimeout: 5000,
    nav: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1200: {
        items: itemsCount,
      },
    },
  };

  setTimeout(() => setItemsCount(3), 1000);

  React.useEffect(() => {
    setItemsCount(3);
    document.title = "MISU VIVA Tech 2022"
  }, []);

  return (
    <div className="land">
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top px-4 px-sm-3"
        id="mainNav"
      >
        <div className="container-land position-relative">
          <a
            className="navbar-brand display-full"
            href="https://misu.in.ua/"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./assets/img/logo.png" alt="MISU" />
          </a>
          <button className="btn-link-mob btn-land bg-red-land text-blue-land display-mobile">
            <a
              href="mailto:V.shevcvol@misu.in.ua?subject=VivaTech 2022"
              target="_blank"
              rel="noreferrer"
            >
              Contact us
            </a>
          </button>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav text-capitalize py-4 py-lg-0 ps-xl-6 ps-xxl-10">
              <li>
                {" "}
                <a
                  className="navbar-brand display-mobile"
                  href="https://misu.in.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./assets/img/logo.png" alt="MISU" />
                </a>
              </li>
              <li className="nav-item mt-4 mt-lg-0">
                <a className="nav-link" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-nowrap" href="#features">
                  Features of MISU
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-nowrap" href="#partners">
                  Achievements & Partners
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-nowrap" href="#team">
                  Our team
                </a>
              </li>
            </ul>
            <button className="btn-land bg-red-land text-blue-land display-full ms-lg-5">
              <a
                href="mailto:V.shevcvol@misu.in.ua?subject=VivaTech 2022"
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>
            </button>
          </div>
        </div>
      </nav>

      <section className="page-section-land" id="about">
        <div className="container-land">
          <div className="logo-mob-land display-mobile mt-5">
            <a href="https://misu.in.ua/" target="_blank" rel="noreferrer">
              <img src="./assets/img/logo.png" alt="MISU" />
            </a>
          </div>
          <div className="col-12 solid row-land section-1-land">
            <div className="left-content-land col-12 col-lg-7">
              <h1 className="h1-land text-title-land text-white-land">
                New vision of the primary health care
              </h1>
              <p className="text-subtitle-land text-white-land">
                Thanks to modern technologies MISU predicts the occurrence
                of diseases and informs about it!
              </p>
              <div className="applications-btn-wrapper">
                <a href="https://apps.apple.com/ua/app/misu/id1516509504">
                  {" "}
                  <img
                    src={window.location.origin + "/image/appstorebadge.svg"}
                    alt=""
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.WH.MISU">
                  {" "}
                  <img
                    src={window.location.origin + "/image/googleplaybadge.svg"}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="right-content-land col-12 col-lg-5">
              <img src="./assets/img/background/section1-img.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section-land">
        <div className="container-land">
          <div className="section-2-land col-12">
            <div className="text-wrapper-land col-11 col-lg-7">
              <h2 className="h2-land text-dark-blue-land">
                The main causes of death in Europe
              </h2>
              <p className="text-dark-blue-land">
                More than 72% of deaths all over the world, especially in Europe
                are from heart attack and stroke as a result of late reactions
                to heart attacks. To date, no disease prevention measures have
                been fully implemented*
              </p>
              <p className="remark text-grey-land">
                *World Health Organization
              </p>
            </div>
            <div className="col-10 col-lg-5">
              <img
                className=""
                src="./assets/img/background/section2-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="page-section-land">
        <div className="container-land">
          <div className="section-3-land col-12">
            <div className="section-3-land-top col-12">
              <img
                className="img"
                src="./assets/img/icon/section3-img.png"
                alt=""
              />
            </div>
            <div className="section-3-land-bottom col-10 col-xl-9 col-xxl-8 text-wrapper-land">
              <h2 className="h2-land text-white-land">
                MISU can predict critical conditions in 1-2 weeks
              </h2>
              <p className="text-white-land">
                We help you and your family to live longer and we do it today!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land">
        <div className="container-land">
          <div className="section-4-land col-12">
            <div className="section-4-land-top col-12 col-lg-6"></div>
            <div className="section-4-land-bottom col-12 col-lg-6">
              <h2 className="h2-land text-dark-blue-land mb-4 mx-4 mx-lg-4 my-lg-5">
                {" "}
                We{" "}
                <span className="text-blue-land position-relative">
                  make forecasts
                </span>{" "}
                based on your data
              </h2>
              <img
                className="px-3"
                src="./assets/img/background/section4-img.png"
                alt=""
              />
              <p className="remark text-grey-land pt-3 px-3 px-md-5">
                * Blood pressure data are inflated. We advise you to consult
                with a doctor{" "}
              </p>
              <p className="remark text-grey-land px-3 px-md-5">
                ** The forecast is based on the data of your indicators for the
                last few days
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land">
        <div className="container-land">
          <div className="section-5-land col-12">
            <div className="text-wrapper-land col-11 col-lg-7">
              <h2 className="h2-land text-dark-blue-land">
                We will inform if health indicators are unstable
              </h2>
              <p className="text-dark-blue-land">
                In case of deterioration, you will receive the notification and
                the offer with a doctor's consult
              </p>
            </div>
            <div className="col-10 col-lg-5">
              <img
                className=""
                src="./assets/img/background/section5-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land" id="features">
        <div className="container-land">
          <div className="section-6-land col-12">
            <div className="section-6-land-top"></div>
            <h2 className="h2-land text-dark-blue-land my-4">
              Misu starts{" "}
              <span className="text-blue-land position-relative">
                a new stage{" "}
              </span>
              in medicine
            </h2>
            <div className="section-6-land-card col-10 col-sm-6">
              <div className="section-6-land-card-top">
                <img src="./assets/img/icon/section6-img1.png" alt="" />{" "}
              </div>
              <div className="section-6-land-card-bottom">
                <p className="card-land-text px-md-4 px-lg-0">
                  Have launched the process of globalisation of the primary
                  health care and united the best Ukrainian and Polish medical
                  services
                </p>
              </div>
            </div>

            <div className="section-6-land-card col-10 col-sm-6">
              <div className="section-6-land-card-top">
                <img src="./assets/img/icon/section6-img2.png" alt="" />{" "}
              </div>
              <div className="section-6-land-card-bottom">
                <p className="card-land-text px-md-4 px-lg-0">
                  The doctors are given the opportunity to join and create
                  virtual clinics and people get quality health service in line
                </p>
              </div>
            </div>

            <div className="section-6-land-card col-10 col-sm-6">
              <div className="section-6-land-card-top">
                <img src="./assets/img/icon/section6-img3.png" alt="" />{" "}
              </div>
              <div className="section-6-land-card-bottom">
                <p className="card-land-text px-md-4 px-lg-0">
                  Have launched an Uber-doctors to make the medical service more
                  available 24/7
                </p>
              </div>
            </div>

            <div className="section-6-land-card col-10 col-sm-6">
              <div className="section-6-land-card-top">
                <img src="./assets/img/icon/section6-img4.png" alt="" />{" "}
              </div>
              <div className="section-6-land-card-bottom">
                <p className="card-land-text px-md-4 px-lg-0">
                  Critical state is more foreseen than the consequences: use any
                  devices with MISU (Apple, Xiaomi)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land">
        <div className="container-land">
          <div className=" text-center flex-center">
            <h2 className="h2-land text-dark-blue-land w-75 l-h-50">
              The{" "}
              <span className="text-blue-land position-relative">
                {" "}
                MISU achievements
                <img
                  className="position-absolute blue-line-1"
                  src="./assets/img/icon/blue-line.png"
                  alt=""
                />
              </span>{" "}
              we have made over several years
            </h2>
          </div>
          <div className="section-7-land col-12">
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-1.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                Opening new offices in Ukraine and Poland
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-2.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                Cooperation with partners and Alpha Insurance
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-3.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                Partnership with MEDIMOST, Netrix, Amazon
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-4.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                Received the largest grants from the Ukraine and Poland
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-5.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                Raised $300 K investitions from VC
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-6.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                We are preparing to start working in APAC
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-7.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                We are preparing to scale in Africa with JICA
              </p>
            </div>
            <div className="achievements-card">
              {" "}
              <img
                className="achievements-img"
                src="./assets/img/achievements/achievements-8.png"
                alt=""
              />
              <p className="card-land-text px-sm-3">
                {" "}
                <span className="text-blue-land">Ukraine:</span> MISU, Adamant
                Capital, USF
                <span className="text-blue-land"> Poland:</span> Medimost,
                Netrix, Lubelski Park
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land" id="partners">
        <div className="container-land">
          <div className="text-center flex-center">
            <h2 className="h2-land text-dark-blue-land w-75 mb-xl-5 l-h-50">
              Together{" "}
              <span className="text-blue-land position-relative">
                with partners
                <img
                  className="h2-land position-absolute blue-line-2"
                  src="./assets/img/icon/blue-line.png"
                  alt=""
                />{" "}
              </span>
              we build the great future{" "}
            </h2>
          </div>
          <div className="section-8-land">
            <div className="row-land align-items-center justify-content-center block-projects">
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a href="https://moz.gov.ua/" target="_blank" rel="noreferrer">
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-2.png"
                    alt="МЗУ"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a href="https://medimost.eu/" target="_blank" rel="noreferrer">
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-5.png"
                    alt="medimost"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://www.kmu.gov.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-6.png"
                    alt="GOU"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a href="https://alfaic.ua/" target="_blank" rel="noreferrer">
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-1.png"
                    alt="ВУСО страхування"
                    aria-label="partners"
                  />
                </a>
              </div>
            </div>
            <div className="row-land align-items-center justify-content-center block-projects">
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://polandprize.eu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-9.png"
                    alt="Poland Prize"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a href="https://usf.com.ua/" target="_blank" rel="noreferrer">
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-10.png"
                    alt="USF"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://netrix.ventures/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-7.png"
                    alt="Netrix"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://adamant-capital.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-8.png"
                    alt="Adamant Capital"
                    aria-label="partners"
                  />
                </a>
              </div>
            </div>
            <div className="row-land align-items-center justify-content-center block-projects">
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a href="https://itarena.ua/" target="_blank" rel="noreferrer">
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-3.png"
                    alt="ARENA"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://www.jica.go.jp/english/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-4.png"
                    alt="JICA"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://www.amazon.com/
                        "
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-11.png"
                    alt="Amazon"
                    aria-label="partners"
                  />
                </a>
              </div>
              <div className="col-6 col-lg-3 col-md-4 col-sm-6 my-1">
                <a
                  href="https://www.k-startupgc.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="img-brand d-block mx-auto"
                    src="./assets/img/logos/partner-12.png"
                    alt="K-SGC"
                    aria-label="partners"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-section-land  position-relative" id="team">
        <div className="container-land">
          <div className="section-9-land col-12">
            <div className="section-9-land-top"></div>
            <div className="section-9-land-card">
              <div className="section-9-land-card-top">
                <img src="./assets/img/icon/logo.png" alt="" />
              </div>
              <div className="section-9-land-card-bottom">
                <h2 className="h2-land text-dark-blue-land">
                  MISU{" "}
                  <span className="text-blue-land">saves people's lives</span>,
                  that's why we are happy
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="container-land position-absolute carousel-wrapper">
          <section className="team-carousel">
            <OwlCarousel
              className="owl-carousel owl-theme"
              id="slider"
              {...settingsSlider}
              items={itemsCount}
            >
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-01.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-02.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-03.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-04.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-05.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-06.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-07.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-08.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-09.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-092.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="team">
                <div className="card-land text-center">
                  <img
                    className="card-land-body"
                    src="./assets/img/card/Card-091.png"
                    alt=""
                  />
                </div>
              </div>
            </OwlCarousel>
          </section>
        </div>
      </section>

      <section className="page-section-land">
        <div className="container-land">
          <div className="col-12 solid-2 row-land section-10-land">
            <div className="left-content-land col-12 col-lg-7">
              <h2 className="h2-land text-title-land text-white-land">
                Misu has already raised 300 000$ and want to scale
              </h2>
              <p className="text-subtitle-land text-white-land">
                If you are ready to change primary medicine — join to us and
                MISU! We need mentors and investors in US and Europe
              </p>
              <button className="btn-land bg-white-land text-blue-land">
                <a
                  href="mailto:V.shevcvol@misu.in.ua?subject=VivaTech 2022"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact us
                </a>
              </button>
            </div>
            <div className="right-content-land col-12 col-lg-5">
              <img src="./assets/img/background/section10-img.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <footer className="footer-land py-4">
        <div className="container-land">
          <div className="row-land align-items-center">
            <div className="col-12 text-center text-grey-land">
              2022 &copy; MISU. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
