import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { VUSO } from "./components/Pages/VUSO";
import { AppDownload } from "./components/Pages/AppDownload";
import { Instructions } from "./components/Pages/Instructions";
import { MainV2 } from "./components/Pages/MainV2";
import { PageWrap } from "./components/Pages/PageWrap";
import { PaymentResult } from "./components/Pages/PaymentResult";
import { Policy } from "./components/Pages/Policy";
import { Shop } from "./components/Pages/Shop";
import { Viva } from "./components/Pages/Viva/Viva";
import { ThemeWrapper } from "./components/Themes/ThemeWrapper";
import { BasketV2 } from "./components/UI/Basket/BasketV2.jsx";
import { Poll } from "./components/Pages/Poll";

const NoMatch = () => <div>404</div>;

export const Routes = () => {
  return (
    <>
      <ThemeWrapper>
        <Router>
          <Switch>
            <Route path="/" exact>
              <MainV2 />
            </Route>
            <Route path="/watches">
              <PageWrap fullScreen>
                <Shop />
              </PageWrap>
            </Route>
            <Route path="/basket">
              <PageWrap fullScreen>
                <BasketV2 />
              </PageWrap>
            </Route>
            <Route path="/AppDownload.html">
              <Redirect to="/invite" />
            </Route>
            <Route path="/invite">
              <PageWrap fullScreen>
                <AppDownload />
              </PageWrap>
            </Route>
            <Route
              path={[
                "/policy",
                "/policy?tab=cookie",
                "/policy?tab=privacy",
                "/policy?tab=offer",
                "/policy?tab=delivery",
                "/policy?tab=health_declaration",
                "/policy?tab=clients_right",
              ]}
              exact
            >
              <PageWrap fullScreen>
                <Policy />
              </PageWrap>
            </Route>
            <Route path="/instructions">
              <PageWrap fullScreen>
                <Instructions />
              </PageWrap>
            </Route>
            <Route path="/alfa">
              <Redirect to="/vuso" />
            </Route>
            <Route path="/vuso">
              <PageWrap>
                <VUSO />
              </PageWrap>
            </Route>
            <Route path="/payment">
              <PageWrap fullScreen>
                <PaymentResult />
              </PageWrap>
            </Route>
            <Route path="/poll">
              <Poll />
            </Route>
            <Route path="/viva">
              <Viva />
            </Route>
            <Route path="/not-found">
              <PageWrap fullScreen>
                <NoMatch />
              </PageWrap>
            </Route>
            <Route path="*">
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </Router>
      </ThemeWrapper>
    </>
  );
};
