import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface NewsItem {
  id: number;
  date: string;
  image: string;
  title: string;
  link: string;
  text: string;
}

interface NewsState {
  news: Array<NewsItem> | null | [];
}

const initialState: NewsState = { news: [] };

export const loadNews = createAsyncThunk("news/loadNews", async () => {
  if (!process.env.REACT_APP_GET_NEWS) return;

  const data: Array<NewsItem> = (await axios
    .get(process.env.REACT_APP_GET_NEWS)
    .then((response: any) => {
      return response.data as Array<NewsItem>;
    })
    .catch((e) => {
      console.log("error loading news: ", e);
      return [];
    })) as Array<NewsItem>;

  return data;
});

const newsSlice = createSlice({
  name: "news",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadNews.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.news = action.payload.map((newsItem: NewsItem) => ({
        ...newsItem,
        image: process.env.REACT_APP_BACKEND + newsItem.image,
      })).slice(0, 15);
    });
  },
});

//export const {} = newsSlice.actions;
export default newsSlice.reducer;
