import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100vw",
    height: "100%",
    backgroundColor: "#fff",
    color: "#000",
    paddingTop: 32,
    boxShadow: "0px -5px 40px rgba(41, 105, 188, 0.05)",
    paddingBottom: 17,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: 29,
    },
  },
  columnsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      justifyContent: "start",
      alignItems: "flex-start",
    },
  },
  bottomColumn: {
    paddingLeft: 35,
    paddingRight: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  socialWrapper: {
    marginBottom: 70,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 60,
    },
  },
  socialRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  socialText: {
    fontSize: 16,
    color: "#171926",
    marginBottom: 10,
  },
  socialButton: {
    display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    width: 30,
    height: 30,
    "& img": {
      width: "100%",
      height: "100%",
    },
    // backgroundColor: "#171926",
    // borderRadius: 100,
    "&:hover": {
      cursor: "pointer",
    },
    "&:not(:last-child)": {
      marginRight: 9,
    },
  },
  leftFooter: {
    paddingLeft: 35,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 35,
    },
  },
  rightFooter: {
    paddingRight: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
      alignItems: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      alignItems: "baseline",
      width: "100%",
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
  logoBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      margin: 0,
    },
  },
  image: {
    marginRight: 15,
    width: 50,
    height: 50,
  },
  links: {
    display: "flex",
    alignItems: "flex-start",
    width: 450,
    justifyContent: "space-between",
    textAlign: "left",
    [theme.breakpoints.down(700)]: {
      width: 350,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "start",
      width: "100%",
    },
  },
  linksBlock: {
    [theme.breakpoints.down("xs")]: {
      margin: "30px 0px",
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  link: {
    marginBottom: 12,
    fontSize: 16,
    color: "#1D3A60",
    textAlign: "left",
    fontWeight: 500,
    lineHeight: "140%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      color: "#FF2D55",
    },
  },
  downloadButtons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  button: {
    marginBottom: 12,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      marginRight: 14,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  rightText: {
    fontSize: 24,
  },
  rightFooterTextWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 35,
    },
  },
  contactsWrapper: {
    textAlign: "right",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      margin: "40px auto",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      marginLeft: 0,
    },
  },
  telText: {
    color: "#1D3A60",
    fontWeight: 800,
    textDecoration: "none",
    lineHeight: "100%",
    fontSize: 16,
  },
  emailText: {
    color: "#69779A",
    opacity: 0.85,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "100%",
    marginTop: 10,
    textDecoration: "underline",
  },
  policyText: {
    fontSize: 14,
    color: "#69779A",
    fontWeight: 300,
    marginTop: 16,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  policyWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      margin: "16px auto 24px",
    },
  },
  policyLink: {
    marginBottom: 16,
    opacity: 0.75,
    fontSize: 12,
    color: "#000",
    textAlign: "left",
    "&:last-child": {
      marginBottom: 0,
    },
    "&:hover": {
      cursor: "pointer",
      color: "#FF2D55",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  policyLinksWrapper: {
    textAlign: "left",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  portmoneImagesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 30,
  },
  portmoneImage: {
    width: 95,
    height: 40,
    borderRadius: 5,
    "&:not(:last-child)": {
      marginRight: 10,
    },
  },
  linksTitle: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    color: "#1D3A60",
    marginBottom: 16,
  },
  onlyMobile: {
    display: "none",
    [theme.breakpoints.down(1024)]: {
      display: "block",
    },
  },
  onlyPC: {
    display: "block",
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  bottomRow: {
    paddingLeft: 35,
    paddingRight: 35,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  linksWrapper: {
    width: "100%",
    height: 40,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(1024)]: {
      display: "none",
    },
  },
  linksRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "-5%",
    width: "100%",
    "& p, & p a": {
      marginBottom: 0,
      fontSize: "12px !important",
      marginRight: 24,
      "&:last-child": {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "-10%",
    },
  },
}));

const Footer = ({ t }: any) => {
  const classes = useStyles();

  const history = useHistory();

  const goToMain = () => {
    if (window.location.pathname !== "/") {
      history.push("/");
    }
  };

  const goToInstruct = () => {
    history.push("/instructions");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.columnsWrapper}>
        <div className={classes.leftFooter}>
          <div className={classes.logoBlock}>
            <img
              src={window.location.origin + "/assets/images/logo.png"}
              alt="logo"
              className={classes.image}
            />
            <Typography
              variant="h5"
              style={{ fontFamily: "'Manrope'", fontSize: 24, fontWeight: 500 }}
            >
              MISU
            </Typography>
          </div>
          <div className={classes.links}>
            <div className={classes.linksBlock}>
              <Typography className={classes.linksTitle}>
                {t("COMPANY")}
              </Typography>

              <Typography className={classes.link} onClick={goToMain}>
                <a href="#monitoring" className={classes.link}>
                  {t("HOW_MISU_WORKS")}
                </a>
              </Typography>
              <Typography className={classes.link} onClick={goToMain}>
                <a href="#connection" className={classes.link}>
                  MISU Watch
                </a>
              </Typography>
              <Typography className={classes.link} onClick={goToMain}>
                <a href="#partners" className={classes.link}>
                  {t("PARTNERS")}
                </a>
              </Typography>
              {/* <Typography className={`${classes.link}`} onClick={goToMain}>
                <a href="#whats-new" className={classes.link}>
                  {t("ABOUT_US")}
                </a>
              </Typography> */}
              {/* <Typography className={classes.link} onClick={goToInstruct}>
                <a href="#connection" className={classes.link}>
                  {t("INSTRUCTION")}
                </a>
              </Typography> */}
            </div>
            <div className={classes.linksBlock}>
              <Typography className={classes.linksTitle}>
                {t("FOR_CLIENT")}
              </Typography>
              <Typography className={`${classes.link} ${classes.onlyMobile}`}>
                <Link to="/policy?tab=privacy" className={classes.link}>
                  {t("PRIVACY_POLICY")}
                </Link>
              </Typography>
              <Typography className={`${classes.link} ${classes.onlyMobile}`}>
                <Link to="/policy?tab=cookie" className={classes.link}>
                  {t("COOKIE_USAGE")}
                </Link>
              </Typography>
              <Typography className={classes.link}>
                <Link to="/policy?tab=delivery" className={classes.link}>
                  {t("DELIVERY")}
                </Link>
              </Typography>
              <Typography className={classes.link}>
                <Link to="/policy?tab=offer" className={classes.link}>
                  {t("PUBLIC_OFFER")}
                </Link>
              </Typography>
              <Typography className={`${classes.link} ${classes.onlyMobile}`}>
                <Link
                  to="/policy?tab=health_declaration"
                  className={classes.link}
                >
                  {t("DECLARATION")}
                </Link>
              </Typography>
              {/* <Typography className={classes.link}>
                <Link to="/policy?tab=clients_right" className={classes.link}>
                  {t("CLIENTS_RIGHT_TO_BE_INFORMED")}
                </Link>
              </Typography> */}
            </div>
          </div>
        </div>
        <div className={classes.rightFooter}>
          <div className={classes.contactsWrapper}>
            <Typography>
              <a className={classes.telText} href="tel:+380 50 448 45 78">
                +380 50 448 45 78
              </a>
            </Typography>
            <Typography>
              <a href="mailto:support@misu.in.ua" className={classes.emailText}>
                support@misu.in.ua
              </a>
            </Typography>
          </div>
          <div className={classes.socialWrapper}>
            <div className={classes.socialRow}>
              <div className={classes.socialButton}>
                <a
                  href="https://www.linkedin.com/company/misu-medical-information-system-of-ukraine/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/linkedin_header.svg"}
                    alt="linkedin"
                  />
                </a>
              </div>
              <div className={classes.socialButton}>
                <a
                  href="https://www.instagram.com/misu.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/instagram_header.svg"}
                    alt="instagram"
                  />
                </a>
              </div>
              <div className={classes.socialButton}>
                <a
                  href="https://www.facebook.com/misu.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/facebook_header.svg"}
                    alt="facebook"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.bottomRow}>
        <div className={classes.portmoneImagesContainer}>
          <img
            src={window.location.origin + "/image/portmone_icon.svg"}
            alt="portmone"
            className={classes.portmoneImage}
          />
          <img
            src={window.location.origin + "/image/mastercard_icon.svg"}
            alt="mastercard"
            className={classes.portmoneImage}
            style={{ width: 72 }}
          />
          <img
            src={window.location.origin + "/image/visa_icon.svg"}
            alt="visa"
            className={classes.portmoneImage}
          />
        </div>
        <div className={classes.linksWrapper}>
          <div className={classes.linksRow}>
            <Typography className={`${classes.link} ${classes.onlyPC}`}>
              <Link to="/policy?tab=privacy" className={classes.link}>
                {t("PRIVACY_POLICY")}
              </Link>
            </Typography>
            <Typography className={`${classes.link} ${classes.onlyPC}`}>
              <Link to="/policy?tab=cookie" className={classes.link}>
                {t("COOKIE_USAGE")}
              </Link>
            </Typography>
            <Typography className={`${classes.link} ${classes.onlyPC}`}>
              <Link
                to="/policy?tab=health_declaration"
                className={classes.link}
              >
                {t("DECLARATION")}
              </Link>
            </Typography>
          </div>
        </div>
        <div className={classes.downloadButtons}>
          <div className={classes.button}>
            <a
              href="https://play.google.com/store/apps/details?id=com.WH.MISU"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={window.location.origin + "/image/googleplaybadge.svg"}
                alt="googleplay"
              />
            </a>
          </div>
          <div className={classes.button}>
            <a
              href="https://apps.apple.com/ua/app/misu/id1516509504"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={window.location.origin + "/image/appstorebadge.svg"}
                alt="appstore"
              />
            </a>
          </div>
        </div>
      </div>
      <div className={classes.bottomColumn}>
        <Typography className={classes.policyText}>
          © Copyright 2021 MISU. All rights reserved
        </Typography>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);
