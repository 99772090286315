import React from "react";
import { MenuItem, Select, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePricesByLocale } from "../../store/features/shop/Shop";

interface Locale {
  id: string;
  name: string;
  label: string;
}

const useStyles = makeStyles({
  select: {
    color: "#1D3A60",
    "&.MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
});

export const LocaleDropDown = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const locales: Array<Locale> = [
    { id: "1", name: "en", label: t("US") },
    { id: "2", name: "pl", label: t("PL") },
    { id: "3", name: "ua", label: t("UA") },
  ];

  const [locale, setLocale] = React.useState<string>(
    locales?.find((loc) => loc?.name === i18n?.language)?.id ?? ""
  );

  React.useEffect(() => {
    setLocale(locales?.find((loc) => loc?.name === i18n?.language)?.id ?? "");
  }, [i18n.language])

  const onChangeLocale = (event: any) => {
    const newLocale = event.target.value;
    if (newLocale === locale) return;

    if (newLocale === "2") {
      window.open("https://misu.health/");
      return;
    }

    setLocale(newLocale);

    const newLocaleName =
      locales.find((loc) => loc.id === newLocale)?.name ?? "";
    i18n.changeLanguage(newLocaleName);

    localStorage.setItem("misu_locale", newLocaleName);
    // dispatch(changePricesByLocale(newLocaleName));
  };

  return (
    <>
      <Select
        className={classes.select}
        renderValue={() => {
          const current = locales.find((loc) => loc?.id === locale);
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="language-flag"
                src={
                  window.location.origin +
                  `/image/header/${current?.label ?? ""}.svg`
                }
                alt=""
              />
              <Typography className="language-link">
                {current?.label ?? ""}
              </Typography>
            </div>
          );
        }}
        value={locale}
        onChange={onChangeLocale}
      >
        {locales.map((locale) => (
          <MenuItem key={locale.id.toString()} value={locale.id}>
            <img
              src={window.location.origin + `/image/header/${locale.label}.svg`}
              className="language-flag"
              alt=""
            />{" "}
            <span className="language-link">{locale.label}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
