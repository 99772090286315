import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    position: "fixed",
    zIndex: 1305,
    background: "rgba(29, 58, 96, 0.4)",
  },
  wrapper: {
    width: "90%",
    position: "fixed",
    bottom: "2%",
    left: "5%",
    padding: "14px 40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 12,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: "16px 10px",
      width: "100%",
      bottom: 0,
      left: 0,
      borderRadius: 0,
    },
  },
  textWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#1D3A60",
    marginRight: 40,
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 25,
      marginRight: 0,
    },
  },
  pcCookie: {
    display: "flex",
    "& img": {
      width: 70,
      height: 70,
    },
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileCookie: {
    display: "none",
    "& img": {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginRight: 14,
    },
  },
  title: {
    width: "100%",
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  description: {
    color: "#1D3A60",
    textAlign: "justify",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
  },
  buttonsWrapper: {
    width: "25%",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  buttonContained: {
    textTransform: "none",
    color: "#F8F8F8",
    marginRight: 10,
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    padding: "16px 28px",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 8,
    },
  },
  link: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
    marginTop: 8,
    color: "#5D9BF8",
    display: "block",
    textDecoration: "underline",
    position: "relative",
    // "&:after": {
    //   content: "''",
    //   position: "absolute",
    //   width: "100%",
    //   height: 1,

    // },
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
    },
  },
}));

export const CookieConfirm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const [show, setShow] = React.useState(!!!cookies.get("cookie"));
  const onAgree = () => {
    let expires = new Date();
    expires.setDate(new Date().getDate() + 14);
    cookies.set("cookie", true, { path: "/", expires });
    setShow(false);
  };

  if (show) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  if (!show) return null;

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.textWrapper}>
          <div className={classes.pcCookie}>
            <img
              src={window.location.origin + "/image/cookies_image.svg"}
              alt="cookies"
            />
          </div>
          <div>
            <div className={classes.titleWrapper}>
              <div className={classes.mobileCookie}>
                <img
                  src={window.location.origin + "/image/cookies_image.svg"}
                  alt="cookies"
                />
              </div>
              <Typography className={classes.title}>
                {t("COOKIE_TITLE")}
              </Typography>
            </div>
            <div className={classes.description}>
              <Typography className={classes.description}>
                {t("USE_COOKIE_TEXT")}
              </Typography>
              <Link className={classes.link} to="/policy">
                {t("ABOUT_TERMS")}
              </Link>
            </div>
          </div>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.buttonContained}
            variant="contained"
            color="primary"
            onClick={onAgree}
            fullWidth
          >
            {t("ACCEPT_ALL")}
          </Button>
        </div>
      </div>
    </div>
  );
};
