import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Add, Close, Remove } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  BasketItem,
  minusItemCount,
  plusItemCount,
  removeFromBasket,
} from "../../../store/features/shop/Shop";
import { AppDispatch } from "../../../store/store";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "20px 24px 20px 0px",
    borderRadius: 32,
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 12px 10px 0px",
    },
  },
  leftPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "left",
    width: "85%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "75%",
    },
  },
  infoBlock: {
    height: "100%",
    width: "100%",
  },
  imageBlock: {
    maxWidth: 130,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      width: "80%",
      marginRight: 0,
    },
  },
  rightPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
    height: "100%",
  },
  rightTopPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 100,
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: 60,
    },
  },
  counter: {
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 90,
  },
  counterColor: {
    fontWeight: 600,
    fontSize: 16,
    // opacity: 0.6,
  },
  vusoButton: {
    background: "rgba(93, 155, 248, 0.11)",
    borderRadius: 40,
    color: "#5D9BF8",
    boxShadow: "none",
    textTransform: "none",
    fontWeight: 800,
    fontSize: 12,
    lineHeight: "140%",
    padding: "12px 23px",
    transition: "0.15s",
    "&:hover": {
      color: "#fff",
      background: "#5D9BF8",
      boxShadow: "none",
    },
  },
  name: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "120%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  price: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "120%",
  },
  color: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
  },
  image: {
    width: "80%",
    height: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   width: "auto",
    // },
  },
}));

interface PropsItem {
  item: BasketItem | null;
}

export const BasketItemV2 = ({ item }: PropsItem) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isEnLocale = i18n.language === "en";
  const [count, setCount] = React.useState<number>(item?.count ?? 0);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setCount(item?.count ?? 0);
  }, [item]);

  const plusCounter = () => {
    dispatch(plusItemCount(item?.id_name ?? ""));
    setCount((prev: number) => prev + 1);
  };

  const minusCounter = () => {
    if (count === 1) {
      remove();
      return;
    }

    dispatch(minusItemCount(item?.id_name ?? ""));
    setCount((prev: number) => prev - 1);
  };

  const remove = () => {
    dispatch(removeFromBasket(item?.id_name ?? ""));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.leftPart}>
        <div className={classes.imageBlock}>
          <img
            className={classes.image}
            src={item?.image ?? ""}
            alt="bracelet"
          />
        </div>
        <div className={classes.infoBlock}>
          <Typography className={classes.name}>
            MISU Watch {item?.name.toUpperCase()}
          </Typography>
          <Typography className={classes.color}>
            {t(item?.color?.label ?? "")}
          </Typography>
          <div className={classes.counter}>
            <IconButton
              size="small"
              className={classes.counterColor}
              onClick={minusCounter}
            >
              <Remove />
            </IconButton>
            <Typography className={classes.counterColor}>{count}</Typography>
            <IconButton
              size="small"
              className={classes.counterColor}
              onClick={plusCounter}
            >
              <Add />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={classes.rightPart}>
        <div className={classes.rightTopPart}>
          <Typography className={classes.price}>{item?.price}₴</Typography>
          <IconButton onClick={remove}>
            <Close />
          </IconButton>
        </div>
        {/* <div>
          <Button
            variant="contained"
            className={classes.vusoButton}
            onClick={() => window.open("/vuso")}
          >
            Медична допомога Онлайн
          </Button>
        </div> */}
      </div>
    </div>
  );
};
