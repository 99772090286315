import {
  Button,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import {
  loadBasket,
  resetAllExceptOrder,
  resetBasketState,
} from "../../../store/features/shop/Shop";
import { AppDispatch, RootState } from "../../../store/store";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    backgroundImage: `url(${
      window.location.origin + "/image/vuso_background.svg"
    })`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    marginTop: 20,
    borderRadius: 32,
  },
  content: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    height: "auto",
    padding: "80px 60px 60px 30px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      padding: "16px",
    },
  },
  textBox: {
    textAlign: "left",
    color: "#1D3A60",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  title: {
    fontFamily: "Manrope",
    fontWeight: 800,
    fontSize: 36,
    lineHeight: "120%",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      fontSize: 30,
    },
  },
  description: {
    width: "100%",
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "140%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 16,
    },
  },
  button: {
    background: "#5985F5",
    borderRadius: 40,
    padding: "12px 28px",
    color: "#FFFFFF",
    fontWeight: 800,
    fontSize: 18,
    width: "55%",
    marginTop: 30,
    textTransform: "none",
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      background: "#4978F0",
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("md")]: {
      width: "45%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 20,
      fontSize: 16,
    },
  },
  image: {
    transform: "translateY(60px)",
    [theme.breakpoints.down("md")]: {
      transform: "translateY(0px)",
      "& img": {
        width: "80%",
        height: "80%",
      },
      width: "40%",
      height: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "75%",
      height: "75%",
    },
  },
  link: {
    cursor: "pointer",
    color: "#FF5E5E",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    textDecoration: "underline",
    marginBottom: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  timerText: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "140%",
    color: "#69779A",
    marginBottom: 16,
  },
}));

const SubmittedBlock = () => {
  const classes = useStyles();
  const history = useHistory();
  const prevOrder = useSelector(
    (store: RootState) => store.shop.basket.prevOrder
  );
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const handleClick = () => {
    history.listen((_) => {
      window.scroll(0, 0);
    });
    dispatch(resetBasketState());
    history.push("/");
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.textBox}>
          <Typography className={classes.title}>
            {t("ACTIVATION_COMPLETE_TITLE")}
          </Typography>
          <Typography className={classes.description}>
            {t("ACTIVATION_COMPLETE_TEXT")}
          </Typography>
          <Typography className={classes.description} style={{ marginTop: 20 }}>
            {t("ORDER_SENT_TO_EMAIL")} {prevOrder?.email}
          </Typography>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleClick}
          >
            {t("GO_TO_MAIN")}
          </Button>
        </div>
        <div className={classes.image}>
          <img
            src={window.location.origin + "/image/vuso_girl.svg"}
            alt="girl"
          />
        </div>
      </div>
    </div>
  );
};

const Timer = ({ onTimerEnd, started }: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [seconds, setSeconds] = React.useState<number>(0);

  const mins = Math.trunc(seconds / 60);
  const leftSecs = seconds - mins * 60;

  React.useEffect(() => {
    if (started) setSeconds(90);
  }, [started]);

  React.useEffect(() => {
    if (seconds === 0) return;

    const interval = setTimeout(() => {
      if (seconds === 1) {
        onTimerEnd();
        return;
      }
      setSeconds((prev: number) => --prev);
    }, 1000);
    return () => clearTimeout(interval);
  }, [seconds]);

  return (
    <Typography
      className={classes.timerText}
      style={{ display: !started ? "none" : "block" }}
    >
      {t("RESEND_CODE_TIMER_TEXT")}{" "}
      <b>
        {mins > 0 ? `${mins} ${t("MIN")}` : ""}{" "}
        {leftSecs > 0 ? `${leftSecs} ${t("SEC")}` : ""}
      </b>
    </Typography>
  );
};

export const Success = ({ onActivated }: any) => {
  const classes = useStyles();
  const [submitted, setSubmitted] = React.useState(false);
  const { t } = useTranslation();
  const [inputs, setInputs] = React.useState<Array<any>>([]);
  const [error, setError] = React.useState<string>("");
  const [resended, setResended] = React.useState<boolean>(true);
  const [showTimer, setShowTimer] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch();
  const phone = `${
    useSelector((store: RootState) => store.shop.basket?.prevOrder)?.phone ??
    "+380 (50) 789 87 64"
  }`;

  React.useEffect(() => {
    dispatch(loadBasket());
  }, []);

  const clickArrow = (left: boolean) => {
    let focused = document.activeElement;
    if (!focused || focused == document.body) focused = null;
    else if (document.querySelector) focused = document.querySelector(":focus");

    if (focused?.tagName.toLowerCase() === "input") {
      const index: number = Number(focused.getAttribute("name")?.split("_")[1]);
      if (left && index != 1) {
        document.getElementById(`input_${index - 1}`)?.focus();
      }
      if (!left && index != 4) {
        document.getElementById(`input_${index + 1}`)?.focus();
      }
    }
  };

  React.useEffect(() => {
    const callback = (e: any) => {
      switch (e.keyCode) {
        case 37:
          clickArrow(true);
          break;
        case 38:
          clickArrow(true);
          break;
        case 39:
          clickArrow(false);
          break;
        case 40:
          clickArrow(false);
          break;
      }
    };

    document.addEventListener("keydown", callback);
    return () => {
      document.removeEventListener("keydown", callback);
    };
  }, []);

  const handleChangeOnlyDigit = (e: any, isNumeric: boolean) => {
    const val = e.target.value;

    if (isNumeric && Number.isNaN(Number.parseInt(val))) return;
    if (!isNumeric && !Number.isNaN(Number.parseInt(val))) return;

    const id = Number(e.target.name.split("_")[1]);
    if (val.trim() === "" && id != 1) {
      document.getElementById(`input_${id - 1}`)?.focus();
    }

    if (id != 4 && val.trim() !== "")
      document.getElementById(`input_${id + 1}`)?.focus();

    setInputs((prev: Array<any>) => {
      const newState = prev.slice();
      newState[id - 1] = val?.toUpperCase();
      return newState;
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!process.env.REACT_APP_ASSISTANCE_CHECK) return;
    setLoading(true);
    await axios
      .post(
        process.env.REACT_APP_ASSISTANCE_CHECK,
        { phone, code: inputs.join("") },
        { headers: { Authorization: `Token ${process.env.REACT_APP_TOKEN}` } }
      )
      .then((response: any) => {
        console.log(response);
        if (response.status === 200) {
          onActivated();
          setSubmitted(true);
          dispatch(resetAllExceptOrder());
        }
      })
      .catch((e: any) => {
        console.log("error verify sms code");
        setError(t("ERROR_ACTIVATION_CODE"));
        setTimeout(() => setError(""), 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendSMS = async (e: any) => {
    e.preventDefault();
    if (
      !process.env.REACT_APP_ASSISTANCE_REPEAT_CODE ||
      phone === "+380 (50) 789 87 64"
    )
      return;

    await axios
      .post(
        process.env.REACT_APP_ASSISTANCE_REPEAT_CODE,
        { phone },
        { headers: { Authorization: `Token ${process.env.REACT_APP_TOKEN}` } }
      )
      .then((response: any) => {
        console.log(response);
        setResended(true);
        setShowTimer(true);
      })
      .catch((e: any) => {
        console.log("error resend sms code");
        setError(t("ERROR_ACTIVATION_PHONE"));
        setTimeout(() => setError(""), 5000);
      });
  };

  if (submitted) return <SubmittedBlock />;

  return (
    <div className="page-section-vuso col-12 col-md-6 col-lg-4">
      <div className="container-vuso">
        <div className="section-vuso-text">
          <p className="activation-checkin">{t("COMPLETION_ACTIVATION")}</p>
          {parse(t("COMPLETION_ACTIVATION_TEXT"))}:
          <div className="vuso-client-number">{phone}</div>
        </div>
        <form className="verification-form mt-4" onSubmit={onSubmit}>
          <div className="d-flex mb-3">
            <input
              type="tel"
              maxLength={1}
              inputMode="numeric"
              className="form-control"
              name="code_1"
              value={inputs[0] ?? ""}
              onChange={(e) => handleChangeOnlyDigit(e, true)}
              id="input_1"
              required
            />
            <input
              type="tel"
              maxLength={1}
              inputMode="numeric"
              className="form-control"
              name="code_2"
              onChange={(e) => handleChangeOnlyDigit(e, true)}
              value={inputs[1] ?? ""}
              id="input_2"
              required
            />
            <input
              type="tel"
              maxLength={1}
              inputMode="numeric"
              className="form-control"
              name="code_3"
              onChange={(e) => handleChangeOnlyDigit(e, true)}
              value={inputs[2] ?? ""}
              id="input_3"
              required
            />
            <input
              type="text"
              maxLength={1}
              className="form-control"
              name="code_4"
              onChange={(e) => handleChangeOnlyDigit(e, false)}
              value={inputs[3] ?? ""}
              id="input_4"
              required
            />
          </div>
          <Typography color="error" style={{ marginBottom: 20 }}>
            {error !== "" && <ErrorOutline color="primary" />} {error}
          </Typography>
          {showTimer && (
            <Timer
              onTimerEnd={() => {
                setResended(false);
                setShowTimer(false);
              }}
              started={showTimer}
            />
          )}
          <a
            className={classes.link}
            onClick={resendSMS}
            style={{ display: resended ? "none" : "block" }}
          >
            {t("SEND_AGAIN_CODE")}
          </a>
          <button type="submit" className="btn-vuso">
            <span className="button-text-2">
              {!loading ? (
                t("SIGN")
              ) : (
                <CircularProgress style={{ color: "#fff" }} />
              )}
            </span>
          </button>
        </form>
      </div>
    </div>
  );
};
