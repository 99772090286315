import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    maxWidth: 1200,
    margin: "0 auto",
    width: "90%",
    backgroundColor: "#fff",
    padding: "55px 50px 55px 40px",
    height: "100%",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: 24,
    color: "#1D3A60",
    marginBottom: 30,
  },
  rowsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginRight: 10,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 32,
      marginRight: 0,
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: 40,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
  },
  itemImage: {
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  itemText: {
    fontWeight: 400,
    fontSize: 16,
    color: "#1D3A60",
    textAlign: "left",
    fontFamily: "Manrope",
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      textAlign: "center",
    },
  },
}));

const Item = ({ img, text }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <div className={classes.itemImage}>
        <img src={img} alt="item_image" />
      </div>
      <Typography
        className={classes.itemText}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {text}
      </Typography>
    </div>
  );
};

export const Possibilities = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.rowsWrapper}>
        <div className={classes.columnWrapper}>
          <Item
            img={window.location.origin + "/image/vuso/item_1.svg"}
            text={t("ALFA_POSSIBILITIES_1")}
          />
          <Item
            img={window.location.origin + "/image/vuso/item_2.svg"}
            text={t("ALFA_POSSIBILITIES_2")}
          />
          <Item
            img={window.location.origin + "/image/vuso/item_3.svg"}
            text={t("ALFA_POSSIBILITIES_3")}
          />
        </div>
        <div className={classes.columnWrapper}>
          <Item
            img={window.location.origin + "/image/vuso/item_4.svg"}
            text={t("ALFA_POSSIBILITIES_4")}
          />
          <Item
            img={window.location.origin + "/image/vuso/item_5.svg"}
            text={t("ALFA_POSSIBILITIES_5")}
          />
          <Item
            img={window.location.origin + "/image/vuso/item_6.svg"}
            text={t("ALFA_POSSIBILITIES_6")}
          />
        </div>
      </div>
    </div>
  );
};
