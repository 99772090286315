import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "90%",
    maxWidth: 1200,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "54px 50px 50px 50px",
    borderRadius: 32,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "24px",
      width: "100%",
    },
  },
  textBlock: {
    textAlign: "left",
    width: "70%",
    marginBottom: 35,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: 28,
    fontWeight: 800,
    lineHeight: "120%",
    color: "#1D3A60",
    marginBottom: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 27,
    },
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    color: "#69779A",
    marginBottom: 16,
    "& span": {
      fontWeight: 800,
      fontSize: 16,
      lineHeight: "140%",
      color: "#5D9BF8",
    },
  },
  listWrap: {
    listStyle: "disc !important",
    paddingLeft: 20,
  },
  list: {
    listStyle: "inherit !important",
    "&::marker": {
      color: "#69779A",
    },
  },
  itemsBlock: {
    textAlign: "left",
    width: "80%",
    marginBottom: 35,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    "&:last-child div": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "&:last-child div:first-child": {
        marginBottom: 42,
      },
    },
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    marginBottom: 42,
  },
  itemImage: {
    marginRight: 12,
  },
  itemDescription: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    width: "85%",
    color: "#69779A",
    "& span": {
      fontWeight: 800,
      fontSize: 16,
      lineHeight: "140%",
      color: "#5D9BF8",
    },
  },
}));

const Item = ({ text, img }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <img className={classes.itemImage} src={img} alt="item" />
      <Typography className={classes.itemDescription}>{text}</Typography>
    </div>
  );
};

export const HowToUse = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.textBlock}>
        <Typography className={classes.title}>{t("ALFA_SERVICES")}</Typography>
        <ul className={classes.listWrap}>
          <li className={classes.list}>
            <Typography className={classes.description}>
              {parse(t("ALFA_SERVICES_1"))}
            </Typography>
          </li>
          <li className={classes.list}>
            <Typography className={classes.description}>
              {parse(t("ALFA_SERVICES_2"))}
            </Typography>
          </li>
          <li className={classes.list}>
            <Typography className={classes.description}>
              {parse(t("ALFA_SERVICES_3"))}
            </Typography>
          </li>
          <li className={classes.list}>
            <Typography className={classes.description}>
              {parse(t("ALFA_SERVICES_4"))}
            </Typography>
          </li>
          <li className={classes.list}>
            <Typography className={classes.description}>
              {parse(t("ALFA_SERVICES_5"))}
            </Typography>
          </li>
        </ul>
      </div>
      <div className={classes.itemsBlock}>
        <Typography className={classes.title}>
          {t("ALFA_CONNECT_APP")}
        </Typography>
        <div>
          <div className={classes.row}>
            <Item
              text={t("ALFA_CONNECT_APP_1")}
              img={window.location.origin + "/image/vuso/info/item_1.svg"}
            />
            <Item
              text={t("ALFA_CONNECT_APP_2")}
              img={window.location.origin + "/image/vuso/info/item_2.svg"}
            />
          </div>
          <div className={classes.row}>
            <Item
              text={parse(t("ALFA_CONNECT_APP_3"))}
              img={window.location.origin + "/image/vuso/info/item_3.svg"}
            />
            <Item
              text={t("ALFA_CONNECT_APP_4")}
              img={window.location.origin + "/image/vuso/info/item_4.svg"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
