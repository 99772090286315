import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const isEnLocale = localStorage.getItem("misu_locale") === "en";

const setBasketStorage = (state: BasketState) => {
  localStorage.setItem("basket", JSON.stringify(state));
};
export interface TechSpec {
  id: string;
  label: string;
  value: string;
  units: string | null;
}

export interface Description {
  id: string;
  featuresText: boolean;
  featureTextIndex: number;
  descriptions: Array<string>;
}

const M2Tech: Array<TechSpec> = [
  {
    id: "6",
    label: "TFT",
    value: "0,96 TFT-LCD",
    units: null,
  },
  {
    id: "8",
    label: "CHARGING_METHOD",
    value: "MISU_M2_CHARGING_METHOD",
    units: null,
  },

  {
    id: "9",
    label: "MATERIAL",
    value: "MISU_M2_MATERIAL",
    units: null,
  },
  {
    id: "2",
    label: "WEIGHT",
    value: "23,04",
    units: "GRAMS",
  },
  {
    id: "3",
    label: "CHARGING_TIME",
    value: "2",
    units: "HOURS",
  },
  {
    id: "7",
    label: "BATTERY",
    value: "MISU_M2_BATTERY",
    units: null,
  },
  {
    id: "10",
    label: "WORKING_TIME",
    value: "MISU_M2_WORKING_TIME",
    units: null,
  },
  {
    id: "5",
    label: "SIZE",
    value: "MISU_M2_SIZE",
    units: null,
  },
  {
    id: "1",
    label: "SIZE_MM",
    value: "40,4 * 20,1 * 10,5",
    units: null,
  },
  {
    id: "4",
    label: "RESOLUTION",
    value: "240 * 240",
    units: null,
  },
];

const AirTech: Array<TechSpec> = [
  {
    id: "6",
    label: "TFT",
    value: "MISU_AIR_TFT",
    units: null,
  },
  {
    id: "8",
    label: "CHARGING_METHOD",
    value: "MISU_AIR_CHARGING_METHOD",
    units: null,
  },
  {
    id: "9",
    label: "MATERIAL",
    value: "MISU_AIR_MATERIAL",
    units: null,
  },
  {
    id: "2",
    label: "WEIGHT",
    value: "76",
    units: "GRAMS",
  },
  {
    id: "3",
    label: "CHARGING_TIME",
    value: "2-3",
    units: "HOURS",
  },
  {
    id: "11",
    label: "PREDICTION_ACCURACY",
    value: "88%",
    units: null,
  },
  {
    id: "7",
    label: "BATTERY",
    value: "MISU_AIR_BATTERY",
    units: null,
  },

  {
    id: "10",
    label: "WORKING_TIME",
    value: "MISU_AIR_WORKING_TIME",
    units: null,
  },
  {
    id: "5",
    label: "SIZE",
    value: "MISU_AIR_SIZE",
    units: null,
  },
  {
    id: "1",
    label: "SIZE_MM",
    value: "47 * 37 * 14,5",
    units: null,
  },
  {
    id: "4",
    label: "RESOLUTION",
    value: "240 * 295",
    units: null,
  },
  {
    id: "12",
    label: "SENSORS_TITLE",
    value: "MISU_AIR_SENSORS",
    units: null,
  },
];

const ECGTech: Array<TechSpec> = [
  {
    id: "6",
    label: "TFT",
    value: "MISU_M80_TFT",
    units: null,
  },
  {
    id: "8",
    label: "CHARGING_METHOD",
    value: "MISU_M80_CHARGING_METHOD",
    units: null,
  },
  {
    id: "9",
    label: "MATERIAL",
    value: "MISU_M80_MATERIAL",
    units: null,
  },
  {
    id: "2",
    label: "WEIGHT",
    value: "30",
    units: "GRAMS",
  },
  {
    id: "3",
    label: "CHARGING_TIME",
    value: "2",
    units: "HOURS",
  },
  {
    id: "11",
    label: "PREDICTION_ACCURACY",
    value: "78%",
    units: null,
  },
  {
    id: "7",
    label: "BATTERY",
    value: "MISU_M80_BATTERY",
    units: null,
  },
  {
    id: "10",
    label: "WORKING_TIME",
    value: "MISU_M80_WORKING_TIME",
    units: null,
  },
  {
    id: "5",
    label: "SIZE",
    value: "MISU_M80_SIZE",
    units: null,
  },
  {
    id: "1",
    label: "SIZE_MM",
    value: "225 * 20 * 12",
    units: null,
  },
  {
    id: "4",
    label: "RESOLUTION",
    value: "80 * 160",
    units: null,
  },
  {
    id: "12",
    label: "SENSORS_TITLE",
    value: "MISU_M80_SENSORS",
    units: null,
  },
];

const M2Descriptions: Array<string> = [
  "DESCRIPTION_M2_1",
  "DESCRIPTION_M2_2",
  "DESCRIPTION_M2_3",
];

const ECGDescriptions: Array<string> = [
  "DESCRIPTION_ECG_1",
  "DESCRIPTION_ECG_2",
];

const AirDescriptions: Array<string> = [
  "DESCRIPTION_AIR_1",
  "DESCRIPTION_AIR_2",
];
export interface WatchColor {
  id: string;
  label: string;
  value: string;
}

export interface WatchImages {
  color: string;
  //images: Array<string>;
  image: string;
}

export interface AddBasketItem {
  id: string;
  color: string;
  count: number;
}

export interface Product {
  id: string;
  name: string;
  colors: Array<WatchColor>;
  images: Array<WatchImages>;
  price: { [key: string]: number };
  existence: boolean;
}

export interface BasketItem {
  id_name: string;
  name: string;
  count: number;
  color: WatchColor | null;
  image: string | null;
  price: number;
}
export interface NovaPoshtaInfo {
  id: string;
  description: string;
  address: string;
}

interface CityInfo {
  id: string;
  area: string;
  label: string;
}

interface DeliveryInfo {
  city: CityInfo | null;
  novaposhta: NovaPoshtaInfo | null;
}

interface Order {
  name: string;
  secondName: string;
  familyName: string;
  items: Array<BasketItem> | [] | null;
  totalPrice: number;
  itemsCount: number;
  deliveryTax: number | null;
  deliveryInfo: DeliveryInfo | null;
  id: number | null;
  pasport?: string;
  ipn?: string;
  phone: string;
  email: string;
}

export interface BasketState {
  items: Array<BasketItem> | [] | null;
  totalPrice: number;
  itemsCount: number;
  deliveryTax: number;
  deliveryInfo: DeliveryInfo;
  orderID: string | number | null;
  prevOrder: Order | null;
  activationInSite: boolean;
  isPreOrder: boolean;
  version: string;
}

interface ProductSpec {
  id: string;
  specs: Array<TechSpec>;
}

interface ShopState {
  products: Array<Product> | [] | undefined;
  basket: BasketState;
  productsLoaded: boolean;
  techSpecs: Array<ProductSpec> | [];
  braceletsDescriptions: Array<Description>;
}

const initialBasketState: BasketState = {
  totalPrice: 0,
  items: [],
  itemsCount: 0,
  deliveryTax: 0,
  deliveryInfo: { city: null, novaposhta: null },
  orderID: null,
  prevOrder: null,
  activationInSite: false,
  isPreOrder: false,
  version: "2.0.0",
};

const initialState: ShopState = {
  products: [],
  basket: initialBasketState,
  productsLoaded: false,
  techSpecs: [
    { id: "1", specs: M2Tech },
    { id: "2", specs: AirTech },
    { id: "3", specs: ECGTech },
  ],
  braceletsDescriptions: [
    {
      id: "1",
      featuresText: false,
      featureTextIndex: -1,
      descriptions: M2Descriptions,
    },
    {
      id: "2",
      featuresText: true,
      featureTextIndex: 1,
      descriptions: AirDescriptions,
    },
    {
      id: "3",
      featuresText: true,
      featureTextIndex: 1,
      descriptions: ECGDescriptions,
    },
  ],
};

export const loadProducts = createAsyncThunk("shop/loadProducts", async () => {
  if (!process.env.REACT_APP_GET_PRODUCTS) return;

  const data: Array<Product> = (await axios
    .get(process.env.REACT_APP_GET_PRODUCTS)
    .then((response: any) => {
      const data: Array<Product> = response.data;
      return data;
    })
    .catch((e) => console.log("error loading data: ", e))) as Array<Product>;
  return data;
});

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    addToBasket: (state, action: PayloadAction<AddBasketItem>) => {
      if (!action.payload) return;

      const locale = localStorage.getItem("misu_locale") ?? "en";

      const { id, count, color }: AddBasketItem = action.payload;

      const { images, price, name, existence }: Product = state.products?.find(
        (prod: Product) => prod.id === id
      ) as Product;

      const isAllInBasketPreorder: boolean =
        state.basket?.items?.every(
          (item: BasketItem) =>
            state.products?.find(
              (elem: Product) => elem.id === item.id_name.split("-")[0]
            )?.existence === false
        ) ?? false;

      state!.basket!.isPreOrder = !existence && isAllInBasketPreorder;

      const selectedColor =
        (state.products
          ?.find((prod) => prod.id === id)
          ?.colors?.find((c) => c.id === color) as WatchColor) ?? null;

      const itemID = `${id}-${selectedColor.id}`;

      if (!state.basket || !state.basket?.items) return;

      const itemIndex: number = state.basket?.items?.findIndex(
        (item) => item.id_name === itemID
      );
      if (itemIndex !== -1) {
        console.log("found");
        state.basket.items[itemIndex].count += count;
        state.basket.itemsCount += count;
        state.basket.totalPrice += +(+price!.ua * count);
        setBasketStorage(state.basket);
        return;
      }

      const basketItem: BasketItem = {
        id_name: itemID,
        image: images.find((image) => image.color === color)?.image ?? null,
        price: +price.ua,
        count,
        color: selectedColor,
        name,
      };

      (state.basket?.items as BasketItem[]).unshift(basketItem);

      if (state.basket) state.basket.itemsCount += count;

      if (state.basket) state.basket.totalPrice += count * +price.ua;

      setBasketStorage(state.basket);
    },
    plusItemCount: (state, action: PayloadAction<string>) => {
      const itemID = action.payload;
      const item = state.basket?.items?.find((item) => item.id_name === itemID);
      if (item) item.count += 1;
      if (state.basket?.itemsCount) state.basket.itemsCount += 1;
      if (state.basket?.totalPrice && item)
        state.basket.totalPrice += +item.price;
      if (state.basket) setBasketStorage(state.basket);
    },
    minusItemCount: (state, action: PayloadAction<string>) => {
      const itemID = action.payload;
      const item = state.basket?.items?.find((item) => item.id_name === itemID);

      if (!item) return;

      if (item.count > 0) item.count -= 1;

      if (state.basket?.itemsCount) state.basket.itemsCount -= 1;

      if (state.basket?.totalPrice) state.basket.totalPrice -= +item.price;

      if (state.basket?.totalPrice && state.basket.totalPrice < 0)
        state.basket.totalPrice = 0;

      if (item.count === 0) {
        if (!state.basket) return;

        state.basket.items =
          state.basket.items?.filter((item) => item.id_name !== itemID) ?? [];
      }

      if (state.basket?.itemsCount === 0) state.basket.totalPrice = 0;

      if (state.basket) setBasketStorage(state.basket);
    },
    removeFromBasket: (state, action: PayloadAction<string>) => {
      const itemID = action.payload;

      const item = state.basket?.items?.find((item) => item.id_name === itemID);

      if (!item || !state.basket) return;

      state.basket.itemsCount -= item.count;
      state.basket.items =
        state.basket.items?.filter((item) => item.id_name !== itemID) ?? [];
      state.basket.totalPrice -= +(+item.price * +item.count) ?? 0;

      if (
        (state.basket?.totalPrice && state.basket.totalPrice < 0) ||
        state.basket?.itemsCount === 0
      )
        state.basket.totalPrice = 0;

        const isAllInBasketPreorder: boolean =
        state.basket?.items?.every(
          (item: BasketItem) =>
            state.products?.find(
              (elem: Product) => elem.id === item.id_name.split("-")[0]
            )?.existence === false
        ) ?? false;

        state.basket.isPreOrder = isAllInBasketPreorder;

      if (state.basket) setBasketStorage(state.basket);
    },
    changeItemColor: (
      state,
      action: PayloadAction<{ id: string; color: string }>
    ) => {
      const { id, color } = action.payload;

      if (!state.basket || !state.basket?.items) return;

      const basketItem =
        state.basket?.items?.find((item) => item.id_name === id) ?? null;

      if (!basketItem) return;

      const product = state.products?.find(
        (prod) => prod.id === id.split("-")[0]
      );

      basketItem.color = product?.colors?.find((c) => c.id === color) ?? null;

      const newID = `${product?.id}-${color}`;

      const existItem = state.basket.items.find((bi) => bi.id_name === newID);
      if (existItem !== undefined) {
        existItem.count += +basketItem.count;
        state.basket.items = state.basket.items.filter(
          (item) => item.id_name !== basketItem.id_name
        );
        setBasketStorage(state.basket);
        return;
      }

      basketItem.id_name = newID;

      basketItem.image = (
        product?.images?.find((im) => im.color === color) as WatchImages
      )?.image;

      if (state.basket) setBasketStorage(state.basket);
    },
    loadBasket: (state) => {
      const basket = localStorage.getItem("basket");
      if (basket) {
        const parsed = JSON.parse(basket);
        if (state.basket?.version !== parsed?.version) {
          state.basket = initialBasketState;
          state.basket.deliveryTax = 0;
          setBasketStorage(state.basket);
          return;
        }

        state.basket = parsed;
        if (state.productsLoaded) {
          state!.basket!.isPreOrder = state.basket?.items?.every(
            (item: BasketItem) =>
              state.products?.find(
                (elem: Product) => elem.id === item.id_name.split("-")[0]
              )?.existence === false
          ) ?? parsed.isPreOrder;
        }

        state.basket.deliveryTax = 0;
      } else {
        setBasketStorage(state.basket);
      }
    },
    setDeliveryCity: (state, action: PayloadAction<CityInfo>) => {
      if (state.basket?.deliveryInfo) {
        state.basket.deliveryInfo.city = action.payload;
      }
    },
    resetDeliveryInfoNovaPoshta: (state) => {
      if (state.basket?.deliveryInfo) {
        state.basket.deliveryInfo.novaposhta = null;
      }
    },
    setNovaPoshtaInfo: (state, action: PayloadAction<NovaPoshtaInfo>) => {
      if (state.basket?.deliveryInfo) {
        state.basket.deliveryInfo.novaposhta = action.payload;
      }
    },
    resetBasketState: (state) => {
      if (!state.basket) return;

      state.basket = initialBasketState;
      setBasketStorage(state.basket);
    },
    resetAllExceptOrder: (state) => {
      if (!state.basket) return;

      const order: Order | null = state.basket.prevOrder;
      const activation: boolean = state.basket.activationInSite;
      const preOrder: boolean = state.basket.isPreOrder;

      const basket: BasketState = { ...initialBasketState };

      basket.activationInSite = activation;
      basket.isPreOrder = preOrder;
      if (order) basket.prevOrder = order;

      state.basket = basket;
    },
    changePricesByLocale: (state, action: PayloadAction<string>) => {
      if (state.products === [] || state.products?.length === 0) return;

      const newLocale = action.payload;

      if (!state.basket) return;

      state.basket.items = state.basket.items?.map((item: BasketItem) => {
        const product: Product = state.products?.find(
          (prod: Product) => prod.id === item.id_name.split("-")[0]
        ) as Product;
        return { ...item, price: +product.price[newLocale] } as BasketItem;
      }) as Array<BasketItem>;

      state.basket.totalPrice = state.basket.items?.reduce(
        (acc: number, curr: BasketItem) => acc + +curr.price,
        0
      );

      setBasketStorage(state.basket);
    },
    setOrderID: (state, action: PayloadAction<string | number>) => {
      state.basket!.orderID = action.payload;
    },
    setActivationInSite: (state, action: PayloadAction<boolean>) => {
      state.basket!.activationInSite = action.payload;
    },
    setIsPreOrder: (state, action: PayloadAction<boolean>) => {
      state!.basket!.isPreOrder = action.payload;
    },
    setPrevOrder: (state, action: PayloadAction<Order>) => {
      state.basket!.prevOrder = action.payload;

      if (state.basket) setBasketStorage(state.basket);
    },
    setDeliveryTax: (state, action: PayloadAction<number>) => {
      state.basket.deliveryTax = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProducts.fulfilled, (state, action) => {
      state.products = action.payload?.map((prod) => ({
        ...prod,
        id: prod.id.toString(),
        colors: prod.colors.map((c) => ({ ...c, id: c.id.toString() })),
        images: prod.images.map((im) => ({
          ...im,
          color: im.color.toString(),
        })),
        price: Object.keys(prod.price).reduce(
          (acc: any, curr) => ({
            ...acc,
            [curr]: (+prod.price[curr]).toFixed(0),
          }),
          {}
        ),
      }));

      if (!state.basket) return;

      state.basket.isPreOrder =
        state.basket?.items?.every(
          (item: BasketItem) =>
            state.products?.find(
              (elem: Product) => elem.id === item.id_name.split("-")[0]
            )?.existence === false
        ) ?? false;

      state.productsLoaded = true;
    });
  },
});

export const {
  addToBasket,
  plusItemCount,
  minusItemCount,
  removeFromBasket,
  changeItemColor,
  loadBasket,
  setDeliveryCity,
  setNovaPoshtaInfo,
  resetBasketState,
  resetDeliveryInfoNovaPoshta,
  changePricesByLocale,
  setOrderID,
  setPrevOrder,
  resetAllExceptOrder,
  setActivationInSite,
  setDeliveryTax,
  setIsPreOrder,
} = shopSlice.actions;
export default shopSlice.reducer;
