import React from "react";

export const AppDownload = () => {
  React.useEffect(() => {
    document.title = `MISU Invite`;
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#0b0d17",
        width: "100vw",
        height: "100vh",
        color: "#fff",
      }}
    >
      <section className="page__section downloadApp">
        <div className="content-footer__logo">
          <a href="https://misu.in.ua">
            <img
              className="content-footer__misu-img"
              src={window.location.origin + "/image/icon-union.png"}
              alt="logo"
            />
          </a>
          <p className="content-footer__misu-logo downloadApp__misu-logo">
            MISU
          </p>
        </div>
        <div>
          <ul>
            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.WH.MISU&hl=ru&gl=US"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={window.location.origin + "/image/GooglePlayBadge.png"}
                  alt="GooglePlay"
                />{" "}
              </a>
            </li>
            <li>
              <a
                href="https://apps.apple.com/ua/app/misu/id1516509504"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={window.location.origin + "/image/AppStoreBadge.png"}
                  alt="AppStore"
                />
              </a>
            </li>
          </ul>
          <p className="content-footer__copyright downloadApp__copyright">
            Copyright © 2021 All rights reserved{" "}
          </p>
        </div>
      </section>
    </div>
  );
};
