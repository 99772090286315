import React from "react";
import {
  FormControl,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 20,
    marginBottom: 20,
  },
  title: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 8,
  },
  subtitle: {
    color: "#69779A",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "140%",
    marginBottom: 24,
    textAlign: "left",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100%",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  column: {
    marginRight: 20,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  inputBlock: {
    marginBottom: 24,
    textAlign: "left",
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "&:last-child": {
        marginBottom: 24,
      },
    },
  },
  iosSwitch: {
    width: 51,
    height: 31,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(20px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#5985F5",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 27,
      height: 27,
      boxShadow:
        "0px 3px 8px rgba(89, 133, 245, 0.32), 0px 3px 1px rgba(89, 133, 245, 0.06)",
    },
    "& .MuiSwitch-track": {
      borderRadius: 26,
      backgroundColor: "#E9E9EA",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
  switchText: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: "140%",
    marginRight: 12,
  },
  switchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 48,
  },
}));

export const AssistanceForm = React.memo(
  ({ formik, validateIPN, availableInputting, isDisabled = false }: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [ipnError, setIpnError] = React.useState<string>("");

    const maxDate = new Date()
      .toLocaleDateString("uk-UA")
      .split(".")
      .reverse()
      .join("-");

    const minDate = new Date(formik.values.birth)
      .toLocaleDateString("uk-UA")
      .split(".")
      .reverse()
      .join("-");

    const handleOnlyNumberField = (values: any) => {
      const event = {
        target: {
          value: values.value,
          name: "ipn",
        },
      };
      formik.handleChange(event);

      setIpnError(t(""));
      if (values.value.length === 10) {
        if (validateIPN && !validateIPN(values.value)) {
          setIpnError(t("ERROR_VALIDATE_IPN"));
        }
      }
    };

    const handleOnlyNumberPassportField = (values: any) => {
      const event = {
        target: {
          value: values.value,
          name: "passportID",
        },
      };
      formik!.handleChange(event);
    };

    const handlePassportOldChangeField = (event: any) => {
      if ((event.target.value as string).length > 8) return;

      const ev = {
        target: {
          value: event.target.value.toUpperCase(),
          name: "passportOld",
        },
      };
      formik!.handleChange(ev);
    };

    return (
      <div className={classes.wrapper}>
        <Typography className={classes.title}>
          {t("MEDICAL_ASSISTANCE_ONLINE")} - {t("ACTIVATION")}
        </Typography>
        <Typography className={classes.subtitle}>
          {parse(t("ACTIVATION_INFO"))}
        </Typography>
        <div className={classes.switchWrapper}>
          <Typography className={classes.switchText}>
            {t("FOR_ANOTHER_PERSON")}
          </Typography>
          <Switch
            className={classes.iosSwitch}
            checked={formik.values.forGift}
            name="forGift"
            onChange={formik.handleChange}
            disabled={isDisabled}
          />
        </div>
        <div className={classes.content}>
          <div className={classes.column}>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("CHOOSE_PASSPORT_TYPE")}*</Typography>
              <FormControl fullWidth>
                <Select
                  name="passportType"
                  variant="outlined"
                  value={formik!.values.passportType}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.passportType &&
                    Boolean(formik!.errors.passportType)
                  }
                  required
                  disabled={isDisabled || !availableInputting()}
                >
                  <MenuItem value="id_card">{t("ID_CARD")}</MenuItem>
                  <MenuItem value="old_passport">{t("OLD_PASSPORT")}</MenuItem>
                </Select>
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.passportType && formik!.errors.passportType}
                </p>
              </FormControl>
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("PASSPORT_START_DATE")}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name="passportStartDate"
                  type="date"
                  variant="outlined"
                  value={formik!.values.passportStartDate}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.passportStartDate &&
                    Boolean(formik!.errors.passportStartDate)
                  }
                  inputProps={{ max: maxDate, min: minDate }}
                  required
                  disabled={isDisabled || formik.values.forGift || !availableInputting()}
                />
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.passportStartDate &&
                    formik!.errors.passportStartDate}
                </p>
              </FormControl>
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("IPN")}*</Typography>
              <FormControl fullWidth>
                <NumberFormat
                  id="ipn"
                  format="##########"
                  mask="X"
                  customInput={OutlinedInput}
                  placeholder="0000000000"
                  name="ipn"
                  value={formik!.values.ipn}
                  onValueChange={handleOnlyNumberField}
                  error={formik!.touched.ipn && Boolean(formik!.errors.ipn)}
                  required
                  disabled={
                    isDisabled ||
                    formik.values.activation !== "site" ||
                    formik.values.forGift || !availableInputting()
                    
                  }
                />
                <p style={{ color: "#DD284A" }}>
                  {ipnError === ""
                    ? formik!.touched.ipn && formik!.errors.ipn
                    : ipnError}
                </p>
              </FormControl>
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.inputBlock}>
              {formik!.values.passportType === "old_passport" ? (
                <>
                  <Typography align="left">
                    {t("SERIES_AND_NUMBER")}*
                  </Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="passportOld"
                      variant="outlined"
                      placeholder="MC000000"
                      value={formik!.values.passportOld}
                      onChange={handlePassportOldChangeField}
                      error={
                        formik!.touched.passportOld &&
                        Boolean(formik!.errors.passportOld)
                      }
                      required
                      disabled={
                        isDisabled ||
                        formik.values.activation !== "site" ||
                        formik.values.forGift || !availableInputting()
                      }
                    />
                    <p style={{ color: "#DD284A" }}>
                      {formik!.touched.passportOld &&
                        formik!.errors.passportOld}
                    </p>
                  </FormControl>
                </>
              ) : (
                <>
                  <Typography align="left">{t("PASSPORT_NUMBER")}*</Typography>
                  <FormControl fullWidth>
                    <NumberFormat
                      format="#########"
                      mask="X"
                      customInput={OutlinedInput}
                      placeholder="000000000"
                      name="passportID"
                      value={formik!.values.passportID}
                      onValueChange={handleOnlyNumberPassportField}
                      error={
                        formik!.touched.passportID &&
                        Boolean(formik!.errors.passportID)
                      }
                      required
                      disabled={
                        isDisabled ||
                        formik.values.activation !== "site" ||
                        formik.values.forGift || !availableInputting()
                      }
                    />
                    <p style={{ color: "#DD284A" }}>
                      {formik!.touched.passportID && formik!.errors.passportID}
                    </p>
                  </FormControl>
                </>
              )}
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("PASSPORT_EDIT")}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name="passportEdit"
                  variant="outlined"
                  placeholder={t("PASSPORT_EDIT")}
                  value={formik!.values.passportEdit}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.passportEdit &&
                    Boolean(formik!.errors.passportEdit)
                  }
                  required
                  disabled={isDisabled || formik.values.forGift || !availableInputting()}
                />
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.passportEdit && formik!.errors.passportEdit}
                </p>
              </FormControl>
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("ACTIVATION")}*</Typography>
              <FormControl fullWidth>
                <Select
                  name="activation"
                  variant="outlined"
                  defaultValue={formik!.values.activation}
                  value={formik!.values.activation}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.activation &&
                    Boolean(formik!.errors.activation)
                  }
                  disabled={isDisabled || !availableInputting()}
                  required
                >
                  <MenuItem value="site">{t("ON_SITE")}</MenuItem>
                  <MenuItem value="app">{t("IN_APPLICATION")}</MenuItem>
                </Select>
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.activation && formik!.errors.activation}
                </p>
              </FormControl>
            </div>
          </div>
        </div>
        <Typography align="left">* {parse(t("MANDATORY_FIELD"))}</Typography>
        <Typography align="left">** {t("ASSISTANCE_IN_UKRAINE")}</Typography>
      </div>
    );
  }
);
