import { makeStyles, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../store/store";
import { LocaleDropDown } from "./LocaleDropDown";

const useStyles = makeStyles((theme) => ({
  socialRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 50,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  socialButton: {
    display: "flex",
    // flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    width: 30,
    height: 30,
    "& img": {
      width: "100%",
      height: "100%",
    },
    // backgroundColor: "#171926",
    // borderRadius: 100,
    "&:hover": {
      cursor: "pointer",
    },
    "&:not(:last-child)": {
      marginRight: 9,
    },
  },
}));

export const HeaderV2 = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const basketItems =
    useSelector((store: RootState) => store.shop.basket?.itemsCount) ?? 0;
  const [open, setOpen] = React.useState(false);

  const toggleMenu = () => {
    const iconMenu = document.querySelector(".menu-trigger");
    const navbar = document.querySelector("#navbarResponsive");
    document.body.classList.toggle("_lock");
    iconMenu!.classList.toggle("active");
    navbar!.classList.toggle("show");

    if (open) enableScroll();
    if (!open) disableScroll();

    setOpen((prev) => !prev);
  };

  const enableScroll = () => {
    document.body.style.overflow = "auto";
  };

  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const goToMain = () => {
    if (window.location.pathname !== "/") {
      history.push("/");
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "auto";
    }
    if (open) toggleMenu();
  };

  const goToInstruct = () => {
    document.body.style.overflow = "auto";
    history.push("/instructions");
  };

  return (
    <>
      <div style={{ height: 75 }}></div>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container">
          <div className="navbar-wrap">
            <a className="navbar-brand" href="/">
              <img
                src={window.location.origin + "/image/header/logo.svg"}
                alt="MISU"
              />
              <Typography display="inline" className="navbar-brand-title">
                MISU
              </Typography>
            </a>
          </div>

          <div className="navbar-toggler-wrapper">
            <a className="nav-basket nav-basket-mobilescreen" href="/basket">
              <img
                src={window.location.origin + "/image/header/basket.svg"}
                alt="basket"
              ></img>
              <div className="amount">
                {" "}
                {basketItems > 0 ? `(${basketItems})` : ""}{" "}
              </div>
            </a>

            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <a className="menu-trigger">
                {open ? (
                  <Close
                    style={{
                      color: "#1D3A60",
                      width: "100%",
                      height: "100%",
                      paddingTop: 5,
                    }}
                  />
                ) : (
                  <img
                    src={window.location.origin + "/image/header/menu.svg"}
                  />
                )}
              </a>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <div className="nav-store-wrapper">
              <a
                className="nav-app-icon"
                href="https://apps.apple.com/ua/app/misu/id1516509504"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={
                    window.location.origin + "/image/header/app-store-badge.svg"
                  }
                  alt="app-store"
                ></img>
              </a>
              <a
                className="nav-app-icon"
                href="https://play.google.com/store/apps/details?id=com.WH.MISU"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    window.location.origin +
                    "/image/header/google-play-badge.svg"
                  }
                  alt="google-play"
                ></img>
              </a>
            </div>

            <div className={classes.socialRow + " nav-basket-mobilescreen"}>
              <div className={classes.socialButton}>
                <a
                  href="https://www.linkedin.com/company/misu-medical-information-system-of-ukraine/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/linkedin_header.svg"}
                    alt="linkedin"
                  />
                </a>
              </div>
              <div className={classes.socialButton}>
                <a
                  href="https://www.instagram.com/misu.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/instagram_header.svg"}
                    alt="instagram"
                  />
                </a>
              </div>
              <div className={classes.socialButton}>
                <a
                  href="https://www.facebook.com/misu.ua/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/facebook_header.svg"}
                    alt="facebook"
                  />
                </a>
              </div>
            </div>

            <ul className="navbar-nav">
              <li className="nav-item" onClick={goToMain}>
                <a className="nav-link" href="#monitoring">
                  {t("HEALTH_MONITORING")}
                </a>
              </li>
              <li className="nav-item" onClick={goToMain}>
                <a className="nav-link" href="#connection">
                  {t("INTERACTION_WITH_MISU")}
                </a>
              </li>

              {/* <li className="nav-item" onClick={goToInstruct}>
                <a className="nav-link" href="#connection">
                  {t("INSTRUCTION")}
                </a>
              </li> */}
              <li className="nav-item" onClick={goToMain}>
                <a className="nav-link" href="#contacts">
                  {t("CONTACTS")}
                </a>
              </li>
            </ul>

            <ul className="nav-option-wrapper">
              <li className="nav-basket nav-basket-fullscreen">
                {" "}
                <a href="/basket">
                  <img
                    src={window.location.origin + "/image/header/basket.svg"}
                    alt="basket"
                  ></img>
                  <span className="amount">
                    {" "}
                    {basketItems > 0 ? `(${basketItems})` : ""}{" "}
                  </span>{" "}
                </a>
              </li>
              <li className="nav-language">
                <LocaleDropDown />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
