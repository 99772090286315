import React from "react";

export const Poll = () => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLScrJ48JJzDV3hr3jZduJrzpbCjINHKm4kldMvLKfADXBgBMAg/viewform?embedded=true"
      style={{ display: "flex", justifyContent: "center", height: "100vh", width: "100%" }}
    >
      Loading…
    </iframe>
  );
};
