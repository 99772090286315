import { makeStyles } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { BasketItemV2 } from "./BasketItemV2";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: 385,
    // height: 380,
    overflowY: "auto",
    width: "100%",
    paddingRight: 20,
    marginBottom: 20,
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 32,
      opacity: 0.2,
      backgroundColor: "#DEE1EB",
      border: "3px solid #DEE1EB",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#FF5E5E",
      borderRadius: 32,
      height: 150,
    },
    [theme.breakpoints.down("sm")]: {
      overflowY: "hidden",
      maxHeight: "100%",
      paddingRight: "0px !important",
      height: "100%",
      marginBottom: 20,
    },
  },
}));

export const ItemsWrapper = () => {
  const classes = useStyles();
  const basketItems =
    useSelector((store: RootState) => store.shop.basket?.items) ?? [];

  return (
    <div
      className={classes.wrapper}
      style={{ paddingRight: basketItems.length > 2 ? 20 : 0 }}
    >
      {basketItems.map((item: any) => (
        <BasketItemV2 key={item?.id_name} item={item} />
      ))}
    </div>
  );
};
