import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBasket,
  loadProducts,
  setActivationInSite,
  setDeliveryTax,
  setIsPreOrder,
  setOrderID,
  setPrevOrder,
} from "../../../store/features/shop/Shop";
import { isValidPhoneNumber } from "libphonenumber-js";

import Footer from "../Footer";
import { AssistanceForm } from "./AssistanceForm";
import { BasketHeader } from "./BasketHeader";
import { DeliveryForm } from "./DeliveryForm";
import { InfoForm } from "./InfoForm";
import { ItemsWrapper } from "./ItemsWrapper";
import { TotalBlock } from "./TotalBlock";

import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { ConfirmationModal } from "./ConfirmationModal";
import { useHistory } from "react-router-dom";
import { PaymentForm } from "./PaymentForm";
import { SuccessPreorder } from "./SuccessPreorder";
import { PreorderOnOrderModal } from "./PreorderOnOrderModal";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "90%",
    maxWidth: 1200,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "20px auto",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  leftColumn: {
    width: "100%",
    marginRight: 20,
  },
  rightColumn: {
    width: "55%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  wrapEmptyCart: {
    width: "70%",
    margin: "20px auto",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  emptyCartWrapper: {
    width: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "155px 0px",
    marginTop: 15,
    borderRadius: 32,
    [theme.breakpoints.down("md")]: {
      margin: "auto",
      padding: "25px 0px",
    },
  },
  emptyCartTitle: {
    color: "#494949",
    fontSize: 24,
    marginBottom: 8,
  },
  emptyCartSubtitle: {
    color: "#171926",
    opacity: 0.5,
    fontSize: 16,
    marginBottom: 16,
  },
  emptyCartButton: {
    textTransform: "none",
    fontSize: 16,
    padding: "15px 87px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px 37px",
    },
  },
  emptyCartContent: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

export const BasketV2 = () => {
  const classes = useStyles();
  const OurCityRef = "db5c896a-391c-11dd-90d9-001a92567626";
  const dispatch = useDispatch();
  const basket = useSelector((store) => store.shop?.basket);
  const isPreOrder = useSelector((store) => store.shop?.basket.isPreOrder);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openNotAvailableModal, setOpenNotAvailableModal] =
    React.useState(false);
  const [haveToBePreOrder, setHaveToBePreOrder] = React.useState(false);
  const [availableBracelets, setAvailableBracelets] = React.useState([
    { id: "1", name: "MISU Watch AIR", count: 1 },
    { id: "2", name: "MISU Watch ECG", count: 2 },
  ]);
  const [isLoadingDeliveryPrice, setIsLoadingDeliveryPrice] =
    React.useState(false);
  const [showPreOrder, setShowPreOrder] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const isEnLocale = i18n.language === "en";
  const [phoneCountry, setPhoneCountry] = React.useState("ua")

  const initState = {
    pib: "",
    phone: "",
    email: "",
    // birth: "",
    country: t("UKRAINE"),
    city: "",
    delivery: t("NOVA_POSHTA"),
    address: "",
    activation: "site",
    paymentMethod: isPreOrder ? "preOrder" : "cod",
    // for: "self",
    // forGift: false,
    // ipn: "",
    // passportType: "id_card",
    // passportStartDate: "",
    // passportEdit: "",
    // passportID: "",
    // passportOld: "",
  };

  const schema = yup
    .object({
      pib: yup
        .string()
        .trim()
        .matches(
          /[А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z\-]{2,} [А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z\-]{2,} [А-ЩЬЮЯҐЄІЇа-щьюяґєіїa-zA-Z]{4,}/,
          t("VALIDATION_FULLNAME")
        )
        .required(t("REQUIRED")),
      // birth: yup.date(),
      phone: yup
        .string()
        // .min(10, t("MUST_BE_TEN"))
        // .max(10, t("MUST_BE_TEN"))
        .required(t("REQUIRED")),
      email: yup.string().email(t("ENTER_VALID_EMAIL")).required(t("REQUIRED")),
      // ipn: yup.string().min(10, t("MUST_BE_TEN")).max(10, t("MUST_BE_TEN")),
      // passportID: yup
      //   .string()
      //   .matches(/[0-9]{9}/, t("VALIDATION_ID_CARD"))
      //   .min(9, t("MUST_BE_NINE"))
      //   .max(9, t("MUST_BE_NINE")),
      // passportOld: yup
      //   .string()
      //   .matches(/[А-ЩЬЮЯҐЄІЇ]{2}[0-9]{6}/, t("VALIDATION_PASSPORT_OLD"))
      //   .min(8, t("MUST_BE_NINE"))
      //   .max(8, t("MUST_BE_EIGHT")),
      // passportStartDate: yup.date(),
      // passportEdit: yup.string().trim().min(4, t("MUST_BE_FOUR")),
    })
    .required();

  const formik = useFormik({
    initialValues: initState,
    validationSchema: schema,
    onSubmit: (values) => {
      if (!isValidPhoneNumber(values.phone, phoneCountry)) {
        invalidPhoneNumber();
        return;
      }
      // if (values.ipn === "" || validateIPN(values.ipn)) 
      onSubmit(values);
    },
  });

  const invalidPhoneNumber = () => {
    formik.setFieldError("phone", t("ERROR_VALIDATE_PHONE"));
  };

  const calculateDelivery = async () => {
    if (!basket.deliveryInfo?.city) return 0;
    // if (basket.deliveryTax !== 0) return 0;
    setIsLoadingDeliveryPrice(true);
    return await axios
      .post("https://api.novaposhta.ua/v2.0/json/", {
        apiKey: process.env.REACT_APP_NOVA_POSHTA_API,
        modelName: "InternetDocument",
        calledMethod: "getDocumentPrice",
        methodProperties: {
          CitySender: OurCityRef,
          CityRecipient: basket.deliveryInfo.city.id,
          Weight: (basket.itemsCount * 0.1).toString(),
          ServiceType: "WarehouseWarehouse",
          Cost: basket.totalPrice.toString(),
          RedeliveryCalculate: {
            CargoType: "Money",
            Amount: basket.totalPrice.toString(),
          },
          CargoType: "Cargo",
          SeatsAmount: "1",
          PackCount: "1",
        },
      })
      .then(async ({ data }) => {
        return data.data[0].Cost;
      })
      .catch((e) => console.log("error calculate delivery price: ", e))
      .finally(() => setIsLoadingDeliveryPrice(false));
  };

  const onSubmit = async (values) => {
    let tax = 0;
    if (values.paymentMethod !== "preOrder") {
      tax = (await calculateDelivery()) ?? 0;
      dispatch(setDeliveryTax(tax));
    }

    const {
      city,
      phone,
      email,
      // ipn,
      // passportEdit,
      // passportStartDate,
      // forGift,
      ...rest
    } = values;
    const items = basket.items.map((item) => {
      const { image, ...rest } = item;
      const {
        color: { value, ...colorRest },
        ...itemRest
      } = rest;
      itemRest.color = colorRest;
      return itemRest;
    });
    const { deliveryInfo, totalPrice, itemsCount } = basket;
    const order = {
      name: rest.pib.split(" ")[1],
      familyName: rest.pib.split(" ")[0],
      secondName: rest.pib.split(" ")[2],
      phone: `+38${phone}`,
      email,
      // birth_date: forGift ? null : rest.birth,
      deliveryInfo,
      deliveryTax: tax,
      totalPrice: +totalPrice,
      itemsCount,
      items,
      // ipn: forGift ? null : ipn,
      // edit: forGift ? null : passportEdit,
      // passportDate: forGift ? null : passportStartDate,
      // pasport: forGift
      //   ? null
      //   : rest.passportType === "old_passport"
      //   ? rest.passportOld
      //   : rest.passportID,
      // ipn: null,
      // edit: null,
      // passportDate: null,
      // pasport: null,
    };

    // if (order.pasport === "") {
    //   order.pasport = null;
    //   order.ipn = null;
    // }

    // if (order.passportDate === "") {
    //   order.passportDate = null;
    // }

    console.log("order: ", order);

    dispatch(
      setActivationInSite(
        // rest.for === "self" &&
        rest.activation === "site" &&
          rest.paymentMethod === "card" 
          // && mustShow()
      )
    );

    const preOrder = {
      familyName: rest.pib.split(" ")[0],
      name: rest.pib.split(" ")[1],
      secondName: rest.pib.split(" ")[2],
      phone: `+38${phone}`,
      email,
      deliveryInfo: {},
      deliveryTax: null,
      totalPrice: +totalPrice,
      itemsCount,
      items,
      pasport: null,
      ipn: null,
      passportDate: null,
      edit: null,
    };
    console.log("preorder: ", preOrder);

    setOrder(isPreOrder ? preOrder : order);
    setOpenConfirmModal(true);
  };

  const validateIPN = (value) => {
    const n = value.split("").map((elem) => Number(elem));
    const X =
      n[0] * -1 +
      n[1] * 5 +
      n[2] * 7 +
      n[3] * 9 +
      n[4] * 4 +
      n[5] * 6 +
      n[6] * 10 +
      n[7] * 5 +
      n[8] * 7;
    const result = n[9] === (X % 11) % 10;
    if (!result) {
      formik.setFieldError("ipn", t("ERROR_VALIDATE_IPN"));
    }
    console.log("last", (X % 11) % 10);

    return result;
  };

  const sendInfo = async (values) => {
    let url = process.env.REACT_APP_ORDER_LINK; //  remove in production

    if (isPreOrder) url += "?status=preorder"; //  change & -> ? in production

    await axios
      .post(url, values, {
        headers: { Authorization: `Token ${process.env.REACT_APP_TOKEN}` },
      })
      .then(({ data }) => {
        console.log("data: ", data);
        setOpenConfirmModal(false);
        if (isPreOrder) {
          setShowPreOrder(true);
          dispatch(setPrevOrder(data));
          formik.resetForm();
          return;
        }

        dispatch(setOrderID(data.slug));
        dispatch(setPrevOrder(data));
        if (formik.values.paymentMethod === "cod") {
          history.push("/payment?result=success");
          formik.resetForm();
        }

        document.getElementById("payment_form").submit();
        formik.resetForm();
      })
      .catch((e) => {
        if(e.response){
          if (e.response.status === 404) setHaveToBePreOrder(true);
          else if (e.response.status === 400) {
            setHaveToBePreOrder(false);
            console.log(e.response.data.product);
            setAvailableBracelets(e.response.data.product);
          }
          setOpenNotAvailableModal(true);
        }
        console.log("error: ", e);
      });
  };

  React.useEffect(() => {
    dispatch(loadProducts()).finally(() => {
      dispatch(loadBasket());
    });
  }, [dispatch]);

  React.useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const elem = document.getElementsByName(Object.keys(formik.errors)[0])[0];
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
      elem.focus({ preventScroll: true });
    }
  }, [formik]);

  React.useEffect(() => {
    document.title = `MISU Basket`;
  }, []);

  React.useEffect(() => {
    if(isPreOrder && formik.values.paymentMethod !== "preOrder")
    {
      formik.setFieldValue("paymentMethod", "preOrder");
    }
    if(!isPreOrder && formik.values.paymentMethod === "preOrder")
    {
      // formik.setFieldValue("paymentMethod", "card");
      formik.setFieldValue("paymentMethod", "cod");
    }
  }, [isPreOrder]);

  const mustShow = React.useCallback(() => {
    const date = formik.values.birth;

    if (date === "" || formik.values.forGift) return false;

    const now = new Date();
    let startDate = new Date(date);
    startDate.setFullYear(startDate.getFullYear() + 18);
    let endDate = new Date(date);
    endDate.setFullYear(endDate.getFullYear() + 65);
    const result = now >= startDate && now < endDate;
    // formik.values.for !== "gift" &&
    // formik.values.paymentMethod === "card" &&
    // formik.values.activation === "site";
    return result;
  });

  const paymentBody = {
    paymentTypes: {
      card: "Y",
      gpay: "Y",
      privat: "Y",
      applepay: "Y",
      masterpass: "Y",
      portmone: "Y",
      visacheckout: "Y",
    },
    priorityPaymentTypes: {
      card: "1",
      portmone: "7",
      masterpass: "3",
      visacheckout: "4",
      privat: "5",
      gpay: "2",
      applepay: "6",
    },
    payee: {
      payeeId: process.env.REACT_APP_PORTMONE_ID ?? "",
      //payeeId: "1185",
    },
    order: {
      description: "MISU ORDER",
      billAmount: +basket.totalPrice + +basket.deliveryTax,
      successUrl:
        process.env.REACT_APP_PAYMENT_SUCCESS +
          `?order=${basket?.orderID ?? ""}` ?? "",
      failureUrl:
        process.env.REACT_APP_PAYMENT_FAIL +
          `?order=${basket?.orderID ?? ""}` ?? "",
      billCurrency: "UAH",
    },
    payer: {
      lang: isEnLocale ? "en" : "uk",
      emailAddress: basket?.prevOrder?.email,
      showEmail: "Y",
    },
  };

  const moveToShop = () => {
    history.push("/watches");
  };

  const afterOrderSubmit = () => {
    if (haveToBePreOrder) {
      console.log("submit preorder");
      dispatch(setIsPreOrder(true));
      sendInfo(order);
    } else {
      console.log("submit sliced order");
      let items = order.items.slice();
      items = items.filter(
        (elem) =>
          availableBracelets.findIndex((x) => x.id_name === elem.id_name) !== -1
      );
      items = items.map((x) => {
        const availableBracelet = availableBracelets.find(
          (y) => y.id_name === x.id_name
        );
        return {
          ...x,
          count:
            availableBracelet.count > x.count
              ? x.count
              : availableBracelet.count,
        };
      });
      let newTotalPrice = items.reduce(
        (prev, currElem) => prev + currElem.price * currElem.count,
        0
      );
      let newItemsCount = items.reduce(
        (prev, currElem) => prev + currElem.count,
        0
      );
      const newOrder = {
        ...order,
        items,
        totalPrice: newTotalPrice,
        itemsCount: newItemsCount,
      };
      setOrder(newOrder);
      console.log(newOrder);
      sendInfo(newOrder);
    }
    setOpenNotAvailableModal(false);
  };

  return (
    <div style={{ width: "100%" }}>
      <BasketHeader shouldClearBasket={showPreOrder} title={t("YOUR_ORDER")} />
      {basket.itemsCount > 0 || showPreOrder ? (
        showPreOrder ? (
          <SuccessPreorder />
        ) : (
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className={classes.wrapper}>
                <div className={classes.leftColumn}>
                  <ItemsWrapper />
                  <InfoForm formik={formik} onChangePhone={(countryISO) => setPhoneCountry(countryISO)} />
                  {(formik.values.paymentMethod !== "preOrder" && !isPreOrder) && (
                    <DeliveryForm formik={formik} />
                  )}
                  {/* {mustShow() && ( */}
                  {/* {(formik.values.paymentMethod !== "preOrder" && !isPreOrder) && ( */}
                    {/* <AssistanceForm
                      availableInputting={mustShow}
                      validateIPN={validateIPN}
                      formik={formik}
                    /> */}
                  {/* )} */}
                  {/* )} */}
                  <PaymentForm formik={formik} />
                </div>
                <div className={classes.rightColumn}>
                  <TotalBlock
                    formik={formik}
                    // showingPassportInfo={mustShow}
                    loadingDelivery={isLoadingDeliveryPrice}
                  />
                </div>
              </div>
            </form>
            {/* <form
              action={process.env.REACT_APP_PORTMONE ?? ""}
              method="post"
              target="_blank"
              id="payment_form"
              style={{ display: "none" }}
            >
              <input
                type="hidden"
                name="bodyRequest"
                value={JSON.stringify(paymentBody)}
              />
              <input type="hidden" name="typeRequest" value="json" />
              <input
                type="submit"
                value="Оплатити через Portmone.com"
                style={{ width: 350, height: 30 }}
                // disabled={!showPayment}
              />
            </form> */}
          </>
        )
      ) : (
        <div className={classes.wrapEmptyCart}>
          <div className={classes.emptyCartWrapper}>
            <div className={classes.emptyCartContent}>
              <Typography className={classes.emptyCartTitle}>
                {t("NOTHING_SELECTED")}
              </Typography>
              <Typography className={classes.emptyCartSubtitle}>
                {t("NOTHING_SELECTED_SUBTITLE")}
              </Typography>
              <Button
                className={classes.emptyCartButton}
                variant="contained"
                color="primary"
                onClick={moveToShop}
              >
                {t("GO_TO_STORE")}
              </Button>
            </div>
          </div>
        </div>
      )}

      <div>
        <Footer />
      </div>
      <ConfirmationModal
        open={openConfirmModal}
        handleClose={() => setOpenConfirmModal(false)}
        onSubmit={() => sendInfo(order)}
        formik={formik}
        // showPassportInfo={mustShow}
        loadingDelivery={isLoadingDeliveryPrice}
      />
      <PreorderOnOrderModal
        open={openNotAvailableModal}
        handleClose={() => setOpenNotAvailableModal(false)}
        onSubmit={afterOrderSubmit}
        haveToBePreorder={haveToBePreOrder}
        availableBracelets={availableBracelets}
      />
    </div>
  );
};
