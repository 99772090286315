import { createTheme } from "@material-ui/core";

const lightTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#FF2D55",
      dark: "#CB3750",
      contrastText: "#fff",
    //   light: "#fff",
    },
    secondary: {
      main: "#ffD500",
    },
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
  },
  typography: {
    fontFamily: "'Manrope'"
  }
});
const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#00E5F0",
      dark: "#FFaaaa",
      contrastText: "#000",
    //   light: "#fff",
    },
    secondary: {
      main: "#ff00ff",
    },
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
  },
});

export { lightTheme, darkTheme };
