import React from "react";
import { useDispatch } from "react-redux";
import { loadBasket } from "../../store/features/shop/Shop";
import { AppDispatch } from "../../store/store";
import { AlfaStats } from "../UI/AlfaPage/AlfaStats";
import { HowToUse } from "../UI/AlfaPage/HowtoUse";
import { Main } from "../UI/AlfaPage/Main";
import { Possibilities } from "../UI/AlfaPage/Possibilities";
import Footer from "../UI/Footer";
import { HeaderV2 } from "../UI/HeaderV2";

export const VUSO = () => {
  const dispatch: AppDispatch = useDispatch();
  React.useEffect(() => {
    document.title = "MISU VUSO";
    dispatch(loadBasket());
  }, [dispatch]);

  return (
    <div style={{ width: "100%" }}>
      <HeaderV2 />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
        }}
      >
        <Main />
        <HowToUse />
        <AlfaStats />
        <Possibilities />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
