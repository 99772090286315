import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: "112px 5% 88px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: 1200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "48px 16px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#1D3A60",
    width: "100%",
    height: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 24,
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 32,
      width: "90%",
      "div:last-child > &:last-child": {
        marginBottom: 0,
      },
    },
  },
  row: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "baseline",
    marginBottom: 60,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: 0,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 80,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  text: {
    textAlign: "center",
    width: "100%",
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "140%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

const Item = ({ img, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <div className={classes.imageWrapper}>
        <img src={img} alt="icon" />
      </div>
      <div>
        <Typography className={classes.text}>{text}</Typography>
      </div>
    </div>
  );
};

export const Section6 = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Typography className={classes.title}>
          {t("SECTION_6_TITLE")}
        </Typography>
        <div className={classes.itemsWrapper}>
          <div className={classes.row}>
            <Item
              img={window.location.origin + "/image/section6/item_1.svg"}
              text={t("SECTION_6_ITEM_1")}
            />
            <Item
              img={window.location.origin + "/image/section6/item_2.svg"}
              text={t("SECTION_6_ITEM_2")}
            />
          </div>
          <div className={classes.row}>
            <Item
              img={window.location.origin + "/image/section6/item_3.svg"}
              text={t("SECTION_6_ITEM_3")}
            />
            <Item
              img={window.location.origin + "/image/section6/item_4.svg"}
              text={t("SECTION_6_ITEM_4")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
