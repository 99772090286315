import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  makeStyles,
  Modal,
  Typography,
} from "@material-ui/core";
import { Close, ExpandLess, ExpandMore } from "@material-ui/icons";
import { ListItemButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../store/store";
import { AssistanceForm } from "./AssistanceForm";
import { DeliveryForm } from "./DeliveryForm";
import { InfoForm } from "./InfoForm";
import { PaymentForm } from "./PaymentForm";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "50%",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "85%",
    color: "#1D3A60",
    padding: "48px 35px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: "38px 20px 48px",
    },
  },
  orderWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    padding: "23px 40px",
    width: "100%",
    borderRadius: 32,
    marginBottom: 20,
  },
  paymentTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "150%",
    color: "#69779A",
  },
  paymentCost: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "180%",
  },
  paymentTitleTotal: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "150%",
    color: "#1D3A60",
  },
  paymentTotal: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "150%",
    color: "#1D3A60",
  },
  paymentItemWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  paymentTotalWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 21,
    marginBottom: 12,
  },
  orderTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: "120%",
    textAlign: "center",
    marginBottom: 24,
    color: "#1D3A60",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: "100%",
    padding: "0px 30px 20px 0px",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 32,
      opacity: 0.2,
      backgroundColor: "#DEE1EB",
      border: "3px solid #DEE1EB",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#FF5E5E",
      borderRadius: 32,
      height: "25%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkboxWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  submitButton: {
    marginTop: 20,
    textTransform: "none",
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    width: "35%",
    padding: "14px 69px",
    [theme.breakpoints.down("sm")]: {
      width: "55%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  link: {
    color: "#5D9BF8",
    textDecoration: "underline",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "140%",
  },
  checkbox: {
    color: "#69779A",
    "&.Mui-checked": {
      color: "#5D9BF8",
    },
  },
  closeButton: {
    position: "absolute",
    right: 15,
    top: 15,
    [theme.breakpoints.down("xs")]: {
      right: 5,
      top: 5,
    },
  },
  vusoDescription: {
    fontWeight: 400,
    fontSize: 8,
    lineHeight: "140%",
    marginBottom: 17,
    color: "#69779A",
    opacity: 0.5,
  },
  collapseText: {
    fontFamily: "Manrope",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
    marginBottom: 48,
    color: "#69779A",
    maxWidth: 400,
    "& p": {
      marginBottom: 18,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "140%",
    },
    "& ul": {
      paddingLeft: 16,
      "& li": {
        marginBottom: 12,
        position: "relative",
        "&:last-child": {
          marginBottom: 0,
        },
        "&:before": {
          position: "absolute",
          display: "block",
          content: "''",
          width: 8,
          height: 8,
          backgroundColor: "#5D9BF8",
          opacity: 0.5,
          top: 5,
          left: "-16px",
          borderRadius: "50%",
        },
      },
    },
    "& ol": {
      marginTop: 12,
      "& li": {
        marginBottom: 12,
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
  },
  collapseButton: {
    paddingLeft: "0px !important",
    "&:hover": {
      background: "none !important",
    },
  },
}));

export const ConfirmationModal = ({
  open,
  handleClose,
  onSubmit,
  formik,
  // showPassportInfo,
  loadingDelivery,
}: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const basket = useSelector((store: RootState) => store.shop.basket);
  const isPreOrder = useSelector(
    (store: RootState) => store.shop.basket.isPreOrder
  );

  React.useEffect(() => {
    var checkboxes = document.querySelectorAll(".verification_form input");

    checkboxes.forEach((elem: any) => {
      elem.oninput = (e: any) => {
        e.target.setCustomValidity("");
      };
      elem.oninvalid = (e: any) => {
        e.target.setCustomValidity(t("REQUIRED"));
      };
    });
  }, [open]);

  React.useEffect(() => {
    var checkboxes = document.querySelectorAll(".verification_form input");
    console.log(checkboxes);

    checkboxes.forEach((elem: any) => {
      elem.oninput = (e: any) => {
        e.target.setCustomValidity("");
      };
      elem.oninvalid = (e: any) => {
        e.target.setCustomValidity(t("REQUIRED"));
      };
    });
  }, [open]);

  const submitHandler = (event: any) => {
    event.preventDefault();
    onSubmit();
    handleClose();
  };

  const onCollapseClick = () => {
    setCollapseOpen((prev) => !prev);
  };

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box className={classes.wrapper}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButton>
        <div className={classes.content}>
          <Typography className={classes.title}>
            {t("DATA_VERIFICATION")}
          </Typography>
          <div className={classes.orderWrapper}>
            <Typography className={classes.orderTitle}>
              {t("ORDER_BASKET")}
            </Typography>
            <div className={classes.paymentItemWrapper}>
              <Typography className={classes.paymentTitle}>
                {t("ORDER_BASKET")} ({basket?.itemsCount})
              </Typography>
              <Typography className={classes.paymentCost}>
                {+basket!.totalPrice}₴
              </Typography>
            </div>
            <div className={classes.paymentItemWrapper}>
              <Typography className={classes.paymentTitle}>
                {t("DELIVERY")}
              </Typography>
              <Typography className={classes.paymentCost}>
                {loadingDelivery ? (
                  <CircularProgress size={18} color="primary" />
                ) : (
                  `${basket!.deliveryTax}₴`
                )}
              </Typography>
            </div>
            {/* {(showPassportInfo() || formik.values.forGift) && ( */}
              <>
                <ListItemButton
                  onClick={onCollapseClick}
                  className={classes.collapseButton}
                  disableRipple
                  disableTouchRipple
                >
                  <ListItemText
                    primary={t("MEDICAL_ASSISTANCE_ONLINE") + "*"}
                  />
                  {collapseOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Typography className={classes.vusoDescription}>
                  *{t("INSURANCE_AMOUNT")} <br /> {t("INSURANCE_TARIFF")} <br />{" "}
                  {t("INSURANCE_PAYMENT")}
                </Typography>
                <Collapse
                  in={collapseOpen}
                  timeout="auto"
                  unmountOnExit
                  className={classes.collapseText}
                >
                  <Typography>{t("INSURANCE_TITLE")}</Typography>
                  <ul>
                    <li>{t("INSURANCE_1")}</li>
                    <li>{t("INSURANCE_2")}</li>
                  </ul>
                  <ol type="a">
                    <li>{t("INSURANCE_A")}</li>
                    <li>{t("INSURANCE_B")}</li>
                    <li>{t("INSURANCE_C")}</li>
                    <li>{t("INSURANCE_D")}</li>
                  </ol>
                </Collapse>
              </>
            {/* )} */}

            <Divider variant="fullWidth" style={{ width: "100%" }} />

            <div className={classes.paymentTotalWrapper}>
              <Typography className={classes.paymentTitleTotal}>
                {t("TOTAL")}
              </Typography>
              <Typography className={classes.paymentTotal}>
                {+basket!.totalPrice + +basket!.deliveryTax}₴
              </Typography>
            </div>
          </div>
          <InfoForm formik={formik} isDisabled={true} />
          {formik.values.paymentMethod !== "preOrder" && !isPreOrder && (
            <DeliveryForm formik={formik} isDisabled={true} />
          )}
          {/* {showPassportInfo() && ( */}
          {/* {formik.values.paymentMethod !== "preOrder" && !isPreOrder && ( */}
          {/* <AssistanceForm
            availableInputting={showPassportInfo}
            formik={formik}
            isDisabled={true}
          /> */}
          {/* )} */}
          {/* )} */}
          <PaymentForm formik={formik} isDisabled={true} />
          <form
            className={classes.form + " verification_form"}
            onSubmit={submitHandler}
            style={{
              display:
                formik.values.paymentMethod === "preOrder" ? "none" : "flex",
            }}
          >
            <div className={classes.checkboxWrap}>
              {/* <FormControlLabel
                control={<Checkbox className={classes.checkbox} required />}
                label={<Typography>{t("DATA_VERIFICATION_1")}</Typography>}
              /> */}
              <FormControlLabel
                control={<Checkbox className={classes.checkbox} required />}
                label={<Typography>{t("DATA_VERIFICATION_2")}</Typography>}
              />
              <FormControlLabel
                control={<Checkbox className={classes.checkbox} required />}
                label={
                  <Typography>
                    {t("DATA_VERIFICATION_3_1")}{" "}
                    <a
                      href="/policy?tab=health_declaration"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.link}
                    >
                      {t("DATA_VERIFICATION_3_2")}
                    </a>
                    *
                  </Typography>
                }
              />
              {/* {showPassportInfo() && (
                <FormControlLabel
                  control={<Checkbox className={classes.checkbox} required />}
                  label={
                    <Typography>
                      {t("DATA_VERIFICATION_4_1")}{" "}
                      <a
                        href={window.location.origin + "/pdf/VUSO_Insurance.pdf"}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}
                      >
                        {t("DATA_VERIFICATION_4_2")}
                      </a>
                      *
                    </Typography>
                  }
                />
              )} */}
              <FormControlLabel
                control={<Checkbox className={classes.checkbox} required />}
                label={
                  <Typography>
                    {t("DATA_VERIFICATION_5_1")}{" "}
                    <a
                      href="/policy?tab=offer"
                      target="_blank"
                      rel="noreferrer"
                      className={classes.link}
                    >
                      {t("DATA_VERIFICATION_5_2")}
                    </a>
                    *
                  </Typography>
                }
              />
              <Typography style={{ marginTop: 20 }}>
                *{t("DATA_VERIFICATION_DESCRIPTION")}
              </Typography>
            </div>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitButton}
            >
              {t("CONTINUE")}
            </Button>
          </form>
          <div
            className={classes.form}
            style={{
              display:
                formik.values.paymentMethod === "preOrder" ? "flex" : "none",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={classes.submitButton}
              onClick={onSubmit}
            >
              {t("CONTINUE")}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
