import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../assets/css/Section3.module.css";
import parse from "html-react-parser";

export const Section3 = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.section_4} ${styles.col_12}`}>
        <div className={`${styles.top} ${styles.col_12}`}>
          <div className={styles.img_s4}></div>
        </div>
        <div className={styles.bottom}>
          <Typography className={styles.text_title}>
            {parse(t("SECTION_3_TITLE"))}
          </Typography>
          <Typography className={styles.text_subtitle}>
            {t("SECTION_3_SUBTITLE")}
          </Typography>
        </div>
      </div>
    </div>
  );
};
