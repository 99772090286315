import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./features/theme/Theme";
import shopReducer from "./features/shop/Shop";
import localeReducer from "./features/locales/Locales";
import newsReducer from "./features/news/News";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    shop: shopReducer,
    locale: localeReducer,
    news: newsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
