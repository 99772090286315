import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "25%",
    border: "1px solid #E4EFFF",
    borderRadius: 16,
    minWidth: 400,
    marginBottom: 24,
    marginRight: 18,
    padding: "24px 30px",
    [theme.breakpoints.down("md")]: {
      minWidth: 500,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      minWidth: 0,
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 32,
      width: "90%",
      "div:last-child > &:last-child": {
        marginBottom: 0,
      },
    },
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 34,
    marginTop: 12,
    maxWidth: 200,
    maxHeight: 150,
    width: "100%",
    height: "100%",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    textAlign: "center",
    width: "100%",
    fontWeight: 800,
    fontSize: 28,
    lineHeight: "120%",
    marginBottom: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  subText: {
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
    fontSize: 26,
    lineHeight: "140%",
    marginBottom: 20,
  },
  text: {
    textAlign: "left",
    width: "100%",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
    color: "#69779A",
    marginBottom: 22,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  button: {
    borderRadius: "40px",
    textTransform: "none",
    marginTop: 28,
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "140%",
    color: "#FFF",
    backgroundColor: "#5985F5",
    padding: "16px 28px",
    minWidth: 240,
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4978F0",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
      width: "100%",
    },
  },
  subItem: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 12,
  },
  subItemText: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "140%",
    marginLeft: 10,
  },
  price: {
    textDecoration: "line-through",
    display: "inline",
    fontSize: 14,
    marginRight: 10,
  },
}));

export const PreorderItem = ({
  img,
  title,
  items,
  price,
  discount,
  text,
}: any) => {
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <div className={classes.imageWrapper}>
        <img src={img} alt="icon" />
      </div>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subText}>
        Ціна: <Typography className={classes.price}>{price}₴</Typography>
        {price * (1 - discount / 100)}₴
      </Typography>
      <Typography className={classes.text}>{text}</Typography>
      {items?.map((e: any) => (
        <div className={classes.subItem}>
          <img
            src={window.location.origin + "/image/subscriptions/check.svg"}
            alt="done"
          />
          <Typography className={classes.subItemText}>{e}</Typography>
        </div>
      ))}
    </div>
  );
};
