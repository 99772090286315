import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { WatchColor } from "../../store/features/shop/Shop";

export const BraceletColorSelect = ({ selected, onChange, colors }: any) => {
  const { t } = useTranslation();

  const onChangeColor = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  return (
    <Select
      value={selected}
      onChange={onChangeColor}
      renderValue={() => (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: "50%",
              border: "1px solid #000",
              backgroundColor: colors.find((c: WatchColor) => c.id === selected)
                ?.value,
              marginRight: 10,
            }}
          ></div>
          {/* <div>
            {t(colors.find((c: WatchColor) => c.id === selected)?.label)}
          </div> */}
        </div>
      )}
    >
      {colors.map((c: WatchColor) => (
        <MenuItem value={c.id}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: "50%",
                border: "1px solid #000",
                backgroundColor: c.value,
                marginRight: 10,
              }}
            ></div>
            {/* <div>{t(c.label)}</div> */}
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};
