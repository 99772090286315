import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    width: "100%",
    backgroundColor: "#fff",
    padding: "55px 50px 55px 40px",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
    },
  },
  title: {
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: 24,
    color: "#1D3A60",
    marginBottom: 30,
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginBottom: 40,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  itemImage: {
    marginRight: 20,
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: 70,
        height: 70,
      },
    },
  },
  itemText: {
    fontWeight: 500,
    fontSize: 16,
    color: "#69779A",
    textAlign: "left",
    fontFamily: "Manrope",
  },
}));

const Item = ({ img, text }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <div className={classes.itemImage}>
        <img src={img} alt="item_image" />
      </div>
      <div>
        <Typography className={classes.itemText}>{text}</Typography>
      </div>
    </div>
  );
};

export const Sensors = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("HEALTH_MONITORING")}</Typography>
      <div className={classes.itemsWrapper}>
        <Item
          img={window.location.origin + "/image/sensors/item_1.svg"}
          text={parse(t("SENSORS_TITLE_1"))}
        />
        <Item
          img={window.location.origin + "/image/sensors/item_2.svg"}
          text={parse(t("SENSORS_TITLE_2"))}
        />
        <Item
          img={window.location.origin + "/image/sensors/item_3.svg"}
          text={t("SENSORS_TITLE_3")}
        />
        <Item
          img={window.location.origin + "/image/sensors/item_4.svg"}
          text={parse(t("SENSORS_TITLE_4"))}
        />
        <Item
          img={window.location.origin + "/image/sensors/item_5.svg"}
          text={parse(t("SENSORS_TITLE_5"))}
        />
        <Item
          img={window.location.origin + "/image/sensors/item_6.svg"}
          text={parse(t("SENSORS_TITLE_6"))}
        />
      </div>
    </div>
  );
};
