import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loadBasket } from "../../store/features/shop/Shop";
import Footer from "../UI/Footer";
import { HeaderV2 } from "../UI/HeaderV2";

export const Instructions = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isEnLocale = i18n.language === "en";
  React.useEffect(() => {
    document.title = "MISU Instructions";
    dispatch(loadBasket());
  }, [dispatch]);

  return (
    <>
      <HeaderV2 />
      <div className="container-flex">
      <div id="connection" style={{ height: 75 }}></div>
        <div className="title-wrapper ">
          <p className="blocks-title">{t("INSTRUCTION")}</p>
        </div>
        <div className="instructions block-wrapper">
          <div></div>
          <div className="instructions__card-wrapper">
            <p className="instructions__title">{t("DEVICE_CONNECTION")}</p>
            <div className="instructions__card">
              <p className="instructions__main-text">
                {t("MAKE_SURE")}
                <span className="blue-text">Bluetooth</span> {t("TURNED_ON")}
              </p>
              <p className="instructions__main-text">
                {t("DEVICE_CONNECTION_1")} <br /> {t("DEVICE_CONNECTION_1_1")}
              </p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-1${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div className="instructions__card">
              <p className="instructions__main-text">
                {t("DEVICE_CONNECTION_2")}
              </p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-2${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div className="instructions__card">
              <p className="instructions__main-text">
                {t("DEVICE_CONNECTION_3")}
              </p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-3${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div id="settings"></div>
            <p className="instructions__title">{t("CONFIGURE_APP")}</p>
            <p className="instructions__sub-title">{t("PATIENT_DOCTOR")}</p>

            <div className="instructions__card">
              <div className="instructions__main-text-wrapper">
                <p className="instructions__main-text instructions__main-text-profile">
                  {t("CONFIGURE_APP_1")}
                </p>
              </div>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-4${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>

            <div className="instructions__card">
              <p className="instructions__main-text">{t("CONFIGURE_APP_2")}</p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-5${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <p className="instructions__title">{t("FAMILY_ACCESS")}</p>
            <div className="instructions__card">
              <div className="instructions__main-text-wrapper">
                <p className="instructions__main-text instructions__main-text-fam">
                  {t("FAMILY_ACCESS_1")}
                </p>{" "}
              </div>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-6${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div className="instructions__card">
              <p className="instructions__main-text">{t("FAMILY_ACCESS_2")}</p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-7${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div className="instructions__card">
              <p className="instructions__main-text">{t("FAMILY_ACCESS_3")}</p>
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-8${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
            </div>
            <div className="instructions__card">
              <p className="instructions__main-text">{t("FAMILY_ACCESS_5")}</p>
              <ul>
                <li className="instructions__main-text">
                  {t("FAMILY_ACCESS_5_1")}
                </li>
                <li className="instructions__main-text">
                  {t("FAMILY_ACCESS_5_2")}
                </li>
                <li className="instructions__main-text">
                  {t("FAMILY_ACCESS_5_3")}
                </li>
              </ul>
            </div>
            <div className="instructions__card">
              <img
                className="instructions__img"
                src={
                  window.location.origin +
                  `/image/instruction-bg-9${isEnLocale ? "-eng" : ""}.png`
                }
                alt="phone"
              />
              <p className="instructions__main-text">{t("FAMILY_ACCESS_7")}</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </>
  );
};
