import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { Add, ChevronLeft, ChevronRight, Remove } from "@material-ui/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import parse from "html-react-parser";
import {
  AddBasketItem,
  addToBasket,
  Product,
  WatchColor,
  WatchImages,
} from "../../../store/features/shop/Shop";
import { AppDispatch } from "../../../store/store";
import { BraceletColorSelect } from "../BraceletColorSelect";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    width: "100%",
    backgroundColor: "#fff",
    padding: "55px 50px 55px 40px",
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
      paddingTop: 30,
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  infoPart: {
    textAlign: "left",
    width: "100%",
  },
  slider: {
    width: "80%",
    height: "100%",
    margin: "auto",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginRight: 125,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      width: "60%",
      height: "60%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
      marginBottom: 40,
    },
  },
  mainImage: {
    minWidth: 300,
    minHeight: 300,
    width: 300,
    height: 300,
    [theme.breakpoints.down("md")]: {
      height: "70%",
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      width: "100%",
    },
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 69px",
    borderRadius: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    textTransform: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  name: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 36,
    lineHeight: "100%",
    color: "#1D3A60",
  },
  price: {
    color: "#1D3A60",
    fontWeight: 400,
    fontSize: 16,
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
    },
  },
  selects: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  quantity: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 120,
  },
  colorsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: 100,
  },
  description: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "140%",
    width: "75%",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  link: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "140%",
    color: "#5D9BF8",
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  corporateButton: {
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 20,
    },
  },
  watchText: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    color: "#1D3A60",
  },
}));

const arrowsSliderStyles = makeStyles({
  arrow: {
    width: 61,
    height: 61,
    borderRadius: 50,
    opacity: 0.25,
    backgroundColor: "#C4C4C4",
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "45%",
  },
});

const NextSlideArrow = (props: any) => {
  const classes = arrowsSliderStyles();
  const { onClick } = props;
  return (
    <div className={classes.arrow} style={{ right: "-25%" }}>
      <IconButton onClick={onClick}>
        <ChevronRight style={{ fontSize: 32 }} />
      </IconButton>
    </div>
  );
};

const PrevSlideArrow = (props: any) => {
  const classes = arrowsSliderStyles();
  const { onClick } = props;
  return (
    <div className={classes.arrow} style={{ left: "-25%" }}>
      <IconButton onClick={onClick}>
        <ChevronLeft style={{ fontSize: 32 }} />
      </IconButton>
    </div>
  );
};

export const MainShop = ({ watch, onChangeWatch, products }: any) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [pickedWatch, setPickedWatch] = React.useState(watch?.id);
  const [count, setCount] = React.useState<number>(1);
  const [colors, setColors] = React.useState<Array<WatchColor>>(
    watch?.colors ?? []
  );
  const [color, setColor] = React.useState<string>(colors[0]?.id ?? "");
  const [price, setPrice] = React.useState<number>(watch?.price!.ua ?? 0);
  const currentImages = watch?.images?.find(
    (images: WatchImages) => images.color === color
  )?.image;

  const mailtoText: string =
    "Hello!%0D%0AI want to order your bracelets for - YOUR_GOAL%0D%0AI want to buy - BRACELET_NAME(COUNT), ...%0D%0ABest regards!";
  const mailtoSubject: string = `Corporate Order from "YOUR_COMPANY_NAME"`;

  React.useEffect(() => {
    setPickedWatch(watch?.id);
  }, [watch]);

  React.useEffect(() => {
    setColors(watch?.colors ?? []);
  }, [watch?.colors]);

  React.useEffect(() => {
    setColor(colors[0]?.id);
  }, [colors]);

  React.useEffect(() => {
    setCount(1);
    setPrice(watch?.price.ua);
  }, [watch, i18n.language]);

  React.useEffect(() => {
    setPrice(watch?.price.ua);
  }, [i18n.language, watch?.price]);

  const plusCount = () => {
    setCount((prev) => prev + 1);
  };

  const minusCount = () => {
    if (count === 1) return;
    setCount((prev) => prev - 1);
  };

  const orderHandler = () => {
    addToBasketItem();
    history.push("/basket");
  };

  const addToBasketItem = () => {
    const item: AddBasketItem = { id: watch.id, color, count };
    dispatch(addToBasket(item));
  };

  const changeWatch = (e: any) => {
    setPickedWatch(e.target.value);
    onChangeWatch(e.target.value);
  };

  const sliderOptions = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    nextArrow: <NextSlideArrow />,
    prevArrow: <PrevSlideArrow />,
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.slider}>
        <div
          style={{
            display: !watch?.existence ? "block" : "none",
            position: "absolute",
            zIndex: 2,
            fontSize: 36,
            color: "#171926",
            fontWeight: 300,
            width: "100%",
          }}
        >
          {t("COMING_SOON")}
        </div>
        <Slider className="watches-slider" {...sliderOptions}>
          <div>
            <img
              className={classes.mainImage}
              src={currentImages}
              alt="watch"
              style={!watch?.existence ? { opacity: 0.5 } : {}}
            />
          </div>
          {/* {currentColoredImages?.images?.map(
              (imageSrc: string, i: number) => (
                <div
                  key={i.toString()}
                  style={{
                    width: "300px !important",
                    height: "300px !important",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={`${imageSrc}`}
                    alt="image"
                  />
                </div>
              )
            )} */}
        </Slider>
      </div>
      <div className={classes.infoPart}>
        <Typography className={classes.name}>
          MISU Watch {watch?.name.toUpperCase()}
        </Typography>
        <Typography className={classes.price}>{price}₴</Typography>
        <div className={classes.selects}>
          <div className={classes.quantity}>
            <IconButton onClick={minusCount}>
              <Remove />{" "}
            </IconButton>
            <Typography>{count}</Typography>
            <IconButton onClick={plusCount}>
              <Add />{" "}
            </IconButton>
          </div>
          <div className={classes.colorsWrapper}>
            <BraceletColorSelect
              selected={color}
              onChange={(value: string) => setColor(value)}
              colors={colors}
            />
          </div>
          <div className={classes.colorsWrapper}>
            <Select
              value={pickedWatch ?? ""}
              onChange={changeWatch}
              renderValue={(val: any) => (
                <Typography className={classes.watchText}>
                  {products
                    .find((e: Product) => e.id === val)
                    ?.name.toUpperCase()}
                </Typography>
              )}
            >
              {products.map((p: Product) => (
                <MenuItem value={p.id} className={classes.watchText}>
                  {p.name.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <div>
          <Typography className={classes.description}>
            {parse(t("SHOP_DESCRIPTION"))}{" "}
            <Typography
              className={classes.link}
              display="inline"
              onClick={() => window.open("/vuso")}
            >
              {t("SHOP_MEDICAL_SUPPORT_LINK")}
            </Typography>
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={orderHandler}
            >
              {t(
                !watch?.existence
                  ? "PRE_ORDER"
                  : 
                  "MEDICAL_ASSISTANCE_ONLINE_ORDER"
              )}
            </Button>
            <div className={classes.corporateButton}>
              <a
                href={`mailto:misu.connect.ua@gmail.com?subject=${mailtoSubject}&body=${mailtoText}`}
                className={classes.link}
              >
                {t("CORPORATE_ORDER")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
