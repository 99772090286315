import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CookieConfirm } from "../UI/CookieConfirm";

export const PageWrap = ({ children, fullScreen = false }: any) => {
  const loadingLocales = useSelector(
    (store: RootState) => store.locale.loading
  );

  if (loadingLocales) return <></>;

  return (
    <div
      style={
        fullScreen
          ? {
              color: "#000",
              display: "flex",
              flexDirection: "column",
              height: "100vh",
              width: "100vw",
              alignItems: "center",
            }
          : {}
      }
    >
      <CookieConfirm />
      {children}
    </div>
  );
};
