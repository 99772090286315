import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundImage: `url(${window.location.origin}/image/section_5_bg.svg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    padding: "38px 5%",
    borderRadius: 40,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: 1200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "48px 16px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#1D3A60",
    width: "100%",
    height: "100%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  phoneWrapper: {
    width: "50%",
    marginRight: 40,
    maxHeight: 580,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      // height: "100%",
      // width: "100%",
      maxHeight: 580,
    },
    [theme.breakpoints.down("sm")]: {
    width: "75%",
    marginRight: 0,
    },
  },
  textWrapper: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 50,
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

export const Section5 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.phoneWrapper}>
          <img
            src={window.location.origin + "/image/section5_phone.png"}
            alt="phone"
          />
        </div>
        <div className={classes.textWrapper}>
          <Typography className={classes.title}>
            {t("SECTION_5_TITLE")}
          </Typography>
          <Typography className={classes.text}>
            {t("SECTION_5_SUBTITLE_1")}
            <br />
            <br />
            {t("SECTION_5_SUBTITLE_2")}
          </Typography>
        </div>
      </div>
    </div>
  );
};
