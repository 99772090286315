import {
  FormControl,
  makeStyles,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    backgroundImage: `url(${window.location.origin}/image/feedback_bg.svg)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "55px 120px 60px",
    // marginBottom: 120,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "50px 32px 60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "50px 16px 60px",
      // marginBottom: 60,
    },
  },
  content: {
    width: "100%",
    maxWidth: 1200,
    color: "#fff",
    padding: "55px 120px 60px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "50px 32px 60px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "50px 16px 60px",
      marginBottom: 60,
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 32,
    "& .MuiFormControl-root": {
      marginRight: 20,
      "& .MuiFormControl-root.MuiTextField-root": {
        marginRight: 0,
      },
      "&:last-child": {
        marginRight: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: 0,
      "& .MuiFormControl-root": {
        marginRight: 0,
      },
    },
  },
  input: {
    border: "1px solid #E4EFFF",
    borderRadius: 8,
    "& fieldset": {
      border: "none !important",
    },
    "& input": {
      color: "#fff",
    },
    "& textarea": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
  inputPhone: {
    border: "1px solid #E4EFFF",
    borderRadius: 8,
    textAlign: "left",
    width: "100% !important",
    height: "100% !important",
    "& input": {
      background: "none !important",
      border: "none !important",
      color: "#8aa2bf !important",
      paddingLeft: "0px !important",
      width: "100% !important",
      height: "100% !important",
      padding: "18px 0px",
    },
    "& .country-list": {
      color: "#8aa2bf",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 24,
    },
  },
  label: {
    color: "#fff",
    textAlign: "left",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    marginBottom: 7,
  },
  submitButton: {
    borderRadius: "40px",
    textTransform: "none",
    marginTop: 45,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    color: "#1D3A60",
    backgroundColor: "#FFFFFF",
    padding: "16px 28px",
    minWidth: 240,
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      color: "#1D3A60",
      backgroundColor: "#FBFCFF",
      opacity: 0.8,
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 16,
      width: "100%",
    },
  },
}));

export const Section11Feedback = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubmit = async (values) => {
    if (!process.env.REACT_APP_FEEDBACK) return;

    const { name, phone, message } = values;

    console.log(values);

    await axios
      .post(process.env.REACT_APP_FEEDBACK, {
        name,
        message,
        phone: `+${phone}`,
        email: "",
      })
      .then((response) => {})
      .catch((error) => {})
      .finally(() => {
        formik.resetForm();
      });
  };

  const validationSchema = yup
    .object({
      name: yup.string().required(t("REQUIRED")),
      phone: yup
        .string()
        // .min(10, t("MUST_BE_TEN"))
        // .max(10, t("MUST_BE_TEN"))
        .required(t("REQUIRED")),
      message: yup.string().required(t("REQUIRED")),
    })
    .required(t("REQUIRED"));

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      message: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handlePhoneChange = (value, data, e, formattedValue) => {
    const event = {
      target: {
        name: "phone",
        value: formattedValue,
      },
    };

    formik.handleChange(event);
  };

  return (
    <div className={classes.wrapper} id="feedback">
      <div className={classes.content}>
        <Typography className={classes.title}>
          {t("FEEDBACK_SUBTITLE")}
        </Typography>
        <Typography className={classes.subtitle}>
          {t("SECTION_11_SUBTITLE")}
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <div className={classes.formRow}>
            <FormControl fullWidth>
              <Typography className={classes.label}>
                {t("FULL_NAME")}
              </Typography>
              <TextField
                id="name"
                name="name"
                variant="outlined"
                placeholder={t("Ivanov Ivan Ivanovich")}
                className={classes.input}
                onChange={formik.handleChange}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                required
                fullWidth
              />
              <p style={{ color: "#FF5E5E" }}>
                {formik.touched.name && formik.errors.name}
              </p>
            </FormControl>
            <FormControl fullWidth>
              <Typography className={classes.label}>{t("CONTACTS")}</Typography>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                onChange={handlePhoneChange}
                value={formik.values.phone}
                className={classes.inputPhone}
                excludeCountries={["ru", "by"]}
                country={"ua"}
              />
              <p style={{ color: "#FF5E5E" }}>
                {formik.touched.phone && formik.errors.phone}
              </p>
            </FormControl>
          </div>
          <FormControl fullWidth>
            <Typography className={classes.label}>{t("QUESTION")}</Typography>
            <TextField
              id="message"
              name="message"
              variant="outlined"
              className={classes.input}
              onChange={formik.handleChange}
              value={formik.values.message}
              error={formik.touched.message && Boolean(formik.errors.message)}
              multiline
              rows={3}
              maxRows={3}
              required
            />
            <p style={{ color: "#FF5E5E" }}>
              {formik.touched.message && formik.errors.message}
            </p>
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            className={classes.submitButton}
          >
            {t("SEND")}
          </Button>
        </form>
      </div>
    </div>
  );
};
