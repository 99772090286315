import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { setLoading } from "./store/features/locales/Locales";
import { store } from "./store/store";

const createLocales = async () => {
  let translationEN = JSON.parse(localStorage.getItem("en_locale") ?? "{}");
  let translationUK = JSON.parse(localStorage.getItem("ua_locale") ?? "{}");

  if (translationEN) {
    // load the locales
    await fetch(process.env.PUBLIC_URL + "/locales/en/translation.json")
      .then((r) => r.json())
      .then((r) => {
        if (r) {
          translationEN = r;
          localStorage.setItem("en_locale", JSON.stringify(r));
        }
      })
      .catch((e) => console.log("error en load: ", e));
  }

  if (translationUK) {
    await fetch(process.env.PUBLIC_URL + "/locales/ua/translation.json")
      .then((r) => r.json())
      .then((r) => {
        if (r) {
          translationUK = r;
          localStorage.setItem("ua_locale", JSON.stringify(r));
        }
      })
      .catch((e) => console.log("error uk load: ", e));
  }

  // the translations
  const resources: any = {
    en: {
      translation: translationEN,
    },
    ua: {
      translation: translationUK,
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
      {
        resources,
        lng: localStorage.getItem("misu_locale") ?? "ua",
        fallbackLng: "ua",
        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      },
      () => store.dispatch(setLoading(false))
    );
};

export default createLocales();
