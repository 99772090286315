import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "90%",
    maxWidth: 1200,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "74px 50px 70px 50px",
    borderRadius: 32,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      padding: "24px 32px",
      width: "100%",
    },
  },
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    // marginRight: 60,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-around",

      marginRight: 0,
      "&:last-child div": {
        // marginBottom: 0,
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  itemWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#1D3A60",
    width: "100%",
    marginBottom: 40,
    "&:nth-child(2)": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("sm")]: {
      "&:nth-child(2)": {
        marginBottom: 40,
      },
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "140%",
    marginTop: 20,
  },
  text: {
    fontWeight: 400,
    fontSize: 16,
    color: "#69779A",
    width: "90%",
  },
  mainTitle: {
    fontSize: 27,
    fontWeight: 800,
    lineHeight: "140%",
    color: "#1D3A60",
    marginBottom: 70,
    textAlign: "left",
  },
}));

const Item = ({ title, text, img }: any) => {
  const classes = useStyles();
  return (
    <div className={classes.itemWrapper}>
      <img src={img} alt="stat" />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>{text}</Typography>
    </div>
  );
};

export const AlfaStats = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography className={classes.mainTitle}>
        {t("ALFA_INSURANCE_MISU_PARTNER")}
      </Typography>
      <div className={classes.wrapper}>
        <div className={classes.column}>
          <Item
            title={t("ALFA_STATS_TITLE_6")}
            text={t("ALFA_STATS_TEXT_6")}
            img={window.location.origin + "/image/vuso/stats/item_6.svg"}
          />
          <Item
            title={t("ALFA_STATS_TITLE_2")}
            text={t("ALFA_STATS_TEXT_2")}
            img={window.location.origin + "/image/vuso/stats/item_2.svg"}
          />
        </div>
        <div className={classes.column}>
          <Item
            title={t("ALFA_STATS_TITLE_3")}
            text={t("ALFA_STATS_TEXT_3")}
            img={window.location.origin + "/image/vuso/stats/item_3.svg"}
          />
          <Item
            title={t("ALFA_STATS_TITLE_4")}
            text={t("ALFA_STATS_TEXT_4")}
            img={window.location.origin + "/image/vuso/stats/item_4.svg"}
          />
        </div>
        <div className={classes.column}>
          <Item
            title={t("ALFA_STATS_TITLE_1")}
            text={t("ALFA_STATS_TEXT_1")}
            img={window.location.origin + "/image/vuso/stats/item_1.svg"}
          />
          <Item
            title={t("ALFA_STATS_TITLE_5")}
            text={t("ALFA_STATS_TEXT_5")}
            img={window.location.origin + "/image/vuso/stats/item_5.svg"}
          />
        </div>
      </div>
    </div>
  );
};
