import React from "react";
import {
  FormControl,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { AppDispatch } from "../../../store/store";
import {
  resetDeliveryInfoNovaPoshta,
  setDeliveryCity,
  setDeliveryTax,
  setNovaPoshtaInfo,
} from "../../../store/features/shop/Shop";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 20,
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 8,
  },
  subtitle: {
    color: "#69779A",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "140%",
    marginBottom: 24,
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "stretch",
    width: "100%",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  column: {
    marginRight: 20,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  inputBlock: {
    marginBottom: 24,
    textAlign: "left",
    // minHeight: 80,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 0,

      "&:last-child": {
        marginBottom: 24,
      },
    },
  },
  link: {
    color: "#5D9BF8",
    textDecoration: "underline",
  },
}));

export const DeliveryForm = React.memo(
  ({ formik, isDisabled = false }: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const [novaPoshtaDeps, setNovaPoshtaDeps] = React.useState([]);
    const [cities, setCities] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const useAutoCompleteStyles = makeStyles({
      autocomplete: {
        "& fieldset": {
          // borderColor: !formik!.values.address ? "#DD284A" : "",
        },
      },
      root: {
        height: "100%",
        "& div": {
          height: "100%",
        },
      },
      option: {
        "&.Mui-focused": {
          backgroundColor: "#EEF5FF !important",
        },
      },
    });

    const autocompleteStyles = useAutoCompleteStyles();

    const fetchCities = async () => {
      await axios
        .post("https://api.novaposhta.ua/v2.0/json/", {
          modelName: "Address",
          calledMethod: "getCities",
          methodProperties: {},
          apiKey: process.env.REACT_APP_NOVA_POSHTA_API,
        })
        .then(async ({ data }: any) => {
          const shortData = await data?.data?.map((d: any) => ({
            id: d.Ref,
            label: d.Description,
            area: d.AreaDescription,
          }));
          setCities(shortData);
        })
        .catch((e: any) => console.log("e: ", e));
    };

    React.useEffect(() => {
      if (isDisabled) return;

      if (cities.length === 0) fetchCities();
    }, []);

    // React.useEffect(() => {
    //   if (isDisabled || cities.length === 0) return;
    //   fetchDepartmentsByCity(formik!.values.city);
    // }, [formik!.values.city]);

    const fetchDepartmentsByCity = async (selectedCity: string) => {
      setLoading(true);
      await axios
        .post("https://api.novaposhta.ua/v2.0/json/", {
          modelName: "AddressGeneral",
          calledMethod: "getWarehouses",
          methodProperties: {
            CityName: selectedCity,
          },
          apiKey: process.env.REACT_APP_NOVA_POSHTA_API ?? "",
        })
        .then(async ({ data }: any) => {
          const shortData = await data?.data?.map((d: any) => ({
            id: d.Ref,
            description: d.Description.split(":")[0],
            address: d.Description.split(":")[1],
          }));
          setNovaPoshtaDeps(shortData);
          setLoading(false);
        })
        .catch((e: any) => console.log("e: ", e));
    };

    const autoCompleteSelectCity = async (
      event: any,
      value: any,
      reason: any
    ) => {
      dispatch(setDeliveryTax(0));
      let selected = "";

      if (reason === "clear") {
        formik.setFieldValue("city", "");
        autoCompleteSelectDepartment({}, "", "clear");
        return;
      }

      if (event.type === "blur") return;

      if (event.type === "keydown") selected = value?.label;
      if (event.type === "click" || reason === "selectOption")
        selected = value?.label;

      if (selected === "") {
        formik.setFieldValue("city", "");
        return;
      }

      const city: any = cities.find((c: any) => c.label === selected);

      if (!city) return;

      formik.setFieldValue("city", city.label);
      formik.setFieldValue("address", "");
      setNovaPoshtaDeps([]);
      fetchDepartmentsByCity(city!.label);
      dispatch(setDeliveryCity(city));
    };

    const autoCompleteSelectDepartment = (
      event: any,
      value: any,
      reason: any
    ) => {
      let selected = "";
      console.log(value);
      if (reason === "clear") {
        formik.setFieldValue("address", "");
        dispatch(resetDeliveryInfoNovaPoshta());
        return;
      }

      if (event.type === "blur") return;

      if (event.type === "keydown") selected = value;
      if (event.type === "click") selected = value;

      if (selected === "") {
        formik.setFieldValue("address", "");
        dispatch(resetDeliveryInfoNovaPoshta());
        return;
      }
      console.log("selected: ", selected);

      const dep: any = novaPoshtaDeps.find(
        (c: any) => c?.description === selected.split(",")[0]
      );
      if (!dep) return;

      formik.setFieldValue(
        "address",
        `${dep?.description} (${dep?.address})` ?? selected
      );
      dispatch(setNovaPoshtaInfo(dep));
    };

    return (
      <div className={classes.wrapper}>
        <Typography className={classes.title}>
          {t("DELIVERY_AND_ADDRESS")}
        </Typography>
        <Typography align="left" className={classes.subtitle}>
          {t("DELIVERY_DESCRIPTION_1")} <br />
          {t("DELIVERY_DESCRIPTION_2")}
          <br /> <br />
          {t("DELIVERY_DESCRIPTION_ANOTHER_COUNTRY")}
          <a
            href="https://misu.health/"
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            {t("LINK")}
          </a>
        </Typography>
        <div className={classes.content}>
          <div className={classes.column}>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("COUNTRY")}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name="country"
                  variant="outlined"
                  placeholder={t("UKRAINE")}
                  value={formik!.values.country}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.country && Boolean(formik!.errors.country)
                  }
                  disabled={true}
                  required
                />
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.country && formik!.errors.country}
                </p>
              </FormControl>
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("DELIVERY_METHOD")}*</Typography>
              <FormControl fullWidth>
                <TextField
                  name="delivery"
                  variant="outlined"
                  placeholder={t("NOVA_POSHTA")}
                  value={formik!.values.delivery}
                  onChange={formik.handleChange}
                  error={
                    formik!.touched.delivery && Boolean(formik!.errors.delivery)
                  }
                  disabled={true}
                  required
                />
                <p style={{ color: "#DD284A" }}>
                  {formik!.touched.delivery && formik!.errors.delivery}
                </p>
              </FormControl>
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("CITY")}*</Typography>
              <FormControl fullWidth style={{ height: 53 }}>
                <Autocomplete
                  options={cities}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  blurOnSelect={true}
                  openOnFocus={true}
                  onChange={autoCompleteSelectCity}
                  // isOptionEqualToValue={(option: any, value: any) => {
                  //   if (value == null || value === "" || value.trim() === "")
                  //     return false;

                  //   return option.label?.toLowerCase() === value.toLowerCase();
                  // }}
                  filterOptions={(options, state): any => {
                    if (
                      state.inputValue == null ||
                      state.inputValue == "" ||
                      state.inputValue.trim() === "" ||
                      options.length === 0
                    )
                      return [];
                    // return cities.map((c: any) => c.label);

                    return options.filter(
                      (op: any) =>
                        op?.label
                          ?.toLowerCase()
                          ?.startsWith(state.inputValue.toLowerCase()) ?? []
                    );
                  }}
                  loading={cities.length === 0}
                  noOptionsText={t("NO_CITIES")}
                  loadingText={t("LOADING")}
                  value={formik!.values.city ?? ""}
                  disabled={isDisabled}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder={t("CHOOSE_YOUR_SETTLEMENT")}
                      variant="outlined"
                      disabled={isDisabled}
                      required
                    />
                  )}
                  classes={{
                    inputRoot: autocompleteStyles.autocomplete,
                    root: autocompleteStyles.root,
                    option: autocompleteStyles.option,
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.inputBlock}>
              <Typography align="left">{t("DELIVERY_ADDRESS")}*</Typography>
              <FormControl fullWidth style={{ height: 53 }}>
                <Autocomplete
                  options={novaPoshtaDeps.map(
                    (npD: any) => `${npD.description}, ${npD.address}`
                  )}
                  blurOnSelect={true}
                  autoSelect={true}
                  autoComplete={true}
                  autoHighlight={true}
                  openOnFocus={true}
                  onChange={autoCompleteSelectDepartment}
                  filterOptions={(options, state): any => {
                    if (
                      state.inputValue === "" ||
                      state.inputValue.trim() === "" ||
                      options.length === 0
                    )
                      return novaPoshtaDeps.map(
                        (npD: any) => `${npD.description}, ${npD.address}`
                      );

                    return options.filter(
                      (op: any) =>
                        op
                          ?.toLowerCase()
                          ?.includes(state.inputValue?.toLowerCase()) ?? []
                    );
                  }}
                  loadingText={t("LOADING")}
                  noOptionsText={t("NO_BRANCHES")}
                  loading={loading}
                  disabled={formik!.values.city === "" || isDisabled}
                  value={formik!.values.address ?? ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t("CHOOSE_BRANCH")}
                      variant="outlined"
                      disabled={formik!.values.city === "" || isDisabled}
                      required
                    />
                  )}
                  classes={{
                    inputRoot: autocompleteStyles.autocomplete,
                    root: autocompleteStyles.root,
                    option: autocompleteStyles.option,
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <Typography align="left">* {t("MANDATORY_FIELD")}</Typography>
      </div>
    );
  }
);
