import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../assets/css/Section10.module.css";

export const Section10 = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.margin1} id="partners">
      <div className={styles.wrapper}>
        <Typography className={styles.text_title}>
          {t("SECTION_10_TITLE")}
        </Typography>
      </div>
      <div className={styles.wrapper}>
        <div className={`${styles.section_12} ${styles.col_12} ${styles.row}`}>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_vuso}
              href="https://vuso.ua/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_moz}
              href="https://en.moz.gov.ua/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_ITARENA}
              href="https://itarena.ua/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_JICA}
              href="https://www.jica.go.jp/english/index.html?"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_medimost}
              href="https://medimost.eu/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div
            className={`${styles.partnerCard} ${styles.row} ${styles.partner_GOU}`}
          ></div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_Netrix}
              href="https://netrix.ventures/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_oranta}
              href="https://www.oranta-ao.com/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_AAIC}
              href="https://aa-ic.com/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_USF}
              href="https://usf.com.ua/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_Amazon}
              href="https://www.amazon.com/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_KSGC}
              href="https://www.k-startupgc.org/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_PARP}
              href="https://www.parp.gov.pl/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_CPP}
              href="https://polandprize.lpnt.eu/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div
            className={`${styles.partnerCard} ${styles.row} ${styles.partner_unia}`}
          ></div>
          <div className={`${styles.partnerCard} ${styles.row}`}>
            <a
              className={styles.partner_fundasze}
              href="https://www.funduszeeuropejskie.gov.pl/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row} ${styles.partner_askep}`}>
            <a
              className={styles.partner_askep}
              href="https://askep.net/"
              target="_blank"
              rel="noreferrer"
            ></a>
          </div>
          <div className={`${styles.partnerCard} ${styles.row}`}></div>
          <div className={`${styles.partnerCard} ${styles.row}`}></div>
          <div className={`${styles.partnerCard} ${styles.row}`}></div>
        </div>
      </div>
    </div>
  );
};
