import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    maxWidth: 1200,
    marginBottom: 120,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 60,
      padding: "10px 16px",
      paddingBottom: 0,
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  leftPart: {
    color: "#1D3A60",
    textAlign: "left",
    width: "100%",
    height: "100%",
  },
  rightPart: {
    width: "100%",
    height: "100%",
    "& img": {
      width: "70%",
      height: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
      "& img": {
        width: "60%",
        height: "60%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "90%",
        height: "90%",
      },
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 40,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 16,
      fontSize: 27,
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "140%",
    marginBottom: 16,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
}));

export const Section4 = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.leftPart}>
          <Typography className={classes.title}>
            {t("SECTION_4_TITLE")}
          </Typography>
          <Typography className={classes.text}>
            {t("SECTION_4_SUBTITLE")}
          </Typography>
        </div>
        <div className={classes.rightPart}>
          <img
            src={window.location.origin + "/image/section4_img.svg"}
            alt="section_8"
          />
        </div>
      </div>
    </div>
  );
};
