import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PreorderModal } from "../PreorderModal";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100vw",
    backgroundImage: `url(${window.location.origin}/image/section_1_bg.svg)`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    marginTop: "-25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3% 10%",
    marginBottom: 120,
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px",
      marginBottom: 60,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
    maxWidth: 1200,
    color: "#1D3A60",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
  },
  leftPart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
    },
  },
  rightPart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "35%",
    "& img": {
      maxHeight: 600,
      paddingTop: 30,
    },
    [theme.breakpoints.down("md")]: {
      width: "35%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      marginBottom: 30,
      // marginLeft: "35%",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  textWrapper: {
    textAlign: "left",
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  title: {
    fontWeight: 800,
    fontSize: 40,
    lineHeight: "120%",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      fontSize: 27,
    },
  },
  text: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: "140%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  linksWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    marginRight: 18,
    width: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
  },
  button: {
    borderRadius: "40px",
    textTransform: "none",
    marginTop: 56,
    marginBottom: 40,
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    color: "#FFF",
    backgroundColor: "#5985F5",
    padding: "16px 28px",
    minWidth: 240,
    boxShadow: "none",
    transition: "0.2s",
    position: "relative",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4978F0",
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
      marginBottom: 24,
      width: "100%",
    },
  },
  downloadText: {
    fontWeight: 800,
    fontSize: 20,
    lineHeight: "140%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "& button": {
      marginTop: 30,
      marginBottom: 10,
    },
    "& button:last-child": {
      marginTop: 10,
      marginBottom: 40,
    },
  },
  discount: {
    position: "absolute",
    top: -5,
    right: -30,
    background: "#FF2D55",
    borderRadius: 34,
    padding: "4px 8px",
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "140%",
  },
}));

export const Section1 = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openPreorderModal, setOpenPreorderModal] = React.useState(false);

  const history = useHistory();

  const handleShopClick = () => {
    history.push("/watches/air");
  };

  const handlePreorderClick = () => {
    setOpenPreorderModal(true);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.content}>
          <div className={classes.leftPart}>
            <div className={classes.textWrapper}>
              <Typography className={classes.title}>
                {t("SECTION_1_TITLE")}
              </Typography>
              <Typography className={classes.text}>
                {t("SECTION_1_SUBTITLE")}
              </Typography>
              <div className={classes.buttonRow}>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handleShopClick}
                >
                  {t("BUY_MISU_WATCH")}
                </Button>
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={handlePreorderClick}
                >
                  {t("PREORDER_FORM_SUBMIT_MAINPAGE")}
                  <Typography className={classes.discount}>-30%</Typography>
                </Button>
              </div>
              <Typography className={classes.downloadText}>
                {t("DOWNLOAD_THE_APP")}
              </Typography>
            </div>
            <div className={classes.linksWrapper}>
              <div className={classes.link}>
                <a
                  href="https://apps.apple.com/ua/app/misu/id1516509504"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={window.location.origin + "/image/app_store_badge.svg"}
                  />
                </a>
              </div>
              <div className={classes.link}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.WH.MISU"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/image/google_play_badge.svg"
                    }
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.rightPart}>
            <img
              src={window.location.origin + "/image/section_1_phone.png"}
              alt="main_phone"
            />
          </div>
        </div>
      </div>
      <PreorderModal
        isOpen={openPreorderModal}
        onClose={() => setOpenPreorderModal(false)}
      />
    </>
  );
};
