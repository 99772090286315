import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { TechSpec } from "../../../store/features/shop/Shop";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    width: "100%",
    backgroundColor: "#fff",
    padding: "55px 50px 55px 40px",
    marginBottom: 20,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
    },
  },
  title: {
    fontWeight: 600,
    fontFamily: "Manrope",
    fontSize: 24,
    color: "#1D3A60",
    marginBottom: 40,
    textAlign: "left",
  },
  rowsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginRight: 10,
    width: "100%",
    height: "100%",
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 32,
      marginRight: 0,
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  item: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "left",
    minHeight: 70,
    "&:last-child": {
      marginBottom: 0,
      minHeight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 85,
    },
  },
  itemTitle: {
    fontFamily: "Manrope",
    fontWeight: 300,
    fontSize: 16,
    color: "#69779A",
  },
  itemText: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 14,
    color: "#1D3A60",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

const Item = ({ title, text }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <Typography className={classes.itemTitle}>{title}</Typography>
      <Typography className={classes.itemText}>{text}</Typography>
    </div>
  );
};

export const TechSpecs = (props: any) => {
  const classes = useStyles();
  const specs: Array<TechSpec> = props.specs ?? [];

  const { t } = useTranslation();

  const data = [
    specs?.slice(0, specs?.length / 2),
    specs?.slice(specs?.length / 2),
  ];

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("TECHNICAL_SPECS")}</Typography>
      <div className={classes.rowsWrapper}>
        {data.map((arr) => (
          <div className={classes.columnWrapper}>
            {arr.map((item) => (
              <Item
                title={t(item.label)}
                text={`${t(item.value)} ${item.units ? t(item.units) : ""}`}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
