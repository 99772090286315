import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router";
import {
  loadBasket,
  loadProducts,
  Product,
} from "../../store/features/shop/Shop";
import { RootState } from "../../store/store";
import Footer from "../UI/Footer";
import { HeaderV2 } from "../UI/HeaderV2";
import { Delivery } from "../UI/Shop_V2/Delivery";
import { MainShop } from "../UI/Shop_V2/MainShop";
import { Sensors } from "../UI/Shop_V2/Sensors";
import { TechSpecs } from "../UI/Shop_V2/TechSpecs";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 1200,
    width: "100%",
    margin: "0 auto 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "15px auto",
    },
  },
  braceletsWrapper: {
    width: 1200,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      margin: "15px auto",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  left: {
    marginRight: 20,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  right: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}));

const ShopContent = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const products: Array<Product> = props.products;
  const { watch }: any = useParams();
  const history: any = useHistory();
  const techSpecs =
    useSelector((store: RootState) => store.shop.techSpecs) ?? [];
  const watches = products.map((product) => product.name);
  const upperCaseWatches = watches.map((watch) => watch.toUpperCase());
  const [currentWatch, setCurrentWatch] = React.useState<Product | null>(
    products.find((prod) => prod.name.toUpperCase() === watch.toUpperCase()) ??
      null
  );

  React.useEffect(() => {
    setCurrentWatch(
      products.find(
        (prod) => prod.name.toUpperCase() === watch.toUpperCase()
      ) ?? products[0]
    );
    document.title = `MISU ${watch.toUpperCase()}`;
  }, [products, watch]);

  const changeWatch = (id: string) => {
    const watch = products.find((product) => product.id === id) ?? null;
    history.push(`${watch?.name}`);
    setCurrentWatch(watch);
  };

  if (watches.length > 0 && !watches.includes(watch.toLowerCase()))
    return <Redirect to="/not-found" />;

  if (upperCaseWatches.includes(watch))
    return <Redirect to={watch.toLowerCase()} />;

  return (
    <div style={{ width: "100%" }}>
      <HeaderV2 />

      <div className={classes.wrapper}>
        <MainShop
          watch={currentWatch}
          onChangeWatch={changeWatch}
          products={products}
        />
        <div className={classes.infoWrapper}>
          <div className={classes.left}>
            <Sensors />
          </div>
          <div className={classes.right}>
            <TechSpecs
              specs={
                techSpecs.find((prodSpec) => prodSpec.id === currentWatch?.id)
                  ?.specs
              }
            />
            <Delivery />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          margin: "auto",
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export const Shop = () => {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const products = useSelector((store: RootState) => store.shop.products) ?? [];

  React.useEffect(() => {
    dispatch(loadBasket());
    dispatch(loadProducts());
  }, [dispatch]);

  return (
    <Switch>
      <Route path={url} exact>
        <Redirect
          to={`${
            url[url.length - 1] === "/" ? url.slice(0, url.length - 1) : url
          }/${products[0]?.name.toLowerCase() ?? "air"}`}
        />
      </Route>
      <Route path={`${url}/:watch`}>
        <ShopContent products={products} />
      </Route>
    </Switch>
  );
};
