import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  loadBasket,
  resetAllExceptOrder,
  resetBasketState,
} from "../../store/features/shop/Shop";
import { AppDispatch, RootState } from "../../store/store";
import { BasketHeader } from "../UI/Basket/BasketHeader";
import Footer from "../UI/Footer";
import { Fail } from "../UI/PaymentResult/Fail";
import { Success } from "../UI/PaymentResult/Success";

const useStylesNonActivation = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: 500,
    margin: "20px auto",
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    border: "1px solid #E4EFFF",
    padding: "30px 24px",
    borderRadius: 32,
  },
  title: {
    fontWeight: 800,
    fontSize: 24,
    lineHeight: "120%",
    marginBottom: 12,
    marginTop: 24,
  },
  description: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "140%",
    color: "#69779A",
  },
  button: {
    fontWeight: 800,
    fontSize: 16,
    lineHeight: "140%",
    textTransform: "none",
    borderRadius: 40,
    padding: "16px 28px",
    width: "80%",
    marginTop: 24,
  },
}));

const NonActivationSuccessOrder = () => {
  const classes = useStylesNonActivation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const prevOrder = useSelector(
    (store: RootState) => store.shop.basket.prevOrder
  );

  React.useEffect(() => {
    dispatch(loadBasket());
    setTimeout(() => {
      dispatch(resetAllExceptOrder());
    }, 1500);
  }, [dispatch]);

  const goToMain = () => {
    dispatch(resetBasketState());
    history.push("/");
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div>
          <img
            src={window.location.origin + "/image/success_icon.svg"}
            alt="success"
          />
        </div>
        <Typography className={classes.title}>{t("ORDER_SUCCESS")}</Typography>
        <Typography className={classes.description}>
          {t("THANKS_FOR_ORDER")}
        </Typography>
        <Typography className={classes.description}>
          {t("ORDER_SENT_TO_EMAIL")} {prevOrder?.email}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={goToMain}
        >
          {t("GO_TO_MAIN")}
        </Button>
      </div>
    </div>
  );
};

export const PaymentResult = () => {
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const activationInSite = useSelector(
    (store: RootState) => store.shop.basket?.activationInSite
  );
  const isPreOrder = useSelector(
    (store: RootState) => store.shop.basket?.isPreOrder
  );
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState<string>(t("YOUR_ORDER"));
  const [isActivated, setIsActivated] = React.useState<boolean>(false);

  const query = useQuery();
  const resultParam: string = query.get("result") ?? "";
  const isSuccess = resultParam === "success";

  React.useEffect(() => {
    if (isSuccess) document.title = "MISU Payment Successful";
    else document.title = "MISU Payment Cancelled";

    if (isSuccess && activationInSite) setTitle(t("PAYMENT_SUCCESS"));
    else if (isSuccess && isPreOrder) setTitle(t("DONE_PRE_ORDER"));
    else if (isSuccess && !activationInSite) setTitle(t("ORDER_SUCCESS"));
    else if (!isSuccess) setTitle(t("PAYMENT_CANCEL"));
  }, [isPreOrder, activationInSite, isSuccess]);

  React.useEffect(() => {
    dispatch(loadBasket());
  }, [dispatch]);

  React.useEffect(() => {
    if (isActivated) setTitle(t("DONE_ACTIVATION"));
  }, [isActivated]);

  return (
    <div style={{ width: "100%" }}>
      <BasketHeader
        shouldClearBasket={
          (isSuccess && isActivated) ||
          (isSuccess && !activationInSite) ||
          isPreOrder
        }
        title={title}
      />
      <div
        style={{
          margin: "20px auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isSuccess ? (
          activationInSite ? (
            <Success onActivated={() => setIsActivated(true)} />
          ) : (
            <NonActivationSuccessOrder />
          )
        ) : (
          <Fail />
        )}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
