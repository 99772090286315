import { Routes } from "./Routes";
import "./i18n";

function App() {
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
