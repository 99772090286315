import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loadBasket } from "../../../store/features/shop/Shop";
import { AppDispatch, RootState } from "../../../store/store";

export const Fail = () => {
  const { t, i18n } = useTranslation();
  const isEnLocale = i18n.language === "en";
  const dispatch: AppDispatch = useDispatch();
  const prevOrder = useSelector(
    (store: RootState) => store.shop.basket?.prevOrder
  );

  React.useEffect(() => {
    dispatch(loadBasket());
  }, []);

  const paymentBody = {
    paymentTypes: {
      card: "Y",
      gpay: "Y",
      privat: "Y",
      applepay: "Y",
      masterpass: "Y",
      portmone: "Y",
      visacheckout: "Y",
      //token: "Y",
      //createtokenonly: "N",
    },
    priorityPaymentTypes: {
      card: "1",
      portmone: "7",
      masterpass: "3",
      visacheckout: "4",
      privat: "5",
      gpay: "2",
      applepay: "6",
      //token: "1",
      //createtokenonly: "8",
    },
    payee: {
      payeeId: process.env.REACT_APP_PORTMONE_ID ?? "",
      //payeeId: "1185",
    },
    order: {
      description: "MISU ORDER",
      billAmount:
        (Number(prevOrder?.totalPrice) ?? 0) +
        (Number(prevOrder?.deliveryTax) ?? 0),
      successUrl:
        process.env.REACT_APP_PAYMENT_SUCCESS +
          `?order=${prevOrder?.id ?? ""}` ?? "",
      failureUrl:
        process.env.REACT_APP_PAYMENT_FAIL + `?order=${prevOrder?.id ?? ""}` ??
        "",
      billCurrency: "UAH",
    },
    payer: {
      lang: isEnLocale ? "en" : "uk",
      emailAddress: prevOrder?.email,
      showEmail: "Y",
    },
  };

  const makePay = () => {
    (document.getElementById("payment_form") as HTMLFormElement)!.submit();
  };

  return (
    <div className="page-section-vuso col-12 col-md-6 col-lg-4">
      <div className="container-vuso repeat-payment">
        <div className="section-vuso-icon">
          <img src={window.location.origin + "/image/SMS.svg"} alt="sms" />
        </div>
        <div className="section-vuso-text my-4">
          {t("REPEAT_PAYMENT_TITLE")}
        </div>
        <button className="btn-vuso" onClick={makePay} disabled={!prevOrder}>
          {t("REPEAT_PAYMENT_BUTTON")}
        </button>
      </div>

      {/* <form
        action={process.env.REACT_APP_PORTMONE ?? ""}
        method="post"
        target="_blank"
        id="payment_form"
        onSubmit={(e: any) => e.preventDefault()}
      >
        <input
          type="hidden"
          name="bodyRequest"
          value={JSON.stringify(paymentBody)}
        />
        <input type="hidden" name="typeRequest" value="json" />
        <input
          type="submit"
          value="Оплатити через Portmone.com"
          style={{ width: 350, height: 30, display: "none" }}
        />
      </form> */}
    </div>
  );
};
