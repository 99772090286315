import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    border: "1px solid #E4EFFF",
    borderRadius: 32,
    width: "100%",
    backgroundColor: "#fff",
    padding: "55px 50px 55px 40px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: 20,
      paddingLeft: 20,
      paddingBottom: 30,
    },
  },
  title: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "100%",
    marginBottom: 40,
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
  },
  item: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: 40,
    },
  },
  deliveryPrice: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 24,
    color: "#1D3A60",
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
  deliveryTextBlock: {
    textAlign: "left",
  },
  deliveryMethod: {
    color: "#69779A",
    fontFamily: "Manrope",
    fontWeight: 300,
    fontSize: 16,
  },
  deliveryCompany: {
    fontFamily: "Manrope",
    fontWeight: 600,
    fontSize: 16,
    color: "#1D3A60",
  },
}));

export const Delivery = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{t("DELIVERY")}</Typography>
      <div className={classes.itemsWrapper}>
        <div className={classes.item}>
          <div className={classes.deliveryTextBlock}>
            <Typography className={classes.deliveryMethod}>
              {t("DELIVERY_BY_COURIER")}
            </Typography>
            <Typography className={classes.deliveryCompany}>
              {t("DELIVERY_SERVICES")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.deliveryPrice}>85₴ - 90₴</Typography>
          </div>
        </div>
        <div className={classes.item}>
          <div className={classes.deliveryTextBlock}>
            <Typography className={classes.deliveryMethod}>
              {t("DELIVERY_TO_POST_OFFICE")}
            </Typography>
            <Typography className={classes.deliveryCompany}>
              {t("DELIVERY_SERVICES")}
            </Typography>
          </div>
          <div>
            <Typography className={classes.deliveryPrice}>65₴ - 70₴</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
